const Common = {
  DEVELOPER_PORTAL: 'Developer Portal',
  METADATA_TITLE_PREFIX: 'Discord Developer Portal',
  CLOSE: 'Close',
  SAVE: 'Save',
  SUBMIT: 'Submit',
  NO: 'No',
  NOT_AVAILABLE: 'N/A',
  COPY_TO_CLIPBOARD: 'Copy to Clipboard',
  LOGGED_IN_HEADER: 'Logged in as',
  SETTINGS_NOTICE_MESSAGE: 'Careful — you have unsaved changes!',
  APPLICATION_NOT_FOUND_HEADING: 'Application not found!',
  APPLICATION_NOT_FOUND_BODY: 'There was no application found with ID **!!{appId}!!**.',
  SKU_NOT_FOUND_HEADING: 'SKU not found!',
  SKU_NOT_FOUND_BODY: 'There was no SKU found with ID **!!{skuId}!!**.',
  ROUTE_NOT_FOUND_HEADING: 'Route not found!',
  ROUTE_NOT_FOUND_BODY: 'The route specified was not found.',
  TEAM_NOT_FOUND_HEADING: 'Team not found!',
  TEAM_NOT_FOUND_BODY: 'There was no Team found with ID **!!{teamId}!!**',
  GENERIC_API_ERROR: `Oops! You've caught an ultra rare error. This is probably our fault, so please try again or check [our status page]({statusPageURL}).`,
  RATE_LIMIT_ERROR: `You are being rate limited. Chill out for a bit before attempting your request again.`,
  INVALID_MFA_CODE: 'Invalid 2FA code provided.',
  NEW: 'New',
  DESCRIPTION: 'Description',
  TOKEN_GENERATE_SUCCESS: 'A new token was generated! Be sure to copy it as it will not be shown to you again.',
  TWO_FACTOR_AUTHENTICATION_REQUIRED: 'Two-factor authentication is required for this operation.',
  PAGINATOR_OF_PAGES: 'Page !!{currentPage, number}!! of !!{numPages, number}!!',
  SORT_BY: 'Sort By:',
  SMALL: 'Small',
  LARGE: 'Large',
  A_THROUGH_Z: 'A–Z',
  DATE_CREATED: 'Date Created',
  APPLICATION_VERIFICATION_DISABLED_HOLIDAY_BREAK:
    'The verification form is currently closed for the holidays. We have increased the maximum guild limit for bots to 250 until the form re-opens. The form will re-open on January 11, 2021.',
  APPLICATION_VERIFICATION_INVALID_PRIVACY_POLICY_EMPTY:
    'All apps must have a privacy policy. Add one [here]({appInfoLink}).',
  APPLICATION_VERIFICATION_INVALID_TOS_EMPTY: 'All apps must have a Terms of Service. Add one [here]({appInfoLink}).',
  SELECT: 'Select',

  // TODO(beckwith) Previously undiscovered string references found during TypeScript migration.
  NO_RESULTS_FOUND: 'No results found',
  GAME_FEED_USER_PLAYING_JUST_STARTED: 'GAME_FEED_USER_PLAYING_JUST_STARTED placeholder {time}',
  GAME_FEED_USER_PLAYING_FOR_MINUTES: 'GAME_FEED_USER_PLAYING_FOR_MINUTES placeholder {time}',
  GAME_FEED_USER_PLAYING_FOR_HOURS: 'GAME_FEED_USER_PLAYING_FOR_HOURS placeholder {time}',
  GAME_FEED_USER_PLAYING_FOR_DAYS: 'GAME_FEED_USER_PLAYING_FOR_DAYS placeholder {time}',
  GAME_FEED_USER_PLAYING_JUST_ENDED: 'GAME_FEED_USER_PLAYING_JUST_ENDED placeholder {time}',
  GAME_FEED_USER_PLAYED_MINUTES_AGO: 'GAME_FEED_USER_PLAYED_MINUTES_AGO placeholder {time}',
  GAME_FEED_USER_PLAYED_HOURS_AGO: 'GAME_FEED_USER_PLAYED_HOURS_AGO placeholder {time}',
  GAME_FEED_USER_PLAYED_DAYS_AGO: 'GAME_FEED_USER_PLAYED_DAYS_AGO placeholder {time}',
  GAME_FEED_USER_PLAYING_JUST_STARTED: 'GAME_FEED_USER_PLAYING_JUST_STARTED placeholder {time}',
  USER_ACTIVITY_USER_PLAYING_FOR_MINUTES: 'USER_ACTIVITY_USER_PLAYING_FOR_MINUTES placeholder {time}',
  USER_ACTIVITY_USER_PLAYING_FOR_HOURS: 'USER_ACTIVITY_USER_PLAYING_FOR_HOURS placeholder {time}',
  USER_ACTIVITY_USER_PLAYING_FOR_DAYS: 'USER_ACTIVITY_USER_PLAYING_FOR_DAYS placeholder {time}',
};

const AppNavigation = {
  APPLICATIONS: 'Applications',
  TEAMS: 'Teams',
  DOCUMENTATION: 'Documentation',
  GUILDS: 'Server Insights',
  EMBED_DEBUGGER: 'Embed Debugger',
};

const ErrorPage = {
  ERROR_DESCRIPTION: `That's so 1939. Discord is secretly powered by quantum robot hamsters. Technology is wild, isn't it? Anyway, you look lost. Here are a few suggested pages.`,
  ERROR_HEADER: 'Wizards Behind Curtains?',
  STATUS_PAGE_LINK: 'Status Page',
  SUPPORT_LINK: 'Help and Support',
  DOCUMENTATION_LINK: 'API Documentation',
};

const Forms = {
  USERNAME: 'Username',
  REQUIRED_FIELD: 'This field is required.',
  NAME: 'Name',
  ICON: 'Icon',
};

const DeveloperPages = {
  PAGE_TITLE: 'Documentation',

  // Confirm Modal
  DEFAULT_CONFIRM_TITLE: 'Confirmation Required',
  DEFAULT_CONFIRM_MSG: 'Please confirm you want to take this action.',
  CANCEL_BUTTON: 'Nevermind',
  CONFIRM_BUTTON: 'Yes, do it!',

  // New App/Edit App
  ERROR_HEADING: 'Error encountered!',
  ERROR_UNKNOWN: 'Unknown Error',

  // Developer Tools
  PERMISSIONS_CALCULATOR: 'Permissions Calculator',
  PERMISSIONS_CALCULATOR_PERMISSIONS_INTEGER: 'Permissions Integer',
  MISSING_CLIENT_ID: 'Please enter a client id',
  MISSING_REDIRECT_URI: 'Please enter a redirect uri',
  TOO_FEW_SCOPES: 'Please select at least one OAuth2 scope',
};

const AvatarUploader = {
  SELECT_IMAGE: 'Select\nImage',
  REMOVE: 'Remove',
  MINIMUM_SIZE: 'Minimum Size: **{size}x{size}**',
  SIZE: 'Size: **{size}x{size}**',
  DIMENSIONS: 'Size: **{width}x{height}**',
  IMAGES: 'Images',
};

const Permissions = {
  // General Permissions
  GENERAL_PERMISSIONS: 'General Permissions',
  ADMINISTRATOR: 'Administrator',
  VIEW_AUDIT_LOG: 'View Audit Log',
  MANAGE_SERVER: 'Manage Server',
  MANAGE_ROLES: 'Manage Roles',
  MANAGE_CHANNELS: 'Manage Channels',
  KICK_MEMBERS: 'Kick Members',
  BAN_MEMBERS: 'Ban Members',
  CREATE_INSTANT_INVITE: 'Create Instant Invite',
  CHANGE_NICKNAME: 'Change Nickname',
  MANAGE_NICKNAMES: 'Manage Nicknames',
  MANAGE_EMOJIS_AND_STICKERS: 'Manage Emojis and Stickers',
  CREATE_EMOJIS_AND_STICKERS: 'Create Emojis and Stickers',
  MANAGE_WEBHOOKS: 'Manage Webhooks',
  CREATE_EXPRESSIONS: 'Create Expressions',
  MANAGE_EXPRESSIONS: 'Manage Expressions',
  VIEW_CHANNELS: 'View Channels',
  MANAGE_EVENTS: 'Manage Events',
  CREATE_EVENTS: 'Create Events',
  MODERATE_MEMBERS: 'Moderate Members',
  VIEW_GUILD_ANALYTICS: 'View Server Insights',
  VIEW_CREATOR_MONETIZATION_ANALYTICS: 'View Server Subscription Insights',

  // Text Permissions
  TEXT_PERMISSIONS: 'Text Permissions',
  SEND_MESSAGES: 'Send Messages',
  SEND_TTS_MESSAGES: 'Send TTS Messages',
  MANAGE_MESSAGES: 'Manage Messages',
  EMBED_LINKS: 'Embed Links',
  ATTACH_FILES: 'Attach Files',
  READ_MESSAGE_HISTORY: 'Read Message History',
  MENTION_EVERYONE: 'Mention Everyone',
  USE_EXTERNAL_EMOJIS: 'Use External Emojis',
  USE_EXTERNAL_STICKERS: 'Use External Stickers',
  ADD_REACTIONS: 'Add Reactions',
  USE_APPLICATION_COMMANDS: 'Use Slash Commands',
  MANAGE_THREADS: 'Manage Threads',
  CREATE_PUBLIC_THREADS: 'Create Public Threads',
  CREATE_PRIVATE_THREADS: 'Create Private Threads',
  SEND_MESSAGES_IN_THREADS: 'Send Messages in Threads',
  SEND_POLLS: 'Create Polls',
  USE_EXTERNAL_APPS: 'Use External Apps',

  // Voice Permissions
  VOICE_PERMISSIONS: 'Voice Permissions',
  CONNECT: 'Connect',
  SPEAK: 'Speak',
  VIDEO: 'Video',
  MUTE_MEMBERS: 'Mute Members',
  DEAFEN_MEMBERS: 'Deafen Members',
  MOVE_MEMBERS: 'Move Members',
  USE_VAD: 'Use Voice Activity',
  PRIORITY_SPEAKER: 'Priority Speaker',
  STREAM: 'Stream',
  REQUEST_TO_SPEAK: 'Request To Speak',
  USE_EMBEDDED_ACTIVITIES: 'Use Embedded Activities',
  USE_SOUNDBOARD: 'Use Soundboard',
  USE_EXTERNAL_SOUNDS: 'Use External Sounds',
};

const Developers = {
  METADATA_TITLE: 'API Docs',
  METADATA_OGTITLE: 'Discord API Docs for Bots and Developers',
  METADATA_DESCRIPTION: `Integrate your service with Discord — whether it's a bot or a game or whatever your wildest imagination can come up with.`,
  BREAKING_CHANGE: 'This entry includes breaking changes',
  FILTER_BY: 'Filter By:',
};

const Whitelist = {
  HEADING: 'Application Testers',
  SUBHEADING:
    'You can invite up to 50 Discord users to test your application. They must have a registered email and a positive attitude :^)',
  INVITED_TESTERS_HEADING: 'Invited Testers ({count, number} of 50)',
  PENDING_INVITE: 'Pending Invite',
  ACCEPTED_INVITE: 'Accepted',
  INVITE: 'Invite',
  NO_USERS_INVITED: 'No users invited',
};

const DiscoverySettings = {
  HEADING: 'Discovery Settings',
  SUBHEADING: `Configure how your application is displayed on Discord's discovery surfaces.`,
  SUBHEADING_LINK: '[Read our App Directory best practices]({link})',
  EXTERNAL_LINKS: 'Links',
  EXTERNAL_LINKS_ADD_BUTTON_TEXT: 'Add',
  EXTERNAL_LINKS_DESCRIPTION:
    'Share your website, GitHub, or other links relevant to your app. You can include up to five links.',
  EXTERNAL_LINKS_ITEM_NAME: 'External Link Name',
  EXTERNAL_LINKS_ITEM_URL: 'External Link URL',
  ENTRY_FETCH_FAILED: 'There was an error fetching the app discoverability entry.',
  ENTRY_SAVE_FAILED: 'There was an error saving the app discoverability entry.',
  LOCALIZED_DETAILED_DESCRIPTIONS: 'Expanded Description',
  LOCALIZED_SHORT_DESCRIPTIONS: 'Summary',
  LOCALIZED_DETAILED_DESCRIPTIONS_DESCRIPTION:
    'If you have more you would like to tell people about your app, do so below. Tell us about your app, and translate it to other languages. This field supports markdown.',
  LOCALIZED_SHORT_DESCRIPTIONS_DESCRIPTION:
    'Give us a short summary of your app. This will appear in search and on your profile page.',
  LOCALIZED_DESCRIPTIONS_EMPTY: `You need to select your app's supported languages before entering a description.`,
  LOCALIZED_DESCRIPTIONS_EDITOR_EDIT: 'Edit Description',
  LOCALIZED_DESCRIPTIONS_EDITOR_PREVIEW: 'Preview',
  CAROUSEL_ITEMS: 'Media',
  CAROUSEL_ITEMS_ADD_BUTTON_TEXT: 'Add',
  CAROUSEL_ITEMS_DESCRIPTION: `Include screenshots and videos to show what makes your app unique.`,
  CAROUSEL_ITEMS_SUPPORTED_LINKS: `Supported links: YouTube videos, .png, .jpeg, .gif, .webp`,
  SUPPORT_SERVER: 'Support Server',
  SUPPORT_SERVER_DESCRIPTION:
    'This server lets people contact you for support and join your community. It must be a [Community Server]({link}).',
  SUPPORTED_LOCALES: 'Languages',
  SUPPORTED_LOCALES_DESCRIPTION: `Create a list of languages your app supports.`,
  APP_DIRECTORY_PREVIEW: 'Preview App Directory',
  REQUIRED: 'Required Fields',
  OPTIONAL_FIELDS: 'Optional Fields',
  SUPPORT_SERVER_REQUIRED: 'A support server is required.',
};

const DiscoveryStatus = {
  LOAD_ERROR: 'We were unable to load your application. Please try again later',
  SAVE_ERROR: 'We were unable to save your changes. Please try again later',
  SUCCESS_DISCOVERABLE:
    "Your changes were saved! Your app may take up to 24 hours to appear on Discord's discovery surfaces.",
  SUCCESS_UNDISCOVERABLE:
    "Your changes were saved! Your app may take up to 24 hours to stop showing up on Discord's discovery surfaces.",

  HEADING: 'App Discovery Status',
  SUBHEADING: 'Include or remove your application from discovery surfaces on Discord.',
  ABOUT:
    "By enabling discovery, you're opting in for your application and its product information to be included in discovery surfaces on Discord **and agree to our [Discovery Policy]({discoveryPolicyLink})**. As a reminder, you and your app are subject to the $[Developer Terms of Service](tosLink) and $[Developer Policy](policyLink). You are also responsible for your app's product information and ensuring that it is accurate and up-to-date. Failure to abide by our terms and policies may result in your app's removal or exclusion from the Discord's discovery surfaces.",

  TITLE_INELIGIBLE: 'You need to make a few changes',
  SUBTITLE_INELIGIBLE: 'Your application must meet all of the following criteria',

  TITLE_UNDISCOVERABLE: "You're ready to go! Click below to become discoverable.",
  SUBTITLE_UNDISCOVERABLE: 'Your application meets all the criteria.',

  TITLE_DISCOVERABLE: "You're already in! Click below to disable discoverability.",
  SUBTITLE_DISCOVERABLE: 'Your application is currently discoverable',

  CTA_ENABLE: 'Enable Discovery',
  CTA_DISABLE: 'Disable Discovery',

  POLICY_TITLE: 'Verification and Safety',
  MIGRATION_DESCRIPTION:
    'Your application must use slash commands, or have been approved for the Message Content privileged intent',
  VERIFICATION_DESCRIPTION: 'Your application must be verified',
  TWOFA_DESCRIPTION: 'Your Discord account must have Two-Factor authentication enabled',

  ABOUT_APPLICATION_TITLE: 'Tell Us About Your Application',
  HAS_SHORT_DESCRIPTION_DESCRIPTION: 'Provide a $[summary](link) of what your application does',
  HAS_LONG_DESCRIPTION_DESCRIPTION: 'Provide an $[expanded description](link) of what your application does',

  HAS_TAGS_DESCRIPTION: 'Add $[at least one tag](link)',
  HAS_SUPPORT_SERVER: 'You must designate a community server as a $[support server](link) for your application',
  HAS_SUPPORTED_LOCALES: 'Add $[at least one locale](link)',

  LINKS_TITLE: 'Add Application Links',
  INSTALL_DESCRIPTION: 'Add an $[Install URL](link) so people can add your application to their server',
  TOS_DESCRIPTION: 'Provide a link to your $[Terms of Service](link)',
  PRIVACY_POLICY_DESCRIPTION: 'Provide a link to your $[Privacy Policy](link)',

  LANGUAGE_TITLE: 'Use Appropriate Language',
  LANGUAGE_NAME_DESCRIPTION: "Your application's name may not contain any harmful or bad language",
  LANGUAGE_DESCRIPTION_DESCRIPTION:
    'Your application must $[have a description](link), and it may not contain any harmful or bad language',
  LANGUAGE_COMMAND_DESCRIPTION: 'Your commands may not contain any harmful or bad language',
  LANGUAGE_ROLE_CONNECTIONS_METADATA_DESCRIPTION:
    'Your role connections metadata may not contain any harmful or bad language',

  APP_BLOCKED_HEADING: 'Your App is Not Eligible for Discovery',
  APP_BLOCKED_CONTENT: 'Check your email for a message from our Developer Support team',
};

const Bot = {
  HEADING: 'Bot',
  SUBHEADING: `Bring your app to life on Discord with a Bot user. Be a part of chat in your users' servers and interact with them directly.`,
  SUBHEADING_LINK: '[Learn more about bot users]({link})',
  TOKEN_WARNING:
    'For security purposes, tokens can only be viewed once, when created. If you forgot or lost access to your token, please regenerate a new one.',
  PERMISSIONS_HEADING: 'Bot Permissions',
  PERMISSIONS_SUBHEADING:
    'Need some help with bit math? Use the tool below to calculate the permissions integer for your bot based on the features it needs.',
  CREATE_BOT:
    'Bring your app to life by adding a bot user. This action is irreversible (because robots are too cool to destroy).',
  BUILD: 'Build-A-Bot',
  ADD_BOT: 'Add Bot',
  BOT_CREATE_MODAL_LABEL: 'Add a Bot to this App?',
  BOT_CREATE_MODAL_DESCRIPTION:
    'Adding a bot user gives your app visible life in Discord. However, this action is irrevocable! Choose wisely.',
  BOT_TOKEN_GENERATE_MODAL_LABEL: `Reset Bot's Token?`,
  BOT_TOKEN_GENERATE_MODAL_DESCRIPTION: `Your bot will stop working until you update the token in your bot's code.`,
  BOT_ICON: 'Icon',
  BOT_DISABLED_ALERT_HEADER: 'Bot Disabled',
  BOT_DISABLED_ALERT_DESCRIPTION:
    'Your bot has been disabled by Discord. Please [contact us]({url}) for more information.',
  BOT_QUARANTINED_ALERT_HEADER: 'Bot Quarantined',
  BOT_QUARANTINED_ALERT_DESCRIPTION:
    'Your bot has been flagged by our anti-spam system for abusive behavior. While your bot is quarantined, it is unable to join any more guilds, or send any direct messages to users it has not messaged before. If you believe this to be in error, [click here]({url}) and select "Appeal an action taken on an app/bot" as the Report Type to submit an appeal.',
  TOKEN: 'Token',
  REVEAL_TOKEN: 'Click to Reveal Token',
  PUBLIC_BOT: 'Public Bot',
  PUBLIC_BOT_DESCRIPTION: 'Public bots can be added by anyone. When unchecked, only you can join this bot to servers.',
  REQUIRE_OAUTH2_CODE_GRANT: 'Requires OAuth2 Code Grant',
  REQUIRE_OAUTH2_CODE_GRANT_DESCRIPTION: `If your application requires multiple scopes then you may need the full OAuth2 flow to ensure a bot
  doesn't join before your application is granted a token.`,
  BOT_CREATE_SUCCESS: 'A wild bot has appeared!',
  BOT_IMG_ALT: 'Cute robot',
  UPDATE_BOT_SUCCESS: 'Bot successfully updated!',
  AUTHORIZATION_FLOW: 'Authorization Flow',
  AUTHORIZATION_FLOW_DESCRIPTION:
    'These settings control how OAuth2 authorizations are restricted for your bot (who can add your bot and how it is added).',
  UNAPPROVED_INTENTS_WARNING:
    'Your app is temporarily approved for Intents. You may continue to use Privileged Intents until your app joins 100 servers. At that point, you must either apply for full access or turn them off, or your app will not be able to join more servers. You may apply for full access when your app reaches 75 servers.',
  UNAPPROVED_INTENTS_ERROR:
    'Your app is temporarily approved for Intents and has been limited to joining 100 servers. To enable joining additional servers you must either apply for full access or turn them off, or your app will not be able to join more servers.',
  GATEWAY_PRIVILEGED_INTENTS: 'Privileged Gateway Intents',
  GATEWAY_PRIVILEGED_INTENTS_DESCRIPTION: `Some [Gateway Intents]({link}) require approval if your bot is verified. If your bot is not verified, you can toggle those intents below to access them.`,
  GATEWAY_PRESENCE_LIMITED: 'Presence Intent',
  GATEWAY_PRESENCE_LIMITED_DESCRIPTION: `Required for your bot to receive [Presence Update]({event}) events.

**NOTE: Once your bot reaches 100 or more servers, this will require verification and approval. [Read more here]({support})**`,
  GATEWAY_GUILD_MEMBERS_LIMITED: 'Server Members Intent',
  GATEWAY_GUILD_MEMBERS_LIMITED_DESCRIPTION: `Required for your bot to receive events listed under [GUILD_MEMBERS]({intent}).

**NOTE: Once your bot reaches 100 or more servers, this will require verification and approval. [Read more here]({support})**`,
  GATEWAY_MESSAGE_CONTENT_LIMITED: 'Message Content Intent',
  GATEWAY_MESSAGE_CONTENT_LIMITED_DESCRIPTION: `Required for your bot to receive [message content]({faq}) in most messages.

**NOTE: Once your bot reaches 100 or more servers, this will require verification and approval. [Read more here]({support})**`,
};

const Emojis = {
  HEADING: 'Emojis',
  SUBHEADING: 'Add up to {maxCount, number} custom emoji. These emojis may only be used by the application.',
  EMPTY_STATE_HEADING: '0 Emojis Uploaded',
  EMPTY_STATE_SUBHEADING:
    'Get the party started by uploading an emoji. Up to {maxCount, number} custom emojis can be uploaded by an app',
  REQUIREMENTS_HEADER: 'Upload Requirements',
  REQUIREMENTS_1: 'File Type: JPEG, PNG, GIF',
  REQUIREMENTS_2: 'Max file size: 256 KB',
  REQUIREMENTS_3: 'Recommended dimensions: 128 x 128',
  REQUIREMENTS_4:
    'Naming: Emoji names must be at least {minLength, number} characters long and can only contain alphanumeric characters and underscores',
  TABLE_HEADING: 'Emojis - {slots, number} slots available',
  TABLE_SUBHEADING:
    'Emoji names must be {minLength, number}-{maxLength, number} characters long and can only contain alphanumeric characters and underscores',
  TABLE_COLUMN_EMOJI: 'Emoji',
  TABLE_COLUMN_ID: 'Emoji Id',
  TABLE_COLUMN_UPLOADER: 'Uploaded By',
  UPLOAD_BUTTON: 'Upload Emoji',
  UPLOAD_ERROR_TOO_LARGE: 'File too large',
  UPLOAD_ERROR_FILE_INVALID: 'File invalid',
  ASSETS_IMAGES: 'Images',
  COPY_MARKDOWN_TOOLTIP: 'Copy Markdown',
  SEARCH_PLACEHOLDER: 'Search Emojis',
};

const RichPresence = {
  SUBHEADING_LINK: '[Learn more about Rich Presence]({link})',
  ASSETS_HEADING: 'Rich Presence Art Assets',
  ASSETS_SUBHEADING: `Integrate your game deeply with Discord and let players jump directly into your client and share your game.`,
  ASSETS_SUBHEADING_LINK: '[Learn more about Rich Presence Best Practices]({link})',
  INVITE_IMAGE_HEADING: 'Rich Presence Invite Image',
  INVITE_IMAGE_SUBHEADING: `This is the default image for chat invites, so make sure it's pretty! Put your best face forward :)`,
  COVER_IMAGE: 'Cover Image',
  IRL_INVITE_IMAGE_EXAMPLE: 'IRL Invite Image Example',
  ASSETS_SECTION_HEADING: 'Rich Presence Assets',
  ASSETS_SECTION_SUBHEADING:
    'You may add multiple assets that are paired with keys to dynamically update rich presence data for users with images of maps, modes, or whatever works best for your game.',
  ASSETS_ADD_IMAGES: 'Add Image(s)',
  ASSETS_ACCEPTED_FILE_TYPES:
    '.png, .jpg, or .jpeg — {recommendedSize}x{recommendedSize} recommended, {minSize}x{minSize} minimum',
  ASSETS_MANAGER_HEADING: 'Assets ({count, number} of 300)',
  ASSET_CACHING_WARNING:
    'Due to caching, asset keys are not editable once they are saved. Delete and re-upload assets if you need to change a key name.',
  ASSETS_IMAGES: 'Images',
  MINIMUM_ASSET_SIZE: 'Minimum Size: **{size, number}x{size, number}**',
  NO_ASSETS_UPLOADED: 'No assets uploaded',
};

const Information = {
  HEADING: 'General Information',
  SUBHEADING: `What should we call your creation? What amazing things does it do? What icon should represent it across Discord? Tell us here!`,
  SUBHEADING_LINK:
    'By clicking Create, you agree to the Discord [Developer Terms of Service]({termsOfServiceLink}) and [Developer Policy]({developerPolicyLink}).',
  APP_NAME: 'Name',
  APP_ID: 'Application ID',
  APP_CLIENT_ID: 'Client ID',
  APP_CLIENT_SECRET: 'Client Secret',
  APP_ICON: 'App Icon',
  APP_ICON_DESCRIPTION: 'Choose a neat icon!',
  APP_DESCRIPTION: 'Description (maximum {maxLength} characters)',
  APP_TAGS: 'Tags (maximum {maxLength})',
  APP_TAGS_DESCRIPTION: `Add up to 5 tags to describe the content and functionality of your application.`,
  APP_VERIFY_KEY: 'Public Key',
  APP_INSTALL_COUNT: 'Install Count',
  APP_INSTALL_COUNT_DESCRIPTION:
    'This is an approximated number of servers and users that have installed your application.',
  APP_GUILD_INSTALL_COUNT_DESCRIPTION:
    'This is an approximated number of servers that have installed your application.',
  APP_USER_INSTALL_COUNT_DESCRIPTION: 'This is an approximated number of users that have installed your application.',
  APP_INSTALL_COUNT_UPDATE_CADENCE: '{count, plural, one {This number is} other {These numbers are}} updated daily.',
  APP_GUILD_COUNT_VALUE: '{guildCount, plural, one {{guildCount, number} Server} other {{guildCount, number} Servers}}',
  APP_USER_COUNT_VALUE:
    '{userCount, plural, one {{userCount, number} Individual User} other {{userCount, number} Individual Users}}',
  DELETE_APP: 'Delete App',
  TRANSFER_APP_OWNERSHIP: 'Transfer App to Team',
  IRL_EXAMPLE: 'IRL Example',
  APP_SECRET_REGENERATE_MODAL_LABEL: 'Regenerate Secret Key?',
  APP_SECRET_REGENERATE_MODAL_DESCRIPTION:
    'Your app will stop working until you update the secret key in your app code.',
  APP_DELETE_MODAL_LABEL: 'Danger: Deleting Your App!',
  APP_DELETE_MODAL_DESCRIPTION: `To delete this application, please confirm the name (__!!{name}!!__) below.`,
  APP_DELETE_MODAL_BOT_WARNING: `Deleting this application will also delete your bot (removing it from every server it is in)!`,
  APP_DELETE_MODAL_BOT_LEAVE_WARNING: `If your bot is in many servers, you may need to manually leave them before deleting.`,
  APP_NAME_CONFIRMATION_LABEL: 'App Name',
  MFA_CODE: '2FA Code',
  MFA_CODE_PLACEHOLDER: '6-digit authentication code',
  MFA_BACKUP_PLACEHOLDER: '8-character code',
  MFA_PASSWORD_PLACEHOLDER: 'Enter your password',
  SELECT_TEAM_FOR_TRANSFER: 'Select team to transfer to',
  INSTALL_TYPES: 'Install types',
  INSTALL_TYPES_DESCRIPTION: 'This is where you define what kinds of installs you allow this app to have',
  INSTALL_TYPE_GUILD: 'Guild',
  INSTALL_TYPE_USER: 'User',
  INTERACTIONS_ENDPOINT_URL: 'Interactions Endpoint URL',
  INTERACTIONS_ENDPOINT_URL_DESCRIPTION:
    'You can optionally configure an interactions endpoint to receive interactions via HTTP POSTs rather than over Gateway with a bot user.',
  ROLE_CONNECTIONS_VERIFICATION_URL: 'Linked Roles Verification URL',
  ROLE_CONNECTIONS_VERIFICATION_URL_DESCRIPTION:
    "You can configure a verification URL to enable your application as a requirement in a server role's Links settings",
  TOS_URL: 'Terms of Service URL',
  TOS_URL_DESCRIPTION: `A link to your application's Terms of Service`,
  PRIVACY_POLICY_URL: 'Privacy Policy URL',
  PRIVACY_POLICY_URL_DESCRIPTION: `A link to your application's Privacy Policy`,
  MAXIMUM_PARTICIPANTS: 'Maximum Participants',
  MAXIMUM_PARTICIPANTS_DESCRIPTION: 'The maximum participants allowed in your activity',
  APPLICATION_DESCRIPTION_ABOUT_ME_DESCRIPTION: `Your description will appear in the About Me section of your bot's profile.`,
  UNRELEASED_FIELD: 'Unreleased',
};

const Installation = {
  HEADING: 'Installation',
  SUBHEADING:
    'Choose how users will install your app. Create an installation link, choose which installation context to support, and define the scopes and permissions you want to request.',
  INSTALL_LINK_SECTION_HEADING: 'Install Link',
  INSTALL_LINK_SECTION_SUBHEADING: `Use our provided install link or register a custom one. If you choose a custom link, users who add your app will be redirected to your URL instead of the Add App flow in Discord.`,
  AUTHORIZATION_METHODS_SECTION_HEADING: 'Installation Contexts',
  AUTHORIZATION_METHODS_SECTION_SUBHEADING: `Select the installation contexts your app supports. Apps can be installed to both users and guilds.`,
  AUTHORIZATION_METHODS_USER_INSTALL: 'User Install',
  AUTHORIZATION_METHODS_USER_INSTALL_DETAILS:
    'Users can add your app to their account and use it everywhere on Discord.',
  AUTHORIZATION_METHODS_GUILD_INSTALL: 'Guild Install',
  AUTHORIZATION_METHODS_GUILD_INSTALL_DETAILS:
    'Users can add your app to a guild, giving it permissions to take actions in that guild.',
  AUTHORIZATION_METHODS_OPTIONS_LABEL: 'Select Methods',
  AUTHORIZATION_METHODS_SCOPES_LABEL: 'Scopes',
  AUTHORIZATION_METHODS_PERMISSIONS_LABEL: 'Permissions',
  APPLICATION_INSTALL_TYPE: 'Authorization Method',
  APPLICATION_INSTALL_TYPE_NONE: 'None',
  APPLICATION_INSTALL_TYPE_CUSTOM_URL: 'Custom URL',
  APPLICATION_INSTALL_TYPE_DISCORD_PROVIDED: 'Discord Provided Link',
  APPLICATION_CUSTOM_INSTALL_URL: 'Custom URL',
  DEFAULT_SETTINGS_SECTION_HEADING: 'Default Install Settings',
  DEFAULT_SETTINGS_SECTION_SUBHEADING: 'Choose the default set of scopes and permissions your app will request.',
};

const OAuth2 = {
  HEADING: 'OAuth2',
  SUBHEADING:
    'Use Discord as an authorization system or use our API on behalf of your users. Add a redirect URI, pick your scopes, roll a D20 for good luck, and go!',
  SUBHEADING_LINK: '[Learn more about OAuth2]({link})',
  CLIENT_SECTION_HEADING: 'Client information',
  APP_CLIENT_ID: 'Client ID',
  CLIENT_SECRET_HIDDEN: 'Hidden for security',
  SELECT_REDIRECT_URL: 'Select redirect URL',
  SCOPES: 'Scopes',
  REDIRECT_SECTION_HEADING: 'Redirects',
  REDIRECT_SECTION_SUBHEADING: `You must specify at least one URI for authentication to work. If you pass a URI in an OAuth request, it must exactly match one of the URIs you enter here.`,
  BOT_PERMISSIONS: 'Bot Permissions',
  INVALID_REDIRECT_URI: `You provided an invalid redirect URI.`,
  INVALID_RPC_ORIGIN: `You provided an invalid RPC origin.`,
  RPC_ORIGINS: 'RPC Origin(s)',
  RPC_ORIGINS_DESCRIPTION: `You must specify at least one origin for RPC to work. When establishing a connection to a
                            client RPC, it must exactly match one of the origins you enter here.`,
  APPLICATION_INSTALL_SECTION_HEADING: 'Default Authorization Link',
  APPLICATION_INSTALL_SECTION_SUBHEADING: `Pick the scopes and permissions your application needs to function, or add a custom URL. Other Discord users will be able to open your app's profile and directly add it to their server.`,
  APPLICATION_INSTALL_TYPE: 'Authorization Method',
  APPLICATION_INSTALL_TYPE_NONE: 'None',
  APPLICATION_INSTALL_TYPE_CUSTOM_URL: 'Custom URL',
  APPLICATION_INSTALL_TYPE_OAUTH2_FLOW: 'In-app Authorization',
  APPLICATION_CUSTOM_INSTALL_URL: 'Custom URL',
  URL_GENERATOR: 'OAuth2 URL Generator',
  URL_GENERATOR_DESCRIPTION:
    'Generate an invite link for your application by picking the scopes and permissions it needs to function. Then, share the URL to others!',
  AUTHORIZATION_URL: 'Generated URL',
  INTEGRATION_TYPE_SECTION_HEADING: 'Integration Type',
};

const Webhooks = {
  HEADING: 'Webhooks',
  SUBHEADING: 'Configure webhooks for your app to receive via HTTP',
  ENDPOINT_HEADING: 'Endpoint',
  ENDPOINT_SUBHEADING: 'Set a public endpoint URL to receive webhooks. [Learn more]({link})',
  ENDPOINT_URL_FORM_LABEL: 'Endpoint URL',
  ENDPOINT_URL_FORM_PLACEHOLDER: 'Add your endpoint',
  EVENTS_HEADING: 'Events',
  EVENTS_SUBHEADING: 'Send specific events to your application. [Learn more]({link})',
};

const Events = {
  APPLICATION_AUTHORIZED: 'Application Authorized',
  APPLICATION_COMMAND_PERMISSIONS_UPDATE: 'Application Command Permissions Update',
  AUTO_MODERATION_ACTION_EXECUTION: 'Auto Moderation Action Execution',
  AUTO_MODERATION_RULE_CREATE: 'Auto Moderation Rule Create',
  AUTO_MODERATION_RULE_DELETE: 'Auto Moderation Rule Delete',
  AUTO_MODERATION_RULE_UPDATE: 'Auto Moderation Rule Update',
  CHANNEL_CREATE: 'Channel Create',
  CHANNEL_DELETE: 'Channel Delete',
  CHANNEL_PINS_UPDATE: 'Channel Pins Update',
  CHANNEL_UPDATE: 'Channel Update',
  ENTITLEMENT_CREATE: 'Entitlement Create',
  ENTITLEMENT_DELETE: 'Entitlement Delete',
  ENTITLEMENT_UPDATE: 'Entitlement Update',
  GUILD_AUDIT_LOG_ENTRY_CREATE: 'Guild Audit Log Entry Create',
  GUILD_BAN_ADD: 'Guild Ban Add',
  GUILD_BAN_REMOVE: 'Guild Ban Remove',
  GUILD_DELETE: 'Guild Delete',
  GUILD_EMOJIS_UPDATE: 'Guild Emojis Update',
  GUILD_INTEGRATIONS_UPDATE: 'Guild Integrations Update',
  GUILD_MEMBER_ADD: 'Guild Member Add',
  GUILD_MEMBER_REMOVE: 'Guild Member Remove',
  GUILD_MEMBER_UPDATE: 'Guild Member Update',
  GUILD_ROLE_CREATE: 'Guild Role Create',
  GUILD_ROLE_DELETE: 'Guild Role Delete',
  GUILD_ROLE_UPDATE: 'Guild Role Update',
  GUILD_SCHEDULED_EVENT_CREATE: 'Guild Scheduled Event Create',
  GUILD_SCHEDULED_EVENT_DELETE: 'Guild Scheduled Event Delete',
  GUILD_SCHEDULED_EVENT_UPDATE: 'Guild Scheduled Event Update',
  GUILD_SCHEDULED_EVENT_USER_ADD: 'Guild Scheduled Event User Add',
  GUILD_SCHEDULED_EVENT_USER_REMOVE: 'Guild Scheduled Event User Remove',
  GUILD_STICKERS_UPDATE: 'Guild Stickers Update',
  GUILD_UPDATE: 'Guild Update',
  INTEGRATION_CREATE: 'Integration Create',
  INTEGRATION_DELETE: 'Integration Delete',
  INTEGRATION_UPDATE: 'Integration Update',
  INTERACTION_CREATE: 'Interaction Create',
  INVITE_CREATE: 'Invite Create',
  INVITE_DELETE: 'Invite Delete',
  MESSAGE_CREATE: 'Message Create',
  MESSAGE_DELETE: 'Message Delete',
  MESSAGE_DELETE_BULK: 'Message Delete Bulk',
  MESSAGE_REACTION_ADD: 'Message Reaction Add',
  MESSAGE_REACTION_REMOVE: 'Message Reaction Remove',
  MESSAGE_REACTION_REMOVE_ALL: 'Message Reaction Remove All',
  MESSAGE_REACTION_REMOVE_EMOJI: 'Message Reaction Remove Emoji',
  MESSAGE_POLL_VOTE_ADD: 'Message Poll Vote Add',
  MESSAGE_POLL_VOTE_REMOVE: 'Message Poll Vote Remove',
  MESSAGE_UPDATE: 'Message Update',
  PRIVATE_CHANNEL_INTEGRATION_CREATE: 'Private Channel Integration Create',
  PRIVATE_CHANNEL_INTEGRATION_DELETE: 'Private Channel Integration Delete',
  PRIVATE_CHANNEL_INTEGRATION_UPDATE: 'Private Channel Integration Update',
  QUEST_USER_ENROLLMENT: 'Quest User Enrollment',
  STAGE_INSTANCE_CREATE: 'Stage Instance Create',
  STAGE_INSTANCE_DELETE: 'Stage Instance Delete',
  STAGE_INSTANCE_UPDATE: 'Stage Instance Update',
  THREAD_CREATE: 'Thread Create',
  THREAD_DELETE: 'Thread Delete',
  THREAD_UPDATE: 'Thread Update',
  THREAD_MEMBER_UPDATE: 'Thread Member Update',
  THREAD_MEMBERS_UPDATE: 'Thread Members Update',
  USER_UPDATE: 'User Update',
  VOICE_CHANNEL_STATUS_UPDATE: 'Voice Channel Status Update',
  WEBHOOKS_UPDATE: 'Webhooks Update',
};

const EventGroups = {
  APPLICATIONS: 'Applications',
  APPLICATION_COMMANDS: 'Application Commands',
  AUTO_MODERATION: 'Auto Moderation',
  CHANNELS: 'Channels',
  ENTITLEMENTS: 'Entitlements',
  GUILDS: 'Guilds',
  INTEGRATIONS: 'Integrations',
  INVITES: 'Invites',
  MESSAGES: 'Messages',
  POLLS: 'Polls',
  STAGE_INSTANCES: 'Stage Instances',
  USERS: 'Users',
  VOICE: 'Voice',
  WEBHOOKS: 'Webhooks',
};

const MyApps = {
  APPLICATIONS: 'Applications',
  PAGE_TITLE: 'My Applications',
  MY_APPLICATIONS_LABEL: 'My Applications',
  CREATE_APPLICATION_MODAL_HEADING: 'Create an application',
  NEW_APPLICATION: 'New Application',
  INTRO: 'Develop [apps]({link}) to customize and extend Discord for millions of users.',
  TEAM: 'Team',
  ERROR_MUST_PROVIDE_NAME: 'A name is required to create your new application.',
  ERROR_MUST_ACCEPT_TERMS_OF_SERVICE: 'The [Terms of Service]({link}) must be accepted.',
  PERSONAL: 'Personal',
  NO_APPS_PRIMARY: `You don't have any applications yet, sad face.`,
  NO_APPS_SECONDARY: `Click New Application above to get started.`,
  NO_PERSONAL_APPS_PRIMARY: `You don't have any personal apps.`,
  NO_PERSONAL_APPS_SECONDARY: `Check out applications for your teams below!`,
};

const Navigation = {
  SELECTED_APP: 'Selected App',
  SETTINGS: 'Settings',
  INFORMATION: 'General Information',
  BOTS: 'Bot',
  APPLICATION_EMOJIS: 'Emojis',
  OAUTH2: 'OAuth2',
  INSTALLATION: 'Installation',
  WEBHOOKS: 'Webhooks',
  DISCOVERY: 'Discovery',
  DISCOVERY_SETTINGS: 'Discovery Settings',
  DISCOVERY_STATUS: 'Discovery Status',
  EMBEDDED_APPLICATION: 'Activities',
  EMBEDDED_APPLICATION_GETTING_STARTED: 'Getting Started',
  EMBEDDED_APPLICATION_SETTINGS: 'Settings',
  EMBEDDED_APPLICATION_URL_MAPPINGS: 'URL Mappings',
  EMBEDDED_APPLICATION_PUBLISH: 'Publish',
  RICH_PRESENCE: 'Rich Presence',
  VISUALIZER: 'Visualizer',
  ART_ASSETS: 'Art Assets',
  WHITELIST: 'App Testers',
  VERIFICATION_ONBOARDING: 'App Verification',
  SKU_MANAGEMENT: 'Manage SKUs',
  STORE_MANAGEMENT: 'Manage Store',
  MONETIZATION_ANALYTICS: 'Analytics',
  MONETIZATION_GETTING_STARTED: 'Getting Started',
  MONETIZATION: 'Monetization',
  TEAM: 'Team',
  BACK_TO_TEAMS: 'Back to Teams',
  SELECTED_TEAM: 'Selected Team',
  ANALYTICS: 'Analytics',
  ANALYTICS_ACTIVATION: 'Activation',
  ANALYTICS_ACQUISITION: 'Acquisition',
  ANALYTICS_ENGAGEMENT: 'Engagement',
  ANALYTICS_REVENUE: 'Revenue',
  PAYOUT_SETTINGS: 'Payout Settings',
  PAYOUT_HISTORY: 'Payout History',
  RELATED_SKUS: 'Related SKUs',
  SERVER_INSIGHTS: 'Server Insights',
  GUILD_ANALYTICS_GROWTH_ACTIVATION: 'Growth & Activation',
  GUILD_ANALYTICS_ENGAGEMENT: 'Engagement',
  GUILD_ANALYTICS_CHANNEL_FOLLOWING: 'Announcement Channels',
  GUILD_ANALYTICS_AUDIENCE: 'Audience',
  GUILD_ANALYTICS_WELCOME_SCREEN: 'Welcome Screen',
  GUILD_ANALYTICS_ROLE_SUBSCRIPTIONS: 'Server Subscriptions',
  BACK_TO_APPLICATIONS: 'Back to Applications',
  BACK_TO_GUILDS: 'Back to Servers',
  ADVANCED_METRICS: 'Advanced Metrics',
  AUDIENCE_INSIGHTS: 'Audience Insights',
  MESSAGE_REACH: 'Announcement Reach',
  SUBSCRIPTIONS: 'Subscriptions',
  ITEMS: 'Items',
  ON_THIS_PAGE: 'On this page',
};

const Actions = {
  CANCEL: 'Cancel',
  SAVE_CHANGES: 'Save Changes',
  PUBLISH_CHANGES: 'Publish Changes',
  PUBLISH_SKU: 'Publish SKU',
  UNPUBLISH_SKU: 'Unpublish SKU',
  COPY: 'Copy',
  COPIED: 'Copied',
  RESET_SECRET: 'Reset Secret',
  RESET_TOKEN: 'Reset Token',
  VIEW_TOKEN: 'View Token',
  LOG_OUT: 'Log Out',
  RESET: 'Reset',
  CREATE: 'Create',
  CONFIRM: 'Confirm',
  INVITE: 'Invite',
  REMOVE: 'Remove',
  TRANSFER: 'Transfer',
  DELETE: 'Delete',
  UPLOAD_ASSET: 'Upload Additional Asset',
  OK: 'Ok',
  COPY_FAILED: 'Copy Failed',
};

const Alerts = {
  SUCCESS_CHANGES_SAVED: 'All your edits have been carefully recorded.',
  ERROR: 'Something went wrong!',
  CANNOT_BE_UNDONE: 'Danger! This cannot be undone.',
  ASSET_UPLOADED: 'Asset successfully uploaded!',
};

const PresenceViewer = {
  USER_ACTIVITY_HEADER_PLAYING: 'Playing a game',
  USER_ACTIVITY_STATE_SIZE: '({count} of {max})',
  USER_ACTIVITY_TIMESTAMP_END:
    '{hours, plural, =-1 {} other {{hours}:}}{minutes, plural, =-1 {} other {{minutes}:}}{seconds, plural, =-1 {} other {{seconds}}} left',
  USER_ACTIVITY_TIMESTAMP_START:
    '{hours, plural, =-1 {} other {{hours}:}}{minutes, plural, =-1 {} other {{minutes}:}}{seconds, plural, =-1 {} other {{seconds}}} elapsed',
  USER_ACTIVITY_ACTION_ASK_TO_JOIN: 'Ask to Join',
  SECTION_TITLE: 'Rich Presence Visualizer',
  SECTION_MORE_INFO: `Rich Presence lets your game surface exciting game data on your players' profiles, and lets them play together with chat invites, and Ask to Join. See exactly how your text and art will look on a user's profile.`,
  PROFILE: 'Full Profile',
  USER_POPOUT: 'User Popout',
  NONE: 'None',
  SHOW_CODE: 'Show Code',
  MOBILE_ALERT: 'Rich Presence Visualizer only available on desktop',
  PARTY_ID_MUST_BE_UNIQUE: `Party ID can't match Join Secrets.`,
  VISUALIZER_ONLY_NOTICE:
    'This is only a visualizer about how rich presences will look with different assets and settings. Rich presence configuration needs to be programmed in your application. [Learn more about working with Rich Presence]({link})',
};

const Tooltips = {
  STATE: `[type: char*]\nThe user's current party status`,
  DETAILS: '[type: char*]\nWhat the player is currently doing',
  START_TIMESTAMP: `[type: int64_t]\nEpoch seconds for game start - including will show time as "elapsed"`,
  END_TIMESTAMP: `[type: int64_t]\nEpoch seconds for game end - including will show time as "remaining"	`,
  LARGE_IMAGE_KEY: '[type: char*]\nKey of the uploaded image for the large profile artwork',
  LARGE_IMAGE_TEXT: '[type: char*]\nTooltip for the largeImageKey',
  SMALL_IMAGE_KEY: '[type: char*]\nKey of the uploaded image for the small profile artwork',
  SMALL_IMAGE_TEXT: '[type: char*]\nTooltip for the smallImageKey',
  PARTY_ID: `[type: char*]\nId of the player's party, lobby, or group`,
  PARTY_SIZE: `[type: int]\nCurrent size of the player's party, lobby, or group`,
  PARTY_MAX: `[type: int]\nMaximum size of the player's party, lobby, or group	`,
  JOIN_SECRET: '[type: char*]\nUnique hashed string for chat invitations and Ask to Join',
};

const UserProfile = {
  USER_INFO: 'User Info',
  MUTUAL_SERVERS: 'Mutual Servers',
  MUTUAL_FRIENDS: 'Mutual Friends',
  NOTE: 'Note',
  ADD_NOTE: 'Click to add note',
};

const ActiveDeveloperProgram = {
  PAGE_TITLE: 'Active Developer Badge',
  PAGE_HEADING: 'Active Developer Badge',
  PAGE_SUBHEADING:
    'Claim your badge by selecting one of your active apps, linking a support server, and following our developer announcements',
  FORM_HEADING: 'Claim your badge!',
  FORM_ACTIVE_APPLICATION: 'Select an active application',
  FORM_SUPPORT_SERVER: 'Set a support server for this application',
  FORM_ANNOUNCEMENT_CHANNEL: 'Select a channel to subscribe to our announcements',
  FORM_ACTION: 'Claim',
  CLAIMED_HEADING: 'You have claimed your Active Developer Badge!',
  CLAIMED_SUBHEADING: 'Visit your profile to see your new badge!',
  NOT_ELIGIBLE_HEADING: 'You are not eligible for the Active Developer Badge',
  NOT_ELIGIBLE_SUBHEADING: 'For more information on our Developer Badge Requirements [click here]({url})',
  ERROR: 'Failed to claim',
  REMOVE_HEADING: 'No longer want the badge? Click the button below to remove it from your user profile.',
  REMOVE_CTA: 'Remove Badge',
  REMOVE_ERROR: 'Sorry, something went wrong. Please try again later.',
};

const ActiveDeveloperProgramCta = {
  HEADING: 'Active Developer Badge',
  BODY: 'Come claim your badge by joining our active developer program',
  CTA: 'Join',
};

const Locale = {
  DEFAULT_I18N_GROUP_LABEL: 'Default',
};

const SkuManagement = {
  USER_SUBSCRIPTION_HEADING: 'User Subscription Details',
  GUILD_SUBSCRIPTION_HEADING: 'Server Subscription Details',
  OTP_CONSUMABLE_HEADING: 'Consumable Item Details',
  OTP_DURABLE_HEADING: 'Item Details',
  PRICE_SUB_PLACEHOLDER: '$ / Month',
  PRICE_PLACEHOLDER: '$',
  EDIT_PHOTO: 'Edit Photo',
  UPDATE_PHOTO: 'Edit Photo',
  UPLOAD_PHOTO: 'Upload Photo',
  UPLOAD_PHOTO_DESCRIPTION: 'Upload a PNG, JPG, or GIF under 10MB. Images should be at least 680x240.',
  UPLOAD_PHOTO_DESCRIPTION_SUB: 'Upload a PNG, JPG, or GIF under 10MB. Images should be at least 250x250 1:1 ratio.',
  PAGE_HEADING: 'Manage SKUs',
  PAGE_SUBHEADING: 'SKUs are the different items that your players can purchase or get for free. Create them here.',
  SECTION_HEADING: `{count, plural, one {SKU} other {{count, number} SKUs}}`,
  NAME: 'Name',
  NONE: 'None',
  SKU_TYPE: 'Type',
  SKU_NAME: 'Name',
  SKU_DESCRIPTION: 'SKU Description',
  PRODUCT_DESCRIPTION: 'Item Description',
  SUBSCRIPTION_NAME: 'Subscription Name',
  SUBSCRIPTION_DESCRIPTION: 'Subscription Description',
  PRICE: 'Price',
  PRICE_TIER: 'Price Tier',
  STORE_APPLICATION_NAME: 'Store Application Name',
  LOCALE_DROPDOWN_DESCRIPTION: 'Use the dropdown below to select the locale you want to change the description for.',
  ADD_NEW_SKU: 'Create SKU',
  CREATE_NEW_SKU: 'Create a New SKU',
  CREATE_NEW_SKU_OF_TYPE: 'Create a new {type} SKU',
  ADD_SKU_WARNING: 'A SKU cannot be deleted once created.',
  NO_SKUS_FOUND: 'No SKUs have been created. You should create some!',
  DUPLICATE_APPLICATION_NAME: 'That name has already been taken.',
  TOO_MANY_APPLICATION_GAME_SKUS: 'You may not create more than one game SKU per application.',
  NO_SKUS_TITLE: 'Add your first SKU',
  NO_SKUS_DESCRIPTION: 'To learn more about setting up your SKUs visit our [help center]({link})',
  NO_TEAM: 'No team for this App',
  APPS_MUST_BELONG_TO_TEAM: 'Apps must belong to a Team to start earning.',
  MANAGE_TEAM: 'Manage Team for this App',
  SKU_TYPE_SUBSCRIPTION: 'Recurring Subscription',
  SKU_SUBTEXT_USER_SUBSCRIPTION: 'An auto-recurring subscription that grants benefits to one user in all servers',
  SKU_SUBTEXT_GUILD_SUBSCRIPTION: 'An auto-recurring subscription that grants benefits to all users in one server',
  OTP_DESC_CONSUMABLE: 'A one-time purchase that provides a temporary benefit, which is consumed upon use.',
  OTP_DESC_DURABLE: 'A one-time purchase that grants a permanent addition or enhancement.',
  LEARN_MORE_ABOUT_SUBSCRIPTIONS_LINK: '[Learn more about user and server subscriptions]({link})',
  CREATE_SKU_MODAL_HEADER: 'New {type} SKU',
  CREATE_SKU_MODAL_SUBSCRIPTION_LABEL: 'Subscription Name',
  CREATE_SKU_MODAL_SKU_LABEL: 'SKU Name',
  PRICE_LABEL: '${price} {suffix}',
  NO_PRICE_SET_LABEL: 'No price set',
  PRICE_PER_MONTH: 'Price Per Month',
  MONTHLY_PRICE_DESCRIPTION:
    'Subscription SKUs are automatically charged each month unless cancelled. Changing the price of this SKU will only change it for new subscribers. Existing subscribers will continue to be charged the existing price.',
  IMAGE: 'Image',
  BENEFITS: 'Benefits',
  BENEFITS_DESCRIPTION: 'Explain what your customer will get when purchasing this SKU.',
  ADD_BENEFIT: 'Add Benefit',
  SAVE_BENEFIT: 'Save Benefit',
  EMOJI: 'Emoji',
  CUSTOM_EMOJI: 'Custom Emoji',
  DEFAULT_PRICE_IN_USD: 'Price is set in USD and localized automatically at purchase.',

  PUBLISH_SKU_AVAILABLE_PLUS_STORE: 'Available via Store & API',
  PUBLISH_SKU_AVAILABLE_PLUS_STORE_DESC: 'Available to be purchased and visible in your app’s store.',
  PUBLISH_SKU_AVAILABLE: 'Available via API only',
  PUBLISH_SKU_AVAILABLE_DESC: 'You can only make API calls to grant entitlements for this SKU.',
  PUBLISH_SKU_DESCRIPTION: 'This SKU will be available for purchase immediately.',
  UNPUBLISH_SKU_DESCRIPTION: 'Make this SKU unavailable for purchase immediately',
  UNPUBLISH_SKU_DESCRIPTION_ITEM_1: 'Subscribers will not renew at the end of their billing period',
  UNPUBLISH_SKU_DESCRIPTION_ITEM_2: 'This SKU can be re-published at any time',
  UNPUBLISH_AND_DELETE_SKU: 'Unpublish and Delete SKU',
  DELETE_OTP_ALERT_ITEM_1: 'This will remove this product from being available for purchase.',
  DELETE_OTP_ALERT_ITEM_2: 'Users who have already purchased this product will still be able to use it',
  DELETE_SKU_ALERT_ITEM_1: 'This will cancel all active subscriptions to this SKU for all subscribers.',
  DELETE_SKU_ALERT_ITEM_2: 'They will not renew after their current billing cycle.',
  DELETE_SKU_WARNING_ITEM_1: 'Your subscribers will receive an email letting them know what happened',
  DELETE_SKU_WARNING_ITEM_2: 'This SKU will be deleted and unrecoverable',
  DELETE_SKU_WARNING_ITEM_3: 'This action cannot be undone',
  DELETE_SKU_CONFIRMATION: 'Please type “Delete” in the field below to delete this SKU',
  TYPE_DELETE: 'Type “Delete”',
  REMOVE_BENEFIT: 'Remove Benefit',
  REMOVE_BENEFIT_DESCRIPTION: 'Are you sure you want to remove this benefit? This action cannot be undone.',

  BENEFITS_REORDER: 'Reorder',
  BENEFITS_SAVE_ORDER: 'Save Order',

  DONE: 'Done',
  LEARN_MORE: 'Learn more about SKU types',
  IN_STOREFRONT: 'Available in your store',
};

const Storefront = {
  ADD_SKU: 'Add SKU',
  ADD_TO_STOREFRONT: 'Add to store',
  ALT_SKU_THUMBNAIL: 'SKU labeled {skuName}',
  BENEFITS: 'Exclusive to this tier',
  EDIT_SKU: 'Edit SKU',
  EMPTY_DESCRIPTION: 'Add a published item or subscription that can be available for purchase in your own storefront.',
  EMPTY_DESCRIPTION_OTP: 'Add a product that can be available for purchase in your own store.',
  EMPTY_DESCRIPTION_SUBSCRIPTION: 'Add a subscription that can be available for purchase in your own store.',
  EMPTY_TITLE: 'Add SKUs to your store',
  EMPTY_TITLE_NO_SKUS: 'Publish SKUs to add to store',
  EMPTY_TITLE_OTP: 'Add your first product',
  EMPTY_TITLE_SUBSCRIPTION: 'Add your first subscription',
  LABEL_PRICE_SUBSCRIPTION: '{price} / Mo',
  LABEL_PURCHASE: 'Buy for {price}',
  LABEL_SUBSCRIBE: 'Subscribe for {price} / Mo',
  OTP: 'Item',
  PAGE_HEADING: 'Manage Storefront',
  ITEMS: 'Items',
  PUBLISH: 'Publish',
  REMOVE_FROM_STOREFRONT: 'Remove from store',
  REORDER: 'Reorder',
  SAVE: 'Save',
  STOREFRONT: 'App Store',
  STOREFRONT_TITLE: '{appName} App Store',
  SUBSCRIPTION: 'Subscription',
  SUBSCRIPTIONS: 'Subscriptions',
  UNNAMED_ITEM: 'Unnamed item',
  UNPUBLISH: 'Unpublish',
};

const EmbeddedApplicationSkuInformation = {
  SUBHEADING: `You probably want to tell people more details about this In-App Purchase SKU. Do that here!`,
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  PUBLISHED_INFO: 'Controls SKU visibility.',
};

const EmbeddedSkuTypes = {
  DURABLE: 'Durable',
  SKU_MANAGEMENT_DURABLE: 'Durable',
  CONSUMABLE: 'Consumable',
  SUBSCRIPTION: 'Subscription',
  USER_SUBSCRIPTION: 'User Subscription',
  GUILD_SUBSCRIPTION: 'Guild Subscription',
  DURABLE_PRIMARY: 'Durable Primary',
  SKUS_SUFFIX: '{typeLabel} SKUs',
  SUBSCRIPTION_GROUP: 'Subscription Group',
  BUNDLE: 'Bundle',
  UNKNOWN: 'Unknown SKU',
};

const SkuTypes = {
  DURABLE_PRIMARY: 'Game',
  DURABLE: 'DLC',
  IAP: 'IAP',
  BUNDLE: 'Bundle',
  SUBSCRIPTION: 'Subscription',
  SUBSCRIPTION_GROUP: 'Subscription Group',
};

const SkuAccessTypes = {
  FULL: 'Full Access',
  EARLY_ACCESS: 'Early Access',
  VIP_ACCESS: 'VIP Access',
};

const AssetLibrary = {
  HEADING: 'Asset Library',
  SUBHEADING: 'Upload those assets to showcase your beautiful game.',
  ASSETS_SECTION_HEADING: 'Add Assets',
  ASSETS_SECTION_SUBHEADING: `Add assets that you want to include in your store page. You'll use the assets in the Manage SKUs section when editing your store page.`,
  ASSETS_MANAGER_HEADING: `Assets ({count, number})`,
  ADD_ASSETS: 'Add Assets',
  ASSETS: 'Assets',
  ACCEPTED_FILE_TYPES: '.png, .jpg, .jpeg, .gif, or .mp4',
  SUCCESS: 'Assets successfully added!',
};

const SkuRatings = {
  RATINGS: 'Ratings',
  RATINGS_SUBHEADING: 'Add ratings from agencies like ESRB or PEGI if your game has them.',
  ESRB_RATING: 'ESRB Rating',
  RATING: 'Rating',
  PEGI_RATING: 'PEGI Rating',
  CONTENT_DESCRIPTORS: 'Content Descriptors',
  RATING_PENDING: 'Rating Pending',
  EARLY_CHILDHOOD: 'Early Childhood',
  EVERYONE: 'Everyone',
  EVERYONE_TEN_PLUS: 'Everyone 10+',
  TEEN: 'Teen',
  MATURE: 'Mature',
  ADULTS_ONLY: 'Adults Only',
  THREE: 'Three',
  SEVEN: 'Seven',
  TWELVE: 'Twelve',
  SIXTEEN: 'Sixteen',
  EIGHTEEN: 'Eighteen',
};

const ContentDescriptors = {
  ALCOHOL_REFERENCE: 'alcohol reference',
  CARTOON_VIOLENCE: 'cartoon violence',
  FANTASY_VIOLENCE: 'fantasy violence',
  MATURE_HUMOR: 'mature humor',
  SEXUAL_CONTENT: 'sexual content',
  STRONG_LANGUAGE: 'strong language',
  TOBACCO_REFERENCE: 'tobacco reference',
  VIOLENCE: 'violence',
  SHARES_LOCATION: 'shares location',
  ANIMATED_BLOOD: 'animated blood',
  COMIC_MISCHIEF: 'comic mischief',
  INTENSE_VIOLENCE: 'intense violence',
  NUDITY: 'nudity',
  SEXUAL_THEMES: 'sexual themes',
  STRONG_LYRICS: 'strong lyrics',
  USE_OF_ALCOHOL: 'use of alcohol',
  VIOLENT_REFERENCES: 'violent references',
  UNRESTRICTED_INTERNET: 'unrestricted internet',
  BLOOD: 'blood',
  CRUDE_HUMOR: 'crude humor',
  LANGUAGE: 'language',
  PARTIAL_NUDITY: 'partial nudity',
  SEXUAL_VIOLENCE: 'sexual violence',
  STRONG_SEXUAL_CONTENT: 'strong sexual content',
  USE_OF_DRUGS: 'use of drugs',
  IN_GAME_PURCHASES: 'in game purchases',
  BLOOD_AND_GORE: 'blood and gore',
  DRUG_REFERENCE: 'drug reference',
  LYRICS: 'lyrics',
  REAL_GAMBLING: 'real gambling',
  SIMULATED_GAMBLING: 'simulated gambling',
  SUGGESTIVE_THEMES: 'suggestive themes',
  USE_OF_TOBACCO: 'use of tobacco',
  USERS_INTERACT: 'users interact',
  BAD_LANGUAGE: 'bad language',
  FEAR: 'fear',
  GAMBLING: 'gambling',
  SEX: 'sex',
  DRUGS: 'drugs',
  DISCRIMINATION: 'discrimination',
  MILD_BLOOD: 'mild blood',
  MILD_CARTOON_VIOLENCE: 'mild cartoon violence',
  MILD_FANTASY_VIOLENCE: 'mild fantasy violence',
  MILD_LANGUAGE: 'mild language',
  MILD_LYRICS: 'mild lyrics',
  MILD_SEXUAL_THEMES: 'mild sexual themes',
  MILD_SUGGESTIVE_THEMES: 'mild suggestive themes',
  MILD_VIOLENCE: 'mild violence',
  ANIMATED_VIOLENCE: 'animated violence',
};

const SkuRequirements = {
  PAGE_HEADING: 'System Requirements',
  WINDOWS: 'Windows',
  MACOS: 'MacOS',
  LINUX: 'Linux',
  PAGE_SUB_HEADING:
    'Do we need a NASA computer to play your game, or will it run on two potatoes stapled together? Tell us here!',
  MINIMUM: 'Minimum Requirements',
  RECOMMENDED: 'Recommended Requirements',
  OS_VERSION: 'OS Version',
  NETWORK: 'Network',
  PROCESSOR: 'Processor',
  DIRECTX_VERSION: 'DirectX Version',
  MEMORY: 'Memory (in MB)',
  DISK_SPACE: 'Disk Space (in MB)',
  GRAPHICS_CARD: 'Graphics Card',
  SOUND_CARD: 'Sound Card',
  PLACEHOLDER: 'Placeholder',
  NOTES: 'Notes',
  CHOOSE_LOCALE: 'Choose Locale',
};

const SkuPrice = {
  HEADING: 'Price',
  SUBHEADING: 'People should probably pay you.',
  TIERS_HEADING: 'Price Tier',
  TIERS_SUBHEADING: `Select the price tier in USD, and we'll fill in prices for other currencies for you. Note that prices for GBP and Euros are VAT-inclusive.`,
  OVERRIDES_HEADING: 'Price Overrides',
  OVERRIDES_SUBHEADING: `If you want to pick a different price for any of these currencies, you can do so here.`,
  OVERRIDES_WARNING: `Overriding the USD value will not automatically change the other fields. If you want to change the USD value, it must be a higher price than the chosen price tier.`,
  PRICING_TIER: 'Pricing Tier',
  SUBMISSION_ERROR: 'There was an error submitting your prices. Please try again.',
  NO_TIER_SELECTED: 'No tier selected. Select a tier to override its prices.',
  FREE_TIER_SELECTED: 'Overrides are not permitted for the free price tier.',
  SALE_TIERS_HEADING: 'Sale Price Tier',
  SALE_TIERS_SUBHEADING: `Select the sale price tier in USD, and we'll fill in prices for other currencies for you. Note that prices for GBP and Euros are VAT-inclusive.`,
  SALE_OVERRIDES_HEADING: 'Sale Price Overrides',
  SALE_OVERRIDES_SUBHEADING: `If you want to pick a different sale price for any of these currencies, you can do so here.`,
  FREE: `Free`,
  NO_SALE: `No sale`,
  CANNOT_SET_SALE_PRICE_WITHOUT_REGULAR_PRICE: 'You may not select a sale price without selecting a price tier first.',
};

const PromotionalAssets = {
  HEADING: 'Promotional Assets',
  SUBHEADING: `We want to show you off! Pick the assets that represent you best for the promotional places in the store. [Read here]({supportUrl}) to learn where all these images are used.`,
  PREVIEW_VIDEO: 'Preview Video',
  PREVIEW_VIDEO_DESCRIPTION: 'When users hover over your tile art, this short video will play.',
  PREVIEW_VIDEO_METADATA: '640x360 (1 MB)',
  THUMBNAIL: 'Tile Art Image',
  THUMBNAIL_DESCRIPTION: 'This image will be shown on embeds, like gift codes.',
  THUMBNAIL_METADATA: '1280x720 (1 MB)',
  HEADER_BACKGROUND: 'Header Background Image',
  HEADER_BACKGROUND_DESCRIPTION: 'This is the background image that goes at the top of your store page.',
  HEADER_BACKGROUND_METADATA: '880x100 (300 KB)',
  HEADER_LOGO_DARK_THEME: 'Header Logo Image (Dark Theme)',
  HEADER_LOGO_DARK_THEME_DESCRIPTION:
    'The game logo that overlays the header background at the top of the store page for Dark Theme users.',
  HEADER_LOGO_DARK_THEME_METADATA: '300x60 (100 KB)',
  HEADER_LOGO_LIGHT_THEME: 'Header Logo Image (Light Theme)',
  HEADER_LOGO_LIGHT_THEME_DESCRIPTION:
    'The game logo that overlays the header background at the top of the store page for Light Theme users.',
  HEADER_LOGO_LIGHT_THEME_METADATA: '300x60 (100 KB)',
  BOX_ART: 'Box Art Image',
  BOX_ART_DESCRIPTION: 'This image will be shown for games in our Nitro directory.',
  BOX_ART_METADATA: '600x800 (1 MB)',
  HERO_BACKGROUND: 'Hero Backsplash Image',
  HERO_BACKGROUND_DESCRIPTION:
    'If your game gets featured at the top of the store, this is the background image that will underlie the text and video.',
  HERO_BACKGROUND_METADATA: '2480x1000 (1 MB)',
  HERO_VIDEO: 'Hero Video',
  HERO_VIDEO_DESCRIPTION: 'If your game gets featured at the top of the store, this is the video that users will see',
  HERO_VIDEO_METADATA: '1280x720 (15 MB per minute)',
};

const BundledSkus = {
  HEADING: 'Bundled SKUs',
  SUBHEADING: 'Users will be granted entitlement to these bundled SKUs when they purchase this parent SKU.',
  ADD_BUNDLED_SKU_HEADING: 'Add Bundled SKU',
  ADD_BUNDLED_SKU_SUBHEADING: 'All bundled SKUs will be obtained the user on purchase of this bundle',
  ADDED_SKUS: 'Currently Bundled SKUs',
  ADDED_SKUS_DESCRIPTION: 'These are the SKUs bundled with this SKU.',
  ADD_SKU: 'Add a bundled SKU',
  NO_BUNDLED_SKUS: 'There are no bundled SKUs.',
  SUBMISSION_ERROR: 'There was an error adding these SKUs!',
};

const AssetManager = {
  ASSET_ERROR: 'There was an error uploading this asset.',
};

const Features = {
  HEADING: 'Features',
  SUBHEADING: 'Select all features that apply to your game.',
  AVAILABLE_FEATURES_HEADING: 'Available Features',
  FEATURE_SINGLE_PLAYER: 'Single Player',
  FEATURE_LOCAL_MULTIPLAYER: 'Local Multiplayer',
  FEATURE_ONLINE_MULTIPLAYER: 'Online Multiplayer',
  FEATURE_PVP: 'PvP',
  FEATURE_LOCAL_COOP: 'Local Cooperative',
  FEATURE_ONLINE_COOP: 'Online Cooperative',
  FEATURE_CROSS_PLATFORM: 'Cross Platform',
  FEATURE_RICH_PRESENCE: 'Rich Presence',
  FEATURE_DISCORD_GAME_INVITES: 'Discord Game Invites',
  FEATURE_SPECTATOR_MODE: 'Spectator Mode',
  FEATURE_CONTROLLER_SUPPORT: 'Controller Support',
  FEATURE_CLOUD_SAVES: 'Cloud Saves',
  FEATURE_SECURE_NETWORKING: 'Secure Networking',
};

const Locales = {
  HEADING: 'Locales',
  SUBHEADING: 'If your game has localized text, cutscenes, or audio, let players know here.',
  AVAILABLE_LOCALES_HEADING: 'Available Locales',
};

const Teams = {
  HEADING: `Teams`,
  INTRO: `Teams let Discord apps and monetization features be managed by a group of people working together. Each team member has admin privileges for anything owned by that team, so only trusted individuals should be added.`,
  PAGE_TITLE: 'My Teams',
  PAGE_SUBHEADING: 'Create a new team to work on cool projects with you, or check out an existing one.',
  PAGE_SUBHEADING_WITH_EXISTING_TEAMS: 'Manage a team and its members by selecting it below.',
  MAX_TEAMS_REACHED: 'Maximum number of teams reached ({maxTeams, number})',
  CREATE_NEW_TEAM: 'Create a team',
  TOO_MANY_TEAMS: 'Maximum number of teams reached. Users may only join or own up to {maxTeams} teams.',
  INFORMATION_PAGE_HEADING: 'Team Information',
  INFORMATION_PAGE_SUBHEADING:
    'Give your team a name and an icon. Add some members, give them roles, and make collaborating easier.',
  INFORMATION_PAGE_REQUIRED_PAYOUT_ALERT: `If you are earning with Server Subscriptions, Activities, or Premium Apps, don't forget to give us your payout information before you start selling.`,
  GO_TO_PAYOUT_SETTINGS: 'Go to Payout Settings',
  TEAM_DETAILS_HEADING: 'Team Details',
  TEAM_NAME_REQUIRED: 'Teams are required to have a name',
  TEAM_SUCCESSFULLY_UPDATED: 'Your team has successfully been updated.',
  TEAM_MEMBERS_HEADING: 'Team Members',
  TEAM_MEMBERS_SUBHEADING: `This is everyone on this team and their role. You can invite new members, change members' roles, or remove someone from the team.`,
  INVITE_TEAM_MEMBERS: 'Invite Team Members',
  TEAM_ROLE: 'Team Role',
  CURRENT_MEMBERS: 'Current Members',
  PENDING: 'Pending',
  TEAM_MEMBER_INVITED: 'New team member successfully invited!',
  REMOVE_TEAM_MEMBER: 'Remove Team Member',
  TEAM_MEMBER_REMOVED: 'Team member successfully removed.',
  INVALID_USERNAME:
    'Invalid username. Please enter a username and discriminator separated by a # to invite a team member.',
  TEAM_MEMBER_ALREADY_EXISTS: 'User is an existing team member, you cannot invite them again.',
  TEAM_MEMBERS_MUST_BE_VERIFIED:
    'Users must add a verified email address to their account before they can be added to a team.',
  TEAM_OWNER_ONLY: 'Only the owner of the team can perform this action.',
  TEAM_MEMBER_REMOVE_WARNING: 'Are you sure you want to remove {memberName} from your team?',
  SELF_REMOVE_WARNING:
    'Are you sure you want to remove yourself from this team? You will not be able to rejoin without a new invitation.',
  TRANSFER_OWNERSHIP: 'Transfer Team Ownership',
  DELETE_TEAM: 'Delete Team',
  SELECT_USER_FOR_TRANSFER: 'Select user to transfer to',
  TEAM_OWNERSHIP_TRANSFERRED: 'Successfully transferred team ownership.',
  TEAM_NOT_LOADED_ERROR: 'Invalid team',
  SUCCESSFULLY_ADDED: 'You have been successfully added to !!{teamName}!!!',
  INVITATION_ALREADY_ACCEPTED: 'You are already a member of this team!',
  UNKNOWN_TEAM_ERROR: 'Team not found.',
  UNKNOWN_TEAM_MEMBER_ERROR: 'Team member or invitation not found.',
  UNKNOWN_USER: 'User does not exist, please make sure username and discriminator are correct.',
  INVALID_INVITE_TOKEN:
    'Your team invitation token is not valid. Please ensure you are logged into the correct account.',
  CANNOT_DELETE_TEAM_WITH_APPLICATIONS: `You can't delete teams with applications. Delete or transfer ownership of this team's applications if you wish to proceed.`,
  CANNOT_DELETE_PREMIUM_MEMBERSHIP_TEAM: `You cannot delete a team with a Server Subscriptions server. To disable Server Subscriptions, please refer to [this guide]({disableServerSubsArticle})`,
  INVALID_ACCOUNT_TYPE: 'Bots and other non-user accounts cannot be added as team members.',
  GENERIC_ACCEPT_INVITATION_ERROR: `There has been an error accepting your team invitation. Please try again, or click [here](https://dis.gd/contact) to contact support.`,
  GENERIC_INVITE_USER_ERROR:
    'There has been an error inviting this team member. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  NEW_TEAM: 'New Team',
  TEAM_NAME: 'Team Name',
  NO_TEAMS_PRIMARY: `You don't have any teams yet, sad face.`,
  NO_TEAMS_SECONDARY: `Click New Team above to get started.`,
  STRIPE_ONBOARDING_FORM_TITLE: `Complete your profile`,
};

const PayoutSettings = {
  HEADING: 'Payout Settings',
  SUBHEADING: `Enter your information so we know where to send your money. **You must use your legal name or business name**, instead of a pseudonym.`,
  BODY: `We need to collect information on how to pay you before you can sell on Discord. This is securely collected by a third-party company.`,
  ALERT_PENDING: `Your payout information is currently being scrutinized by our financial goblins. We'll notify you when they come to a verdict.`,
  ALERT_ACTION_REQUIRED: `After reviewing your payout information, we determined that we need to collect additional information to make a sound decision. Edit by clicking the button below.`,
  ALERT_PAYABLE: `Payout info added! Edit at any time by clicking the button below.`,
  ALERT_BLOCKED: `You are not eligible to sell on Discord... If you believe there was an error, please [contact our support](https://dis.gd/contact).`,
  BUTTON_GET_STARTED: `Get Started`,
  BUTTON_EDIT: `Edit Payout Info`,
  ALERT_NOT_STRIPE:
    "You're approved for payouts, but not on Stripe. Migrate your account to Stripe or contact [developer support]({developerSupportUrl}).",
};

const PayoutHistory = {
  HEADING: 'Payout History',
  SUBHEADING: `Every payout transaction will be documented here.`,
  NO_PAYOUTS_FOUND_PRIMARY: 'No payouts found',
  NO_PAYOUTS_FOUND_SECONDARY: `There aren't any payouts for this team.`,
  DATE_RANGE: 'Date range',
  AMOUNT: 'Amount',
  STATUS: 'Status',
  OPEN: 'Open',
  PAID: 'Paid',
  PENDING: 'Pending',
  MANUAL: 'Manual',
  CANCELED: 'Canceled',
  CURRENT: 'Current',
  DOWNLOAD_CSV: 'Download CSV',
  BY_TRANSACTION: 'By Transaction',
  BY_SKU: 'By SKU',
};

const Checklist = {
  PAGE_HEADING: 'Server Commerce Checklist',
  PAGE_SUBHEADING: 'Check off the boxes below and apply to get server commerce approval.',
  PAGE_SUBHEADING_INTRO: 'Welcome to the first stop on the road to publishing your game on Discord.',
  OPTIONAL: 'optional',
  APPLICATION: 'Application',
  RICH_PRESENCE: 'Rich Presence',
  GAME_SKU: 'Game SKU',
  BUILDS: 'Builds',
  STORE_PAGE: 'Store Page',
  PROMOTIONAL_ASSETS: 'Promotional Assets',
  TEAM: 'Team',
  GUILD: 'Server',
  COMPLETE: 'Complete',
  NOT_COMPLETE: 'Not Complete',
  COMPLETE_SUBTEXT_1: 'You did it!',
  COMPLETE_SUBTEXT_2: `You did it, optional edition.`,
  COMPLETE_SUBTEXT_3: 'Almost there.',
  COMPLETE_SUBTEXT_4: `Don't forget our eggs!`,
  COMPLETE_SUBTEXT_5: `XP Earned: 1337, Gold Earned: 9001`,
  COMPLETE_SUBTEXT_6: `*insert 8-bit fanfare music here*`,
  COMPLETE_SUBTEXT_7: `*unskippable credit scene loading*`,
  COMPLETE_SUBTEXT_GUILDS: `Great work!`,
  INCOMPLETE_SUBTEXT: `{remaining} out of {total} tasks remaining`,
  LOAD_ERROR: 'Error loading application data.',
  PICK_COMMERCE_SERVER: 'Pick your server',
  COMMERCE_SERVER_DESCRIPTION: 'You must have Administrator permissions in the server for it to appear here.',
  ELIGIBLE_SERVERS: 'Eligible Servers',
  GET_APPROVED: 'Get Approved',
  LICENSE_APPLIED: 'Server has been set, license successfully activated!',
  YOUR_CONNECTED_SERVER: 'Your connected server',
  HOME_TO_YOUR_GAME: `This server is now home to your game. This can't be changed.`,
  TOOLTIP_INCOMPLETE: `Woah buddy. Complete all the required tasks before applying.`,
  TOOLTIP_SUBMISSIONS_PAUSED: 'Game submissions are currently paused.',
  IMMUTABLE_GUILD_WARNING: 'This can’t be changed once you hit Save. No take backs.',
  DEVLEOPER_LICENSE_REQUIRED: `You don't have a developer license available. [Purchase one now](onActionClick).`,
  SUBMISSIONS_PAUSED: `Game approval submissions are currently paused due to unforeseen circumstances. We apologize for the inconvenience. [Click here for more info.]({gameSubmissionHelpURL})`,
};

const StoreApprovalModal = {
  CONFIRM_HEADER: 'Ready to submit?',
  CONFIRM_BODY_1: `Way to go! The Discord team will now check out your store page, your game build, and your server.`,
  CONFIRM_BODY_2: `Once approved, you can start selling your game! Give that envelope one last lick.`,
  BUTTON_APPLY: 'Apply!',
  SUCCESS_HEADER: 'Application submitted!',
  SUCCESS_BODY:
    'Superb lick. You should get an email from us very soon. In the meantime, kick back and relax with a refreshing pappi cola.',
};

const StoreSignupModal = {
  SIGNUP_HEADER: 'Build Your Kingdom',
  SIGNUP_BODY_1: `Discord's new server commerce tools allow you to set up shop in your server, find your fans, and build your kingdom.`,
  SIGNUP_BODY_2: `Start by making a Team. Then make a new app, and bring your game to life!`,
  BUTTON_CONFIRM: `Let's go!`,
};

const ApplicationChecklistItems = {
  GIVE_GAME_NAME: 'Give your game a name',
  UPLOAD_ICON: 'Give it an icon',
  PROVIDE_DEVELOPER_NAME: 'Tell us who developed it',
  PROVIDE_PUBLISHER_NAME: 'Tell us who published it',
  CREATE_SKU: 'Make your Game SKU',
  WRITE_DEFAULT_DESCRIPTION: 'Write default description',
};

const RichPresenceChecklistItems = {
  UPLOAD_INVITE_COVER_IMAGE: 'Set a cover image for chat invites',
  UPLOAD_TWO_ASSETS: 'Upload at least two assets',
  CONNECT_TO_DISCORD: 'Connect to Discord at least once',
};

const GameSKUChecklistItems = {
  SET_RELEASE_DATE: 'Tell us when your game released',
  SET_LEGAL_NOTICE: 'Write some legal mumbo-jumbo if you need to',
  SET_PRICE: 'Set a price for your game',
  MINIMUM_SYSTEM_REQUIREMENTS: 'Define your minimum system requirements',
  MINIMUM_SYSTEM_REQUIREMENTS_LOCALIZED: 'Add minimum system requirements in at least one other language',
  RECOMMENDED_SYSTEM_REQUIREMENTS: 'Define your recommended system requirements',
  RECOMMENDED_SYSTEM_REQUIREMENTS_LOCALIZED: 'Add recommended system requirements in at least one other language',
  SET_RATINGS: 'Give us your ESRB/PEGI ratings if you have them',
  SET_FEATURES: `Pick your game's features`,
  SET_GENRES: `Pick your game's genres`,
};

const BuildsChecklistItems = {
  HAVE_PUBLISHED_BUILD_ON_MASTER_BRANCH: 'Publish a build on your master branch',
  SELECT_ONE_MANIFEST_LABEL: 'Select at least one manifest label',
};

const TeamChecklistItems = {
  TEAM_IS_PAYABLE: 'Submit valid payout information',
};

const GuildChecklistItems = {
  COMMERCE_GUILD_SELECTED: `Select the server that will be home to your game`,
};

const StorePageChecklistItems = {
  ENGLISH_SHORT_SUMMARY: 'Write a default short summary',
  LOCALIZED_SUMMARY: 'Add a localized short summary in at least one other language',
  ENGLISH_LONG_DESCRIPTION: 'Write a default long description',
  LOCALIZED_DESCRIPTION: 'Add a localized long description in at least one other language',
  ONE_VIDEO_CAROUSEL_ASSET: 'Pick at least 1 video for your asset carousel',
  THREE_SCREENSHOTS_CAROUSEL_ASSETS: 'Pick at least 3 images for your asset carousel',
  DEFAULT_TAGLINE: 'Write a default tagline',
  LOCALIZED_TAGLINE: 'Write a localized tagline',
};

const Analytics = {
  DATE: 'Date',
  INFO_IGNORE_DATE_SELECTOR: `Charts below are independent of the date range or interval you've selected. They are based on **the last 28 days.**`,
  NO_DATE_SELECTOR_SUBHEADING: `All data is based on members who visited the server in **the last 28 days.**`,
  PREMIUM_GUILD_DATE_LIMIT: `Only data from the last 120 days are available for non-Partner and non-Verified servers.`,
  GDPR_DISCREPANCY: `Users who opted-out of analytics tracking will not show up in the data.`,
  DATA_UNAVAILABLE_BEFORE_DATE_NOTICE: 'Data is unavailable before {minDate}.',
  NO_BOT_ACTIVITY: `Messages from bots are excluded.`,
  KEY_METRICS: 'Key Metrics',
  KEY_METRICS_LATEST_DATA_TOOLTIP:
    'Key Metrics are always based on the most recent day, week, or month, regardless of the selected date range.',
  KEY_METRICS_CHANGE_DAILY: '{pctChange} ({formattedChange}) compared to **yesterday**',
  KEY_METRICS_CHANGE_WEEKLY: '{pctChange} ({formattedChange}) compared to **last week**',
  KEY_METRICS_CHANGE_MONTHLY: '{pctChange} ({formattedChange}) compared to **last month**',
  KEY_METRICS_CHANGE_HOURLY: '{pctChange} ({formattedChange}) compared to **last hour**',
  KEY_METRICS_COMPARISON_DATES: 'Comparing {currentDate} to {previousDate}',
  GROWTH_ACTIVATION_HEADING: 'Growth & Activation',
  ALL_TIME_JOINS: 'All Time Joins',
  ALL_TIME_JOINS_TOOLTIP: 'Total number of users who have ever joined this server.',
  NEW_COMMUNICATORS: 'New Communicators',
  NEW_COMMUNICATORS_TOOLTIP: 'New members who talked (voice or text).',
  NEW_MEMBER_RETENTION_TOOLTIP: 'Percentage of new members that still came back to the server the week after joining.',
  MEMBERSHIP_BY_SOURCE_HEADING: 'How many new members are joining?',
  MEMBERSHIP_BY_SOURCE_SUBHEADING: 'Also, where are they coming from?',
  DISCOVERY_JOINS: 'Server Discovery',
  INVITES: 'Invite',
  VANITY_JOINS: 'Vanity URL',
  HUBS_JOINS: 'Hub',
  BOT_JOINS: 'Bot',
  INTEGRATION_JOINS: 'Integration',
  OTHER_JOINS: 'Others',
  TOTAL_MEMBERSHIP_HEADING: 'Total membership over time',
  TOTAL_MEMBERS: 'Total members',
  SERVER_LEAVES: 'Server leaves over time',
  LEAVERS: 'Leavers',
  UNKNOWN_CHANNEL: 'Not yet available',
  UNKNOWN_CHANNEL_TOOLTIP: 'New Community servers have to wait 24 hours for channel names data',
  SERVER_LEAVES_TOOLTIP: 'People who leave on their own. Does not include kicks, prunes, or Discord bans.',
  NEW_MEMBER_ACTIVATION_HEADING: 'How many new members successfully activate on their first day?',
  NEW_MEMBER_ACTIVATION_SUBHEADING: `A new member is "activated" when they understand what the community is about and why it’s a fit for them. Usually, this is reflected by them at least sending a message or exploring the server beyond the first few channels.`,
  NEW_MEMBER_ACTIVATION_SUBHEADING_2: `New members who don't activate tend to remain inactive, so try to improve this. How can you make it easier for new members to onboard into your community?`,
  BENCHMARKS_SUBHEADING: `Benchmarks are based on the top active communities on Discord.`,
  CHART_LOOKS_CRAZY_NOTICE: `If the chart looks crazy, you may not have enough new members joining daily. Try changing the interval to Weekly or Monthly.`,
  NEW_MEMBER_RETENTION_HEADING: 'How many new members retain the next week?',
  NEW_MEMBER_RETENTION_SUBHEADING: `Retention measures how many new members stick around in the server beyond their first day. To improve this number, **focus on improving activation (see chart above)** and making your community more active.`,
  NEW_MEMBER_RETENTION_SUBHEADING_2: `How to read the numbers: If April 1st has a Week 1 Retention of 20% with 10 new members joining, it means that of the 10 new people who joined, 2 of them came back to the server again the next week (April 8th - April 15th). As such, **the retention line will always be 2 weeks behind the current date**.`,
  NEW_MEMBER_RETENTION: 'New Member Retention',
  WEEK_ONE_RETENTION: 'Week 1 retention',
  RETENTION_BENCHMARK: 'Benchmark: {value} Retained',
  NEW_MEMBERS: 'New members',
  PCT_TALKED: '% talked (voice or 3+ messages)',
  PCT_VISITED_CHANNEL: '% visited more than 3 channels',
  PCT_ACTIVATED: '% activated',
  ACTIVATION_BENCHMARK: 'Benchmark: {value} Talked',
  INVITE_LINK: 'Invite Link',
  MOST_POPULAR_INVITES: 'Most popular invites',
  REFERRING_DOMAIN: 'Referring Domain',
  MOST_POPULAR_REFERRERS: 'Most popular referrers',
  REFERRERS_TOOLTIP:
    'Referrer is the website that someone was on before they clicked on your invite link. Not all websites or apps support this.',
  VISITORS: 'Visitors',
  CHANNEL: 'Channel',
  GUILD_NAME: 'Server Name',
  GUILD_DESCRIPTION: 'Description',
  GUILD_CATEGORY: 'Server Category',
  GUILD_MEMBERS: 'Members',
  READERS: 'Readers',
  MESSAGES: 'Messages',
  MESSAGE: 'Message',
  DATE: 'Date',
  LINK_CLICKS: 'Link Clicks',
  COMMUNICATORS_HEADING: 'How many members visited and communicated?',
  VISITORS_DESCRIPTION: `A visitor is a member who has clicked into the server and has viewed at least 1 channel.`,
  COMMUNICATORS_DESCRIPTION: `A communicator is a visitor who has sent at least 3 messages and/or has spoken in a voice channel.`,
  COMMUNICATORS: 'Communicators',
  MESSAGE_ACTIVITY_HEADING: 'Message activity',
  PCT_COMMUNICATORS: '% communicators',
  COMMUNICATORS_BENCHMARK: 'Benchmark: {value} Communicators',
  TOTAL_MESSAGES_SENT: 'Total messages sent',
  TOTAL_VOICE_MINUTES: 'Total voice minutes',
  MESSAGES_SENT: 'Messages sent',
  AVERAGE_MESSAGES_PER_COMMUNICATOR: 'Average messages per communicator',
  MESSAGES_PER_COMMUNICATOR: 'Messages per communicator',
  MESSAGES_BENCHMARK: 'Benchmark: {value} messages per sender',
  VOICE_ACTIVITY_HEADING: 'Voice activity',
  SPEAKING_MINUTES: 'Speaking minutes',
  MUTERS_HEADING: 'How many people muted my server?',
  MUTERS_TOOLTIP: `New members are users who have been in your server for less than 1 day.`,
  PRUNEABLE_MEMBERS_HEADING: 'How many users can I prune?',
  MOST_POPULAR_TEXT_CHANNELS: 'Which text channels do people use the most?',
  MOST_POPULAR_VOICE_CHANNELS: 'Which voice channels do people use the most?',
  LISTENERS: 'Listeners',
  INACTIVE_FOR_NUM_DAYS: 'Inactive for {num} days',
  AUDIENCE_HEADING: 'Audience',
  AUDIENCE_ANONYMITY_NOTICE: `To protect user privacy, unless a given group has more than 50 members, it will not be shown as a distinct result and may be grouped into “Other.”`,
  PARTICIPATORS_BY_REG_COUNTRY_HEADING: 'Which countries are my members from?',
  PARTICIPATORS_BY_PLATFORM_HEADING: 'What devices do they use?',
  PARTICIPATORS_BY_GUILD_TENURE_HEADING: 'How long have they been a member of the server?',
  PARTICIPATORS: 'Participators',
  NEW_MEMBERS_BY_DISCORD_TENURE_HEADING: 'Are my new members also new to Discord?',
  ACTIVATION_HEADING: 'Activation',
  ACTIVATION_SUBHEADING:
    'See if new acquirers are installing and playing your game and if older players are reactivating.',
  ACQUISITION_HEADING: 'Acquisition',
  ACQUISITION_SUBHEADING: 'Learn where visitors who acquire your game are coming from. [Learn More]({supportUrl})',
  ENGAGEMENT_HEADING: 'Engagement',
  ENGAGEMENT_SUBHEADING: 'Monitor your overall active player base and player retention. [Learn More]({supportUrl})',
  ENGAGEMENT_VISITORS_TOOLTIP: 'A member who has clicked into the server and viewed a channel.',
  ENGAGEMENT_COMMUNICATORS_TOOLTIP: 'A visitor who sent at least 3 messages and or spoken.',
  WELCOME_SCREEN_HEADING: 'Welcome Screen',
  WELCOME_SCREEN_VIEWERS: 'People who saw the welcome screen',
  WELCOME_SCREEN_FUNNEL_HEADING: 'What are new members clicking and do they activate afterwards?',
  WELCOME_SCREEN_FUNNEL_PCT_CLICKED: '% Clicked',
  WELCOME_SCREEN_FUNNEL_PCT_SENT_MESSAGE: '% Sent message',
  CHANNEL_FOLLOWING_TOTAL_FOLLOWING: 'Total servers following',
  CHANNEL_FOLLOWING_NEW_FOLLOWERS_HEADING: 'How many new servers are following me? ',
  CHANNEL_FOLLOWING_NEW_FOLLOWERS: 'New followers',
  CHANNEL_FOLLOWING_TOTAL_FOLLOWERS_HEADING: 'Total servers following over time',
  CHANNEL_FOLLOWING_TOTAL_FOLLOWERS: 'Total followers',
  CHANNEL_FOLLOWING_UNFOLLOWERS_HEADING: 'How many servers unfollowed me?',
  CHANNEL_FOLLOWING_UNFOLLOWERS: 'Servers unfollowed',
  CHANNEL_FOLLOWING_GUILD_SIZE_HEADING: 'What are the sizes of servers following me?',
  CHANNEL_FOLLOWING_PUBLISHED_MESSAGES: 'Published messages',
  CHANNEL_FOLLOWING_ALL_CHANNELS_OPTION: 'All Channels',
  MESSAGE_ID: 'Message ID',
  MESSAGE_CONTENTS: 'Message Contents',
  SERVERS_REACHED: 'Servers reached',
  AVG: 'AVG',
  WEEK: 'Week',
  EIGHT_WEEK_PERIOD: '8 WEEK PERIOD',
  REVENUE_HEADING: 'Revenue',
  REVENUE_SUBHEADING:
    'See all transactions and activity related to your game revenue. Acquisition of free content will not show here. [Learn More]({supportUrl})',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  AGGREGATION_INTERVAL: 'Interval',
  DATE_RANGE: 'Date Range',
  PAGE_VIEWS: 'Page Views',
  CVR: '% CVR',
  PERCENT_CTR: '% CTR',
  CTR: 'CTR',
  ACQUISITIONS: 'Acquisitions',
  EXTERNAL_CONVERSIONS: 'Acquisitions from External Referrals',
  UTM_CONVERSIONS: 'Acquisitions from Tracked UTM Links',
  UTM_SOURCE: 'Source',
  UTM_MEDIUM: 'Medium',
  UTM_CAMPAIGN: 'Campaign',
  UTM_TERM: 'Term',
  UTM_CONTENT: 'Content',
  IMPRESSION_TYPE: 'Impression Type',
  IMPRESSIONS: 'Impressions',
  REACTIONS: 'Reactions',
  PERCENT_ALL_IMPRESSIONS: '% All Impressions',
  NO_DATA_FOR_PERIOD: 'No data for selected period.',
  IN_APP_IMPRESSIONS: 'Acquisitions from Impressions',
  NET_REVENUE_CHART: 'Net Revenue by SKU by Day',
  NET_REVENUE: 'Net Revenue',
  NET_REVENUE_BY_COUNTRY: 'Net Revenue by Country',
  NET_UNITS_CHART: 'Net Units Sold by SKU by Day',
  NET_UNITS: 'Net Units Sold',
  NET_UNITS_BY_COUNTRY: 'Net Units Sold by Country',
  SKU_PERFORMANCE_TABLE: 'SKU Performance',
  SKU_NAME: 'SKU Name',
  GROSS_UNITS_SOLD: 'Gross Units Sold',
  REFUNDED_UNITS: 'Refunded Units',
  NET_UNITS_SOLD: 'Net Units Sold',
  SHARE_OF_UNITS: '% Total Units',
  GROSS_REVENUE: 'Gross Revenue',
  TAXES: 'Taxes',
  FEES: 'Fees',
  REFUND_AMOUNT: 'Refunds, Chargebacks, Fraud (RCF)',
  SHARE_OF_REVENUE: '% Total Revenue',
  ACQUISITIONS_BY_COUNTRY: 'Acquisitions by Country',
  STORE_LISTING_PAGE_VIEWS: 'Store Listing Page Views by Source',
  OTHER: 'Other',
  EXPORT_CSV: 'Export CSV',
  LEARN_MORE: 'Learn More',
  ACTIVE_PLAYERS_CHART: 'Active Players By Country',
  PLAYER_RETENTION_CHART: 'Player Cohort Retention',
  PLAYERS: 'Players',
  RETENTION: '% Retention',
  PERCENT_WEEK_ONE: '% Week 1',
  PERCENT_WEEK_TWO: '% Week 2',
  PERCENT_WEEK_FOUR: '% Week 4',
  PERCENT_WEEK_EIGHT: '% Week 8',
  INSTALLATIONS: 'Installations',
  ACQUIRE_TO_PLAY_FUNNEL: 'Acquire to Play Funnel',
  NEW_PLAYERS: 'New Players',
  NEW_PLAYERS_WITH_EXPLANATION: 'New Players (1st Time Played)',
  REACTIVATED_PLAYERS: 'Reactivated Players',
  REACTIVATED_PLAYERS_WITH_EXPLANATION: 'Reactivated Players (1st Time Played in 30 Days)',
  TOTAL: 'Total',
  UNIQUE: 'Unique',
  UTM_TABLE_EMPTY:
    'You can track conversions of traffic to your Discord store page. [Click here]({supportUrl}) to learn more.',
  DATA_CURRENT_THROUGH: 'Data current through',
  DATA_CURRENT_THROUGH_VALUE: 'Last updated {date} (UTC)',
  DATA_AS_OF_DATE_WARNING: 'Data is currently only available through {date} (UTC).',
  JOINS: 'Joins',
  VIEWERS: 'Viewers',
  JOINS_BY_SOURCE: 'Where are new server members coming from?',
  MESSAGE_CONTENT: 'Message Content',
  SERVERS_FOLLOWING: 'Servers Following',
  USERS: 'Users',
  GUILD_ANALYTICS_CHANNEL_FOLLOWING_HEADING: 'Announcement Channels',
  MEMBERS: 'Members',
  LURKERS: 'Lurkers',
  CHATTERS: 'Chatters',
  CHANNEL_NAME: 'Channel Name',
  GUILD_SIZE_REQUIRED: 'Your server must have more than 500 members to view these stats.',
  CHART_DATA_ACCURACY_ISSUE_WARNING:
    'We are aware of issues with the data accuracy within these charts. The team is on and will fix it ASAP.',

  // Role Subscriptions
  ROLE_SUBSCRIPTIONS_HEADING: 'Server Subscriptions',
  ROLE_SUBSCRIPTIONS_MONTHLY_REVENUE: 'Estimated Monthly Revenue',
  ROLE_SUBSCRIPTIONS_MONTHLY_REVENUE_TOOLTIP:
    'Estimate based on {subscribers} paying subscribers (excluding those that have cancelled). Actual revenue may vary.',
  ROLE_SUBSCRIPTIONS_ACTIVE_MEMBERS: 'Paying Members',
  ROLE_SUBSCRIPTIONS_ACTIVE_MEMBERS_TOOLTIP: 'Members with active paid subscriptions. Does not include free trials.',
  ROLE_SUBSCRIPTIONS_MONTHLY_REVENUE_DISCLAIMER:
    'Some subscriber payments may not be included in the current month, but the following one. Please refer to [this article]({articleUrl}) for more information.',
  ROLE_SUBSCRIPTIONS_REVENUE_HEADING: 'What is my revenue per tier?',
  ROLE_SUBSCRIPTIONS_REVENUE_SUBTITLE: 'Revenue includes fees and deductions.',
  ROLE_SUBSCRIPTIONS_REVENUE_BY_PLATFORM_HEADING: 'What is my revenue per platform?',
  ROLE_SUBSCRIPTIONS_REVENUE_BY_PLATFORM_SUBTITLE: 'Revenue includes fees and deductions.',
  ROLE_SUBSCRIPTIONS_ACTIVE_MEMBERS_HEADING: 'How many active members do I have in each tier?',
  ROLE_SUBSCRIPTIONS_MEMBER_SIGNUPS_HEADING: 'How many new members are subscribing?',
  ROLE_SUBSCRIPTIONS_CANCELLATION_HEADING: 'Member Cancellations',
  ROLE_SUBSCRIPTIONS_CANCELLATION_SUBTITLE:
    'A cancellation occurs when a Premium member chooses to end their subscription.',
  ROLE_SUBSCRIPTIONS_MEMBER_CHURN_HEADING: 'Member Churn',
  ROLE_SUBSCRIPTIONS_MEMBER_CHURN_SUBTITLE: 'Churn occurs at the end of a canceled subscription’s billing period.',
  ROLE_SUBSCRIPTIONS_TRIALS_STARTED_HEADING: 'Trials Started',
  ROLE_SUBSCRIPTIONS_ACTIVE_TRIALS_HEADING: 'Active Trials',
  ROLE_SUBSCRIPTIONS_TRIALS_CONVERSION_HEADING: 'How many trials converted to paid memberships?',

  // Server insights upsell
  ADD_ONBOARDING: 'Add onboarding to improve retention',
  ADD_ONBOARDING_SUBTITLE:
    ' Make joining less overwhelming for new members. Let users easily choose roles without bots.',
  ADD_ONBOARDING_CTA: "Let's Go!",
  TESTIMONIAL_TEXT:
    'This feature has allowed us to remove command verification, increasing our overall member retention by a good 10-20% LOVE this feature :)',
  TESTIMONIAL_USER: 'Keiran, Teamagers admin',

  // Project Tetris
  PROJECT_TETRIS_AUDIENCE_HEADING: 'Advanced Audience Insights',
  PROJECT_TETRIS_MESSAGE_REACH_HEADING: 'Advanced Message Reach',
  PROJECT_TETRIS_MESSAGE_REACH_SELECT_REACH_TYPE_OPTION_DIRECT: 'View reach within my server',
  PROJECT_TETRIS_MESSAGE_REACH_SELECT_REACH_TYPE_OPTION_CROSSPOSTED: 'View reach from following servers',
  PROJECT_TETRIS_MESSAGE_REACH_SELECT_REACH_TYPE_OPTION_TOTAL: 'View total reach',
  PROJECT_TETRIS_ACTIVITY_BY_DAY_OF_WEEK_HEADING: 'Activity by Day of the Week',
  PROJECT_TETRIS_ACTIVITY_BY_TIME_OF_DAY_HEADING: 'Activity by Time of the Day',
  PROJECT_TETRIS_ACTIVITY_BY_TIME_OF_DAY_TOOLTIP: 'Displayed in your local time ({abbreviatedTimezoneString})',
  PROJECT_TETRIS_INFERRED_AGE_DISTRIBUTION: 'Inferred Age Distribution',
  PROJECT_TETRIS_INFERRED_GENDER_DISTRIBUTION: 'Inferred Gender Distribution',
  PROJECT_TETRIS_LANGUAGE_DISTRIBUTION: 'Language Distribution',
  PROJECT_TETRIS_OTHER_GUILD_MEMBERSHP: 'My Members Also Belong To...',
  PROJECT_TETRIS_OTHER_GUILD_INTERESTS: 'My Members Are Interested In...',
  PROJECT_TETRIS_ANNOUNCEMENT_MESSAGE_IMPRESSIONS: 'Announcement Reach',
  PROJECT_TETRIS_GUILD_LEAVE_REASON: 'Why Members Are Leaving?',
  PROJECT_TETRIS_CHANNEL: 'Channel',
  PROJECT_TETRIS_NUM_PARTICIPANTS: '# Users',
  PROJECT_TETRIS_GUILDS_FOLLOWING: 'Discoverable Servers Following My Channels',
  PROJECT_TETRIS_GUILDS_FOLLOWING_TABLE_SIZE: 'Size',
  PROJECT_TETRIS_GUILDS_FOLLOWING_TABLE_CATEGORY: 'Category',
  PROJECT_TETRIS_GUILDS_FOLLOWING_NO_DATA_MESSAGE: 'Ahhh, feel the breeze',
  PROJECT_TETRIS_GUILDS_FOLLOWING_NO_RESULTS_FOR_FILTERS: 'No results for the selected filters.',
  PROJECT_TETRIS_GUILDS_FOLLOWING_NO_FOLLOWERS: 'Uh oh, looks like no one is following you yet.',
  PROJECT_TETRIS_MESSAGE_REACH_MESSAGE_FAILED_TO_LOAD: 'Failed to load message.',
  PROJECT_TETRIS_MESSAGE_REACH_REACTION_OVERFLOW_COUNT: '+{numReactions, number} more',
  PROJECT_TETRIS_AGGREGATE_TOPIC: 'Topic',
  PROJECT_TETRIS_NUM_CONVERSATIONS: '# Conversations',
  PROJECT_TETRIS_NUM_MESSAGES: '# Messages',
  PROJECT_TETRIS_NUM_USERS: '# Users',
  PROJECT_TETRIS_LAST_UPDATE_UTC: `Last updated {date} (UTC)`,
  NONE: 'None',
};

const PromotionalAssetsChecklistItems = {
  HERO_CAROUSEL_BACKSPLASH: 'Choose an image for the Hero Carousel backsplash',
  HERO_CAROUSEL_FULL_TRAILER_VIDEO: 'Pick a video for the  Hero Carousel full trailer',
  TILE_ART_IMAGE: 'Select an image for your tile art',
  DIRECTORY_HOVER_VIDEO: `Choose a video for your tile's hoverstate`,
  STORE_PAGE_HEADER_BACKGROUND_IMAGE: 'Select an image for the store page background header',
  STORE_PAGE_HEADER_IMAGE: 'Pick an image for the store page header',
};

const Metadata = {
  HEADING: 'Game Metadata',
  SUBHEADING: `Update your game's metadata here!`,
  PUBLISHERS: 'Publishers',
  DEVELOPERS: 'Developers',
  MAX_COMPANIES_REACHED: `You've created the maximum number of companies ({maxCompanies, number}). For help deleting companies, please [contact our support team](https://dis.gd/contact).`,
};

const MultiTextInput = {
  ADD_ONE: 'Add {name}',
  ADD_ANOTHER: 'Add Another',
};

const PizzaTracker = {
  HEADING: 'Road to Publishing',
  CURRENT_STAGE: 'Current Stage: ',
  COMPLETE_CHECKLIST: 'Complete checklist',
  SUBMIT_FOR_APPROVAL: 'Submit for approval',
  CHANGES_REQUESTED: 'Changes requested',
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved!',
  TRACKER_ALT_TEXT: 'Pizza tracker bar',
};

const Address = {
  COUNTRY: 'Country',
};

const Errors = {
  ERROR_LOADING_ASSETS: 'Error loading store assets',
  ERROR_LOADING_GUILDS: 'Error loading servers',
  ERROR_LOADING_APPLICATIONS:
    'Error fetching applications. Please reload page, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_TRANSFERRING:
    'Error completing transfer. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_SUBMITTING_FORM:
    'Error submitting form. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_SAVING_PROMOTIONAL_ASSETS:
    'Error saving promotional assets. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_CREATING_TEAM:
    'Error creating Team. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_FETCHING_TEAMS:
    'Error fetching teams. Please reload page, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_DELETING_TEAM_MEMBER:
    'Error deleting team member. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_DELETING_TEAM:
    'Error deleting team. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_ACTIVATING_LICENSE:
    'Error activating license. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_LOADING_ANALYTICS_DATA:
    'Error loading analytics data. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  ERROR_LOADING_APPLICATION_EMOJIS:
    'Error loading application emojis. Please try again, or click [here](https://dis.gd/contact) to contact support.',
  VALIDATION_ERRORS: 'Validation errors:',
  VERIFIED_EMAIL_REQUIRED: 'You must add a verified email address to access this feature.',
  ERROR_LOADING_PROXY_CONFIG: 'Error fetching application proxy config',
  ERROR_LOADING_EMBEDDED_ACTIVITY_CONFIG: 'Error fetching activity config',
  ERROR_SAVING_SKU_BENEFITS: 'Error saving benefits',
  ERROR_TOO_MANY_LISTINGS:
    'Too many listings attached to this SKU. Click [here](https://dis.gd/contact) to contact support.',
  ERROR_LOCALHOST_INVALID_TARGET: 'Error: "localhost" is an invalid url target.',
};

const ChildSKUs = {
  HEADING: 'Related SKUs',
  SUBHEADING: `Link to related items on this SKU's store page. All together now!`,
  BUTTON_ADD: 'Link SKU',
  NO_CHILD_SKUS: 'No SKUs are linked.',
  CHILD_SKUS: 'Currently Linked SKUs',
  CHILD_SKUS_DESCRIPTION:
    'These are the SKUs currently linked to this SKU. They will appear in this order. Feel free to put them in their place!',
};

const SocialMedia = {
  TWITTER_HANDLE: '@Discord',
};

const Guilds = {
  HEADING: 'Server Insights',
  INTRO: `Here are the servers you have permission to view Server Insights for. Click on any of them to view insightful analytics about your communities, like how engaged your members are and how fast you're growing.`,
  NO_GUILDS_PRIMARY: `You don't have any servers in here yet :(`,
  NO_GUILDS_SECONDARY: `Servers in which you have the "View Server Insights" permission will show up.`,
  SERVER_IS_NOT_READY_YET: `Your server isn't quite ready yet`,
  MY_SERVERS: 'My Servers',
};

const RequestAdditionalIntents = {
  ALERT_APPROVED_APP_REQUEST_INTENTS_HEADING: 'Request approval for Privileged Intents',
  ALERT_APPROVED_APP_REQUEST_INTENTS_CTA_TEXT:
    'Verified apps cannot use [Privileged Intents]({link}) without being approved. Apply for approval if you require Privileged Intents for new functionality.',
  ALERT_LIMITED_INTENTS_WARNING_HEADING: 'Your growth is being capped',
  ALERT_LIMITED_INTENTS_WARNING:
    'Your app has been verified, but you have Limited [Privileged Intents]({link}) enabled on your app. You will not be able to grow past 100 servers unless these are disabled or you apply for [Privileged Intents]({link}) and are approved',
  ALERT_LIMITED_INTENTS_DANGER_HEADING: 'Your app cannot be added to more guilds',
  ALERT_LIMITED_INTENTS_DANGER:
    'Your app has been verified, but you have Limited [Privileged Intents]({link}) enabled on your app. Your app is blocked from being added to more servers until these are disabled or you apply for [Privileged Intents]({link}) and are approved',
  ALERT_SUBMISSION_NOT_AVAILABLE:
    'This application is not currently in the minimum number of servers required to apply intent verification.',
  ALERT_REQUEST_INTENTS_CTA_BUTTON: 'Apply',
  CHECKLIST_TITLE: 'Intent Verification Qualifications',
  CHECKLIST_SUBTITLE_INCOMPLETE:
    "Your app's intents are missing {numberTodo} {numberTodo, plural, one {criterion} other {criteria}} and cannot be submitted for approval",
  CHECKLIST_SUBTITLE_COMPLETE: 'Intent Verification qualification complete',
  APPLICATION_DESCRIPTION_HEADING: 'Application Details',
  APPLICATION_DESCRIPTION_SUBHEADING:
    'What does your application do? Please be as detailed as possible, and feel free to include links to image or video examples.',
  HEADING: 'Request Intents',
  CURRENT_LIMITED_INTENTS: 'You have the following limited intents and may apply for their full intents: {list}.',
  DATA_COLLECTION_GUILD_MEMBERS_REASON_LABEL: 'Why do you need the Guild Members intent?',
  DATA_COLLECTION_GUILD_MEMBERS_STORED_LABEL:
    'Are you storing any potentially sensitive user information off-platform (outside of Discord)?',
  DATA_COLLECTION_GUILD_MEMBERS_AUTODELETE_LABEL:
    'Are you storing potentially sensitive user information, including usernames, nicknames, and avatars, for 30 days or less?',
  DATA_COLLECTION_GUILD_PRESENCES_REASON_LABEL: 'Why do you need the Guild Presences intent?',
  DATA_COLLECTION_GUILD_PRESENCES_CAN_OPT_OUT_LABEL: 'Can users opt-out of having their Presence data tracked?',
  DATA_COLLECTION_GUILD_PRESENCES_STORED_LABEL: 'Are you storing user activity data off-platform (outside of Discord)?',
  DATA_COLLECTION_GUILD_PRESENCES_AUTODELETE_LABEL: 'Are you storing user activity data for 30 days or less?',
  MESSAGE_CONTENT_AI_TRAINING_PT_1: 'Will the message content data be used to train machine learning or AI Models?',
  MESSAGE_CONTENT_AI_TRAINING_PT_2:
    'If yes, please explain how in the text box below, including how such models would be used.',
  DATA_COLLECTION_MESSAGE_CONTENT_REASON_LABEL: 'Why do you need the Message Content intent?',
  DATA_COLLECTION_MESSAGE_CONTENT_CAN_OPT_OUT_LABEL: 'Can users opt-out of having their message content data tracked?',
  DATA_COLLECTION_MESSAGE_CONTENT_STORED_LABEL:
    'Are you storing message content data off-platform (outside of Discord)?',
  DATA_COLLECTION_MESSAGE_CONTENT_AUTODELETE_LABEL: 'Are you storing user message content data for 30 days or less?',
  DATA_COLLECTION_USE_CASE_LABEL: `Please describe the feature you're building using this intent with as much detail as possible.`,
  DATA_COLLECTION_DEMONSTRATION_LABEL:
    'Please provide links to screenshots and/or videos that demonstrate your use case',
  DATA_COLLECTION_DEMONSTRATION_PLACEHOLDER:
    'For each selected intent, we require you to provide a link to screenshots or video that demonstrates your desired use case working within a Discord server.',
  DATA_COLLECTION_DELETION_LABEL: 'How do users contact you to request deletion of their activity data?',
  DATA_COLLECTION_DELETION_PLACEHOLDER:
    'Provide a bot command, server link, email address, or other contact information here.',
  DATA_COLLECTION_ENCRYPTION_LABEL:
    'Are you encrypting your stored potentially sensitive user information data at rest, as is required by our Developer Policy?',
  PRIVACY_POLICY_PUBLIC_LABEL: 'Do you have a public Privacy Policy telling your users about their data usage?',
  PRIVACY_POLICY_LOCATION_LABEL: 'Where is your Privacy Policy available?',
  PRIVACY_POLICY_LOCATION_PLACEHOLDER:
    'Some examples include a link within your bot bio, a help command, a rules channel, a DM sent to users upon guild join, or any other form of notification.',
  PRIVACY_POLICY_EXAMPLE_LABEL: 'Please share your Privacy Policy',
  PRIVACY_POLICY_EXAMPLE_PLACEHOLDER: 'Provide a link or screenshots of your Privacy Policy.',
  SUBMISSION_RECEIVED: 'Your submission was received.',
};

const Verification = {
  ALERT_REQUIRED_HEADING: 'Verification Required',
  ALERT_REQUIRED_BODY:
    'You must verify your identity and submit your application for approval before growing past 100 guilds. [Learn more]({url}).',
  ALERT_REQUIRED_CTA: 'Get Started',
  ALERT_SUBMITTED_HEADING: 'Verification Request Processing',
  ALERT_SUBMITTED_BODY: `We've received your request. We are reviewing your verification form and will reach out to you via email
  with more information.`,
  IMMUTABLE_FIELDS_NOTICE: `Once this form is submitted, you will no longer be able to change the bot's name or ownership (this includes transferring to and from developer teams). Please make any necessary changes before submitting this application.`,
  HEADING: 'Verification',
  SUBHEADING:
    'In order to scale your application past 100 servers, we require you to complete identity and application verification. [Learn more]({url}).',
  IDENTITY_HEADING: 'Identity Verification',
  IDENTITY_SUBHEADING: `We've partnered with Stripe to power our identity verification. Clicking the button below will open a popup to begin the process. You'll be asked to take a photo or upload a scanned copy of your identity document(s) like a driver's license, ID card, or passport.`,
  IDENTITY_VERIFY: 'Verify Me',
  IDENTITY_VERIFY_OWNER_ONLY_TOOLTIP: 'You must be the team owner',
  IDENTITY_VERIFIED_HEADING: 'Identity Verification Completed',
  IDENTITY_VERIFIED: 'Verified',
  IDENTITY_PROCESSING_HEADING: 'Processing Identity Verification',
  IDENTITY_PROCESSING: `Hang tight, we're still processing your submission.. This can take a few minutes.`,
  IDENTITY_REQUIRES_ACTION_HEADING: 'Identity Verification Needs Action',
  IDENTITY_REQUIRES_ACTION: `Looks like there were some issues while trying to verify your identity. Please try again.`,
  IDENTITY_GRACE_PERIOD_WARNING_HEADING: 'Identity Reverification Required',
  IDENTITY_GRACE_PERIOD_WARNING:
    "The identity associated with your account will expire soon and requires action. Please complete [Stripe's identity verification]({learnMoreUrl}) to maintain your apps' current verification statuses.",
  INSECURELY_STORING_MESSAGE_DATA_ERROR: `If you store message content off-platform, you must automatically delete message content after 30 days of storage, and encrypt messages at rest.`,
  APPLICATION_DESCRIPTION_HEADING: 'Application Details',
  APPLICATION_DESCRIPTION_SUBHEADING:
    'What does your application do? Please be as detailed as possible, and feel free to include links to image or video examples.',
  DATA_COLLECTION_HEADING: 'Data Collection',
  DATA_COLLECTION_SUBHEADING: 'Tell us more about the data you store and process from Discord.',
  DATA_COLLECTION_DATA_STORED_LABEL: 'What Discord data do you store?',
  DATA_COLLECTION_DATA_STORED_PLACEHOLDER: 'i.e User IDs, Server IDs, message content, etc.',
  DATA_COLLECTION_MESSAGES_STORED_LABEL: 'Do you store message content?',
  DATA_COLLECTION_MESSAGES_STORED_FOLLOWUP:
    'If you store message content off-platform, you must comply with the following asks:',
  DATA_COLLECTION_MESSAGES_STORED_AUTODELETE: 'Do you automatically delete message content after 30 days of storage?',
  DATA_COLLECTION_MESSAGES_STORED_ENCRYPTION: 'Do you encrypt message content at rest?',

  DATA_COLLECTION_DATA_PURPOSES_LABEL: 'For what purpose(s) do you store the information you collect?',
  DATA_COLLECTION_DATA_PURPOSES_PLACEHOLDER: 'Any features requiring data storage, i.e leaderboards, backups, etc.',
  DATA_COLLECTION_DATA_RETENTION_LABEL: 'For how long do you store it?',
  DATA_COLLECTION_DATA_RETENTION_PLACEHOLDER: `Please clarify how long you keep the information you're collecting.`,
  DATA_COLLECTION_DATA_DELETION_LABEL: 'What is the process for users to request deletion of their data?',
  DATA_COLLECTION_DATA_DELETION_PLACEHOLDER:
    'A deletion process for any collected Discord user or server data is required. Please describe yours.',
  INFRASTRUCTURE_HEADING: 'Infrastructure',
  INFRASTRUCTURE_SUBHEADING: `Tell us more about your application's infrastructure and your team's security practices.`,
  INFRASTRUCTURE_HOST_LABEL: 'How do you host your bot?',
  INFRASTRUCTURE_HOST_DESCRIPTION_PLACEHOLDER: `Please provide the URL of your VPS/VDS provider, if using one, or a description of your computer setup if you're hosting your bot at home.`,
  INFRASTRUCTURE_LIBRARY_LABEL: 'What language or library was your bot written with?',
  INFRASTRUCTURE_LIBRARY_DESCRIPTION_PLACEHOLDER: `Please describe how you wrote your bot.`,
  INFRASTRUCTURE_SECURITY_LABEL: 'How do you keep your bot secure?',
  INFRASTRUCTURE_SECURITY_PLACEHOLDER:
    'Please provide a description of your security protocol, including information on how you secure your host setup and code base, along with information on how you secure your Discord account.',
  INFRASTRUCTURE_VULNERABILITY_DISCLOSURE_LABEL:
    'How can users contact you with security concerns and other questions regarding your bot?',
  INFRASTRUCTURE_VULNERABILITY_DISCLOSURE_PLACEHOLDER:
    'Providing a means of contact for a verified bot is required, be it through a dedicated command, via Discord DMs or in a support server, or other means.',
  INTENTS_REQUESTED_INTENTS_LABEL:
    'Which intents are you applying for, if any? (Leave blank if you do not need any of these)',
  INTENTS_USE_CASE_LABEL:
    'For each Privileged Intent that you have selected, please explain your use case (why you need this data).',
  INTENTS_USE_CASE_PLACEHOLDER: `Please provide a detailed description of any features in your bot that require the privileged intents you're requesting.`,
  INTENTS_USE_CASE_SUPPLEMENTAL_MATERIAL_LABEL:
    'For each Privileged Intent that you have selected, please provide links to screenshots and/or videos that demonstrate your use case.',
  INTENTS_USE_CASE_SUPPLEMENTAL_MATERIAL_PLACEHOLDER: `Please note that we require a demonstration of your features working within a Discord server. Note that any bot in less than 75 servers can use intents without restriction, so you can feel free to test that command on a smaller version of your bot for demonstration purposes.`,
  SUBMIT: 'Submit',
  ERROR_CONSENT_DECLINED:
    'We are unable to verify your identity because you did not give Stripe permission to verify your identity.',
  ERROR_UNVERIFIED:
    'We are unable to verify your identity because one or more of the documents you provided are unverified. Please try again.',
  ERROR_DEVICE_UNSUPPORTED:
    'We are unable to verify your identity because the current device you are using is unsupported. Please try again on a supported device.',
  ERROR_VERIFICATION_DOCUMENT_EXPIRED:
    'We are unable to verify your identity because one or more of the documents you provided are expired. Please try again.',
  ERROR_VERIFICATION_DOCUMENT_INVALID:
    'We are unable to verify your identity because one or more of the documents you provided are invalid. Please try again.',
  ERROR_VERIFICATION_UNEXPECTED_DOCUMENT_COUNTRY:
    'We are unable to verify your identity because one or more of the documents you provided had an unexpected issuing country. Please try again.',
  ERROR_VERIFICATION_UNEXPECTED_DOCUMENT_TYPE:
    'We are unable to verify your identity because one or more of the documents you provided had an unexpected document type. Please try again.',
  ERROR_VERIFICATION_SCAN_NOT_READABLE:
    'We are unable to verify your identity because one or more of the documents you provided were not readable. It may have been too blurry or not well lit. Please try again.',
  ERROR_VERIFICATION_SCAN_MISSING_BACK:
    'We are unable to verify your identity because the back of one or more of the documents you provided were missing. Please try again.',
  ERROR_VERIFICATION_SCAN_ID_TYPE_NOT_SUPPORTED:
    'We are unable to verify your identity because one or more of the documents you provided are not supported. Please try again.',
  ERROR_VERIFICATION_SCAN_CORRUPT:
    'We are unable to verify your identity because one or more of the documents you provided are invalid/corrupted. Please try again.',
  ERROR_VERIFICATION_SCAN_FAILED_COPY:
    'We are unable to verify your identity because one or more of the documents you provided are a copy of an original document. Please try again with the original document.',
  ERROR_VERIFICATION_SCAN_MANIPULATED_DOCUMENT:
    'We are unable to verify your identity because one or more of the documents you provided have been altered or damaged. Please try again with the original document.',
  ERROR_VERIFICATION_SCAN_FAILED_GRAYSCALE:
    'We are unable to verify your identity because one or more of the documents you provided was uploaded in grayscale. Please try again with the original document.',
  ERROR_VERIFICATION_UNDER_SUPPORTED_AGE:
    'We are unable to verify your identity because you are under the minimum supported age for your country. Please try again with help from a parent, legal guardian, or other trusted sponsor.',
};

const EmbeddedApplication = {
  EMBEDDED_APPLICATION_SETTINGS_HEADING: 'Activity Settings',
  EMBEDDED_APPLICATION_SETTINGS_SUBHEADING: 'A place for you to configure your activity settings',
  EMBEDDED_APPLICATION_URL_MAPPINGS_HEADING: 'Activity URL Mappings',
  EMBEDDED_APPLICATION_URL_MAPPINGS_SUBHEADING: 'A place for you to configure your activity URL mappings',
  EMBEDDED_APPLICATION_ASSETS_HEADING: 'Activity Assets',
  EMBEDDED_APPLICATION_ASSETS_SUBHEADING:
    'Preview and update your assets here! Please note that updates may be delayed due to caching, so be patient.',
  EMBEDDED_APPLICATION_ASSETS_ERROR: 'An error occured while loading your assets. Please try again.',
  EMBEDDED_BACKGROUND_HEADING: 'Background',
  EMBEDDED_BACKGROUND_SUBHEADING:
    'Used as a background overlay in Grid view. Artwork should be clustered around the edges of the image leaving space in the center of the image so the UI does not clash with it.',
  EMBEDDED_BACKGROUND_SPECIFICATIONS: 'Specifications: 16:9 Ratio and at least 1024 pixels wide.',
  COVER_ART_HEADING: 'Cover Art',
  COVER_ART_SUBHEADING:
    'Used as the main image on the Activity Shelf. It is suggested that this image contain the title and some art in the background.',
  COVER_ART_SPECIFICATIONS: 'Specifications: 16:9 Ratio and at least 1024 pixels wide.',
  VIDEO_PREVIEW_HEADING: 'Video Preview',
  VIDEO_PREVIEW_SUBHEADING:
    'Shown when hovering on an activity in the menu. Also shown on the upsell when you click on an activity in the menu. Suggested to show a brief preview of the activity. Can be a screen recording of the activity being played.',
  VIDEO_PREVIEW_SPECIFICATIONS:
    'Specifications: 10 seconds or less MP4 file with dimensions 640x360 pixels under 500KB.',
  URL_MAPPINGS: 'URL Mappings',
  URL_MAPPING_DOCUMENTATION_HERE: 'For more information on URL Mapping, check out the documentation [here]({url}).',
  ROOT_MAPPING: 'Root Mapping',
  ROOT_MAPPING_REQUIRED: 'Error: Root URL Mapping Target is required!',
  PREFIX: 'Prefix',
  TARGET: 'Target',
  ADD_ANOTHER: 'Add Another',
  PREVIEW_VIDEO_ASSET_ID: 'Preview Video Asset ID',
  PHONE_DEFAULT_ORIENTATION_LOCK_STATE: 'Phone Default Orientation Lock State',
  TABLET_DEFAULT_ORIENTATION_LOCK_STATE: 'Tablet Default Orientation Lock State',
  PHONE_DEFAULT_ORIENTATION_LOCK_STATE_NOTES:
    'This setting is only consumed in the Discord mobile apps on phones. Desktop / Web Browsers always have Landscape 16 / 9 aspect ratio',
  TABLET_DEFAULT_ORIENTATION_LOCK_STATE_NOTES: 'This setting is only consumed in the Discord mobile apps on tablets.',
  ORIENTATION_LOCK_STATE_UNLOCKED: 'Unlocked',
  ORIENTATION_LOCK_STATE_PORTRAIT: 'Portrait',
  ORIENTATION_LOCK_STATE_LANDSCAPE: 'Landscape',
  AGE_GATE: 'Age Gate',
  AGE_GATED: 'Age Gated',
  AGE_GATE_NOTES: 'Applications with content unsuitable for children under the age of 18 should be marked as Age Gated',
  RESPONSIVE_ASPECT_RATIO_TOGGLE: 'Use responsive aspect ratio',
  RESPONSIVE_ASPECT_RATIO_TOGGLE_NOTES:
    'On December 1, 2024, this setting will be retired, and all activities will default to a dynamic aspect ratio.',
  SUPPORTED_PLATFORMS: 'Supported Platforms',
  SUPPORTED_PLATFORM_WEB: 'Web',
  SUPPORTED_PLATFORM_IOS: 'iOS',
  SUPPORTED_PLATFORM_ANDROID: 'Android',
  PREVIEW: 'PREVIEW',
  JOIN_ACTIVITY: 'Join Activity',
  PARTICIPANTS_LABEL: 'Up to {count} participants',
  UNLIMITED_PARTICIPANTS: 'Unlimited participants',
  VIDEO: 'Video',
  GETTING_STARTED_TITLE: 'Get Started with Activities',
  GETTING_STARTED_SUBTITLE: 'Build rich experiences directly inside Discord.  [Learn more]({url})',
  GETTING_STARTED_BULLET_ONE_TITLE: 'Get started quickly',
  GETTING_STARTED_BULLET_ONE_BODY:
    'Download the SDK, follow our [Getting Started]({url}) guide, and say “Hello, World!”',
  GETTING_STARTED_BULLET_TWO_TITLE: 'Build and test together',
  GETTING_STARTED_BULLET_TWO_BODY:
    'Invite teammates and private testers to build with you. Work together to make something great!',
  GETTING_STARTED_BULLET_THREE_TITLE: 'Distribute and earn',
  GETTING_STARTED_BULLET_THREE_BODY:
    'Make your app discoverable, easily monetize with integrated payments, and distribute on Discord.',
  GETTING_STARTED_WARNING: 'Activities are currently in developer preview. [Learn more]({url}) about what’s available.',
  VERIFIED_APPS_CANNOT_ENABLE_ACTIVITIES: 'Verified apps cannot enable activities at this time.',
  GETTING_STARTED_COMING_SOON_TAG: 'Coming Soon',
  GETTING_STARTED_ACKNOWLEDGE_BUTTON: 'Enable',
  UNEMBED_HEADING: 'Disable Activities',
  UNEMBED_SUBHEADING: `Changed your mind? Remove activity functionality from your app.`,
  UNEMBED_BUTTON: 'Remove',
  PUBLISH_TITLE: 'Ready to launch?',
  PUBLISH_SUBTITLE: `Submit your Activity for early access to discovery and distribution`,
  PUBLISH_BULLET_ONE_TITLE: 'Go Live',
  PUBLISH_BULLET_ONE_BODY: 'Be among the first to give friends fun new ways to spend time together on Discord.',
  PUBLISH_BULLET_TWO_TITLE: 'Get Discovered',
  PUBLISH_BULLET_TWO_BODY: 'Get your Activity in front of millions of users where they already hang out.',
  PUBLISH_BULLET_THREE_TITLE: 'Start Earning',
  PUBLISH_BULLET_THREE_BODY: `Take advantage of Discord's integrated payment systems that users know, trust, and already use.`,
  PUBLISH_SUBMIT_BUTTON: 'Request Early Access',
};

const EmbeddedApplicationEnableModal = {
  TITLE: 'Enable Activities?',
  MODAL_CONTENT_ONE:
    'Enabling Activities will automatically create an Entry Point command for your application if one does not already exist.',
  MODAL_CONTENT_TWO: `An Entry Point command serves as the primary way for users to open an app's Activity from the App Launcher and Voice Channels.`,
  MODAL_CONTENT_THREE: 'Read more about [Entry Points in our documentation]({url}).',
  CONFIRM_BUTTON: 'Confirm',
};

const DeveloperExperienceSurvey = {
  DEVELOPER_EXPERIENCE_SURVEY_HEADING: 'Survey',
  DEVELOPER_EXPERIENCE_SURVEY_PITCH_VOTE:
    'Play and vote for your favorite Discord App Pitches in the Town Hall server through through July 11!',
  DEVELOPER_EXPERIENCE_SURVEY_PITCH_VOTE_CTA: 'Join Now',
  DEVELOPER_EXPERIENCE_CSAT_SURVEY_PITCH: 'How satisfied are you with developing on Discord?',
  DEVELOPER_EXPERIENCE_VERY_UNSATISFIED: 'Very unsatisfied',
  DEVELOPER_EXPERIENCE_UNSATISFIED: 'Unsatisfied',
  DEVELOPER_EXPERIENCE_NEUTRAL: 'Neutral',
  DEVELOPER_EXPERIENCE_SATISFIED: 'Satisfied',
  DEVELOPER_EXPERIENCE_VERY_SATISFIED: 'Very satisfied',
  DEVELOPER_EXPERIENCE_VERY_UNSATISFIED_OPTION: '1',
  DEVELOPER_EXPERIENCE_UNSATISFIED_OPTION: '2',
  DEVELOPER_EXPERIENCE_NEUTRAL_OPTION: '3',
  DEVELOPER_EXPERIENCE_SATISFIED_OPTION: '4',
  DEVELOPER_EXPERIENCE_VERY_SATISFIED_OPTION: '5',
  DISMISS: 'Dismiss',
};

const WinterSnowflakes = {
  CONTROL_DECORATIONS: 'Control Decorations',
  CONTROLS: 'Snowflake Controls',
  COUNT: 'Snowflake Count',
  SHOW: 'Show Snowflakes',
  SHOW_USER_ID: 'Show User ID Snowflake',
};

const Embeds = {
  TITLE: 'Embed Debugger',
  SUBTITLE: 'Test and debug link embeds for your website',
  DESCRIPTION:
    'Discord supports [oEmbed]({oEmbedLink}), [Open Graph]({opengraphLink}), and [Twitter Card]({twitterTagsLink}) metadata formats for rendering link embeds.',
  LABEL: 'Website URL',
  SUBMIT: 'Generate Embed',
  ERROR: 'There was an error. Please try again later',
};

const Onboarding = {
  BLOCKED_TITLE: 'Your app is not eligible for monetization',
  BLOCKED_BODY: 'Please reach out to [developer support]({link}) to understand more',
  EDUCATION_TITLE: 'Monetize Your App',
  EDUCATION_SUBTITLE: 'Earn money with integrated app subscriptions. $[Learn more](link)',
  EDUCATION_BULLET_ONE_TITLE: 'Fulfill eligibility requirements ',
  EDUCATION_BULLET_ONE_BODY:
    'Run through our quick checklist, and make sure you’re ready to start monetizing your app.',
  EDUCATION_BULLET_TWO_TITLE: 'Set up and integrate ',
  EDUCATION_BULLET_TWO_BODY:
    'Create your subscription, customize your offering, integrate the API, and get ready to launch.',
  EDUCATION_BULLET_THREE_TITLE: 'Start earning',
  EDUCATION_BULLET_THREE_BODY:
    'Go live and get paid! To learn more about our payouts process visit our $[help center.](link)',
  EDUCATION_BUTTON: 'Get Started',
  ERROR_FETCHING_APPLICATION: 'There was an error fetching this application. Please try again later',
  PROGRESS_GETTING_STARTED: 'Getting Started',
  PROGRESS_ELIGIBILITY_REQUIREMENTS: 'Eligibility Requirements',
  PROGRESS_SET_UP_SKUS: 'Set Up SKUs',
  TITLE: 'App Monetization',
  SUBTITLE: 'Offer premium experiences to people who love your app, and start earning!',
  ELIGIBLE: "You're eligible for monetization!",
  INELIGIBLE: 'Complete these steps to enable monetization!',
  REQUIREMENT_VERIFIED: 'Your app must be verified',
  REQUIREMENT_TEAM: 'Your app must belong to a $[Developer Team](link)',
  REQUIREMENT_TERMS_OF_SERVICE: 'Your app must have a link to $[Terms of Service](link)',
  REQUIREMENT_PRIVACY_POLICY: 'Your app must have a link to your $[Privacy Policy](link)',
  REQUIREMENT_SAFE_NAMES:
    'Your app must not contain any harmful or bad language in its name, description, commands, or role connection metadata',
  REQUIREMENT_QUARANTINE: 'Your app must not be quarantined',
  REQUIREMENT_VALID_PAYOUT_LOCALE:
    'You must have payouts set up with a valid payment method in an [eligible country]({link})',
  REQUIREMENT_VALID_PAYOUT_STATUS: 'Your payout account must be active',
  REQUIREMENT_USER_AGE_SUPPORTED: 'The team owner must be at least 18 years old',
  REQUIREMENT_EMAIL_2FA: 'All members of your developer team must have a verified email and 2FA set up',
  ELIGIBLE_DETAILS:
    'To get started please read and agree to our [Monetization Terms]({monetizationTermsUrl}) & [Discord Premium Apps Policy]({premiumAppsPolicyUrl}).',
  AGREE: 'Agree',
  ACTION_SET_UP_PAYOUTS: 'Set up Payouts',
  ACTION_SET_UP_2FA: 'Set up 2FA',
  ACTION_SET_UP_DATE_OF_BIRTH: 'Verify Date of Birth',
  MUST_BE_TEAM_OWNER_WARNING: 'You must be the team owner to move forward with monetization onboarding',
  APP_HAS_SKUS:
    'To manage existing SKUs, you must re-enable monetization for your app by completing the monetization onboarding below. If you have any trouble or questions, you can reach out to our [developer support team]({developerSupportUrl}).',
  EMBEDDED_APPS_WARNING: 'Apps which have opted into being EMBEDDED cannot set up monetization at this time',
};

const VerificationOnboarding = {
  HEADING: 'Verify your App',
  SUBHEADING:
    'In order to scale your application past 100 servers, we require your team owner to complete identity and application verification. [Learn more]({url}).',
  CHECKLIST_TITLE: 'Verification Qualifications',
  CHECKLIST_SUBTITLE_INCOMPLETE:
    'Your app is missing {numberTodo} {numberTodo, plural, one {criterion} other {criteria}} and cannot be verified',
  APP_INTENTS_TITLE: 'App Intents',
  CHECKLIST_SUBTITLE_COMPLETE: 'Verification qualifications complete',
  APP_INTENTS_INCOMPLETE:
    "You have {nUnverifiedIntents} unapproved {nUnverifiedIntents, plural, one {intent} other {intents}} and cannot verify your app until {nUnverifiedIntents, plural, one {it is} other {they are}} either approved or removed.\n\nIf you don't absolutely need these intents, we recommend removing them from your app.",
  APP_INTENTS_COMPLETE: 'All of your requested intents have been approved',
  UNAPPROVED_INTENTS_WARNING:
    'Your app is temporarily approved for Intents. You may continue to use Privileged Intents until your app joins 100 servers. At that point, you must either apply for full access or turn them off $[here](here), or your app will not be able to join more servers. You may apply for full access when your app reaches 75 servers.',
  UNAPPROVED_INTENTS_ERROR:
    'Your app is temporarily approved for Intents and has been limited to joining 100 servers. To enable joining additional servers you must either apply for full access or turn them off $[here](here), or your app will not be able to join more servers.',
  REQUIREMENT_HAS_TEAM: 'Your app must belong to a Team',
  REQUIREMENT_HAS_SAFE_NAMES:
    'Your app must not contain any harmful or bad language in its name, description, commands, or role connection metadata',
  REQUIREMENT_TERMS_OF_SERVICE: 'Your app must have a link to Terms of Service',
  REQUIREMENT_PRIVACY_POLICY: 'Your app must have a link to your Privacy Policy',
  REQUIREMENT_INSTALL_LINK: 'Your app must have an install link',
  REQUIREMENT_NOT_EMBEDDED: 'Your app must not enable "Activities"',
  REQUIREMENT_APPROVED_PRESENCE_INTENT: 'Presence Intent must be approved',
  REQUIREMENT_APPROVED_SERVER_MEMBERS_INTENT: 'Server Members Intent must be approved',
  REQUIREMENT_APPROVED_MESSAGE_CONTENT_INTENT: 'Message Content Intent must be approved',
  REQUIREMENT_TEAM_OWNER_VERIFIED: 'Team owner must complete Identity Verification',
  REQUIREMENT_EMAIL_2FA: 'All members of your developer team must have a verified email and 2FA set up',
  ALERT_CANNOT_SUBMIT_FOR_VERIFICATION:
    'Your app cannot request approval for intent verification until it meets the Verification Qualifications listed above.',
  APP_IDENTITY_HEADER: 'App Identity',
  CAUTION: 'Caution',
  CAUTION_VERIFICATION_WARNING:
    "After verification, you cannot modify the app's name or transfer ownership without the assistance of Discord's support team.",
  APP_NAME: 'APP NAME',
  EDIT_NAME: 'Edit Name',
  OWNERSHIP: 'OWNERSHIP',
  CHANGE_OWNER: 'Change Owner',
  AFFIRM_TOS_AND_DEVELOPER_POLICY:
    'I affirm that my application abides by the Discord [Developer Terms of Service]({tosUrl}) and [Developer Policy]({devPolicyUrl})',
  AFFIRM_LOCKED_OWNER_AND_NAME:
    "I am the owner of this app. I recognize that after verification, I cannot modify the app's name or transfer ownership without the assistance of Discord’s support team",
  VERIFY_APP: 'Verify App',
  WARNING_MUST_BE_TEAM_OWNER: 'You must be the team owner to verify this app',
  BLOCKED_TITLE: 'Your app is not eligible for verification',
  BLOCKED_BODY: 'Please reach out to [developer support]({link}) to understand more',
  ERROR_UNKNOWN: 'Unknown Verification Error',
  CONFIRM_VERIFICATION: 'Confirm Verification',
  CONFIRM_VERIFICATION_DETAILS:
    'Some app settings cannot be changed without approval after getting Verified. Make sure your app works as expected with your current settings before continuing.',
  CONFIRM_VERIFICATION_CANNOT_BE_UNDONE: 'This action cannot be undone.',
};

const Mfa = {
  MFA_PICKER_HEADER: 'Multi-Factor Authentication',
  MFA_V2_BACKUP_NAME: 'Use a backup code',
  MFA_V2_PASSWORD_NAME: 'Use your password',
  MFA_V2_SMS_NAME: 'Use a code sent to your phone',
  MFA_V2_TOTP_NAME: 'Use your authenticator app',
  MFA_V2_WEBAUTHN_CTA: 'Authenticate with security key',
  MFA_V2_WEBAUTHN_GENERIC_ERROR: 'An error occurred. Please try again.',
  MFA_V2_WEBAUTHN_NAME: 'Use a security key',
  MFA_VERIFY_WITH_SOMETHING_ELSE: 'Verify with something else',
  TWO_FA_ENTER_SMS_TOKEN_SENDING: 'Sending...',
  TWO_FA_ENTER_SMS_TOKEN_SENT: `We've sent a message to !!{phoneNumber}!!.  Please enter the code you received.`,
};

const XSSDefenses = {
  SELF_XSS_HEADER: 'Hold Up!',
  SELF_XSS_LINE_1: `If someone told you to copy/paste something here you have an 11/10 chance you're being scammed.`,
  SELF_XSS_LINE_2: 'Pasting anything in here could give attackers access to your Discord account.',
  SELF_XSS_LINE_3: 'Unless you understand exactly what you are doing, close this window and stay safe.',
  SELF_XSS_LINE_4: 'If you do understand exactly what you are doing, you should come work with us {url}',
};

const UpdatedTermsOfServiceJune2024 = {
  HEADING: 'Important',
  BODY: 'We’re updating our Developer Terms of Service and Developer Policy, effective July 8, 2024. Please review.',
  CTA: 'See Terms',
  DISMISS: 'Dismiss',
};

module.exports = {
  Common,
  AppNavigation,
  ErrorPage,
  Forms,
  DeveloperPages,
  AvatarUploader,
  RequestAdditionalIntents,
  Verification,
  VerificationOnboarding,
  Permissions,
  Developers,
  Whitelist,
  DiscoverySettings,
  DiscoveryStatus,
  Bot,
  Emojis,
  RichPresence,
  Information,
  Installation,
  OAuth2,
  MyApps,
  Navigation,
  Actions,
  Alerts,
  PresenceViewer,
  Tooltips,
  UserProfile,
  ActiveDeveloperProgram,
  ActiveDeveloperProgramCta,
  Locale,
  SkuManagement,
  SkuTypes,
  SkuAccessTypes,
  AssetLibrary,
  SkuRatings,
  ContentDescriptors,
  SkuRequirements,
  SkuPrice,
  BundledSkus,
  PromotionalAssets,
  AssetManager,
  Features,
  Locales,
  Teams,
  Checklist,
  ApplicationChecklistItems,
  RichPresenceChecklistItems,
  GameSKUChecklistItems,
  BuildsChecklistItems,
  StorePageChecklistItems,
  Analytics,
  PromotionalAssetsChecklistItems,
  Metadata,
  MultiTextInput,
  PizzaTracker,
  Errors,
  PayoutSettings,
  TeamChecklistItems,
  GuildChecklistItems,
  StoreApprovalModal,
  Address,
  StoreSignupModal,
  ChildSKUs,
  SocialMedia,
  PayoutHistory,
  Guilds,
  EmbeddedApplication,
  EmbeddedApplicationEnableModal,
  EmbeddedApplicationSkuInformation,
  EmbeddedSkuTypes,
  DeveloperExperienceSurvey,
  WinterSnowflakes,
  Webhooks,
  Events,
  EventGroups,
  Embeds,
  Onboarding,
  Mfa,
  Storefront,
  XSSDefenses,
  UpdatedTermsOfServiceJune2024,
};
