import {ChannelTypes} from '@discordapp/common/shared-constants/ChannelTypes';
import {OAuth2Scopes} from '@discordapp/common/shared-constants/OAuth2Scopes';
import {SKUTypes} from '@discordapp/common/shared-constants/SKUTypes';
import {wrapPaths} from '@discordapp/common/utils/PathUtils';

import APIDocsEndpoints from './data/api-docs-endpoints';

import {
  PRIMARY_DOMAIN,
  Links as SharedLinks,
  FEEDBACK_DOMAIN,
  EMAIL_DOMAIN,
  SUPPORT_DOMAIN,
  SUPPORT_DEV_DOMAIN,
} from '@discordapp/common/Constants';
import {Messages} from './i18n';
import type {CompanyId, PriceTier, SkuId, TeamId, ApplicationId} from './flow/Server';

export {ChannelTypes, APIDocsEndpoints, OAuth2Scopes};

export const DEFAULT_AVATAR_SIZE = 128;
export const AVATAR_UPLOAD_SIZE = 1024;
export const COVER_IMAGE_UPLOAD_SIZE = 1024;
export const MINIMUM_COVER_IMAGE_SIZE = 512;
export const MINIMUM_ASSET_IMAGE_SIZE = 512;
export const RECOMMENDED_ASSET_IMAGE_SIZE = 1024;
export const RECOMMENDED_ASSET_ASPECT_RATIO = 16 / 9;
export const BANNER_IMAGE_UPLOAD_SIZE_WIDTH = 680;
export const BANNER_IMAGE_UPLOAD_SIZE_HEIGHT = 240;

export const MAXIMUM_APP_DESCRIPTION_LENGTH = 400;
export const MAXIMUM_APP_NAME_LENGTH = 128;
export const MAXIMUM_REDIRECT_URI_COUNT = 30;
export const MAXIMUM_REDIRECT_URI_LENGTH = 170;
export const MAXIMUM_CUSTOM_INSTALL_URI_LENGTH = 512;
export const MAXIMUM_BOT_USERNAME_LENGTH = 32;
export const MAXIMUM_SKU_LEGAL_NOTICE_LENGTH = 1024;
export const MAXIMUM_ALLOWED_TEAMS = 30;
export const MAXIMUM_ALLOWED_COMPANIES = 20;
export const MAXIMUM_APP_TAGS_LENGTH = 5;

export const MAX_EMOJI_UPLOAD_SIZE = 2 * 1024 * 1024;
export const MAX_EMOJI_FILESIZE = 256 * 1024;
export const EMOJI_MAX_SIZE = 128;
export const MAXIMUM_APP_EMOJI_COUNT = 2000;
export const MINIMUM_APP_EMOJI_LENGTH = 2;
export const MAXIMUM_APP_EMOJI_LENGTH = 32;

export const MINIMUM_ANALYTICS_GUILD_SIZE = 500;

export const APP_URL_PREFIX = location.protocol + window.GLOBAL_ENV.WEBAPP_ENDPOINT;

export const SENTRY_DSN = 'https://6856f40b84a04390aeaee79e883afdce@sentry.io/1245041';

export const DEVELOPER_SURVEY_VISIBILITY_KEY = 'showDeveloperSurvey_20240701';
export const UPDATED_TERMS_OF_SERVICE_VISIBILITY_KEY = 'showUpdatedTermsOfService_20240606';
export const SIDEBAR_SURVEY_CONTENT_PROMPT_VISIBILITY_KEY = 'showSidebarSurveyContentPrompt';
export const WEBHOOK_SETTINGS_NEW_BADGE_KEY = 'showWebhookSettingsNewBadge';
export const INSTALLATION_SETTINGS_NEW_BADGE_KEY = 'showInstallationSettingsNewBadge';
export const EMOJI_SETTINGS_NEW_BADGE_KEY = 'showEmojiSettingsNewBadge';

export const DEFAULT_LOCALE_KEY = 'default';

export const PREMIUM_ANALYTICS_DAY_THRESHOLD = 120;
export const DAILY_DEFAULT_FILTER_GUILD_SIZE = 40000;

// Should match sizes in 'discord_media_proxy/server/storage.go'
export const MEDIA_PROXY_SIZES = [
  16, 20, 32, 40, 64, 80, 128, 160, 256, 320, 512, 640, 1024, 1280, 1536, 2048, 3072, 4096,
];

export const Experiments = {
  STAFF_GUILD_INSIGHTS_BYPASS: '2020-08_staff_guild_insights_bypass', // allows some people to view analytics for all community guilds that they are a member of
  APPLICATION_EVENT_WEBHOOKS: '2024-03_application_event_webhooks',
  APPLICATION_EVENT_WEBHOOKS_V2: '2024-07_application_event_webhooks_v2',
  PROJECT_TETRIS_STAFF_OVERRIDE: '2023-06_project_tetris_staff_override',
  PROJECT_TETRIS: '2023-06_project_tetris',
  USER_APPS: '2023-08_user_apps',
  DEVELOPER_SURVEY_NAGBAR: '2024-03_developer_nagbar_app_pitches',
  APPLICATION_OWNED_EMOJI: '2024-07_application_owned_emoji',
  VERIFIED_ACTIVITIES: '2024-08_verified_activities',
};

export const AnalyticsEvents = {
  CTA_VIEWED: 'dev_portal_cta_viewed',
  CTA_CLICKED: 'dev_portal_cta_clicked',
  PAGE_VIEWED: 'dev_portal_page_viewed',
  AUTH_URL_COPIED: 'dev_portal_auth_url_copied',
  RICH_PRESENCE_VISUALIZER_USED: 'dev_portal_rp_viz_used',
  GUILD_ANALYTICS_DATA_FETCH_FAILED: 'guild_insights_data_fetch_failed',
  GUILD_ANALYTICS_GUILD_GRID_VIEWED: 'guild_insights_guild_grid_viewed',
  OUTBOUND_LINK_CLICKED: 'dev_portal_outbound_link_clicked',
  SURVEY_NOTICE_VIEWED: 'dev_portal_survey_notice_viewed',
  SURVEY_NOTICE_CLICKED: 'dev_portal_survey_notice_clicked',
  GUILD_INSIGHTS_CSV_EXPORT_LINK_CLICKED: 'guild_insights_csv_export_link_clicked',
  STORE_LISTING_REMOVED: 'store_listing_removed',
  STORE_LISTING_PUBLISHED: 'store_listing_published',
  STORE_LISTING_UNPUBLISHED: 'store_listing_unpublished',
  CHANGE_MARKETING_LOCALE: 'change_marketing_locale',
  ACTIVITY_URL_MAPPINGS_UPDATED: 'activity_url_mappings_updated',
  APPLICATION_EMBEDDED_CHANGED: 'application_embedded_changed',
};

export const ExperimentTypes = {
  GUILD: 'guild',
  USER: 'user',
  DEVELOPER: 'developer',
  NONE: 'none',
} as const;

export const ExperimentContextTypes = {
  GUILD: 'guild',
  CHANNEL: 'channel',
};

export enum EmbedTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  LINK = 'link',
  ARTICLE = 'article',
  TWEET = 'tweet',
  RICH = 'rich',
  GIFV = 'gifv',
}

export enum ExperimentBuckets {
  NOT_ELIGIBLE = -1,
  CONTROL = 0,
  TREATMENT_1 = 1,
  TREATMENT_2 = 2,
  TREATMENT_3 = 3,
  TREATMENT_4 = 4,
  TREATMENT_5 = 5,
  TREATMENT_6 = 6,
  TREATMENT_7 = 7,
  TREATMENT_8 = 8,
  TREATMENT_9 = 9,
  TREATMENT_10 = 10,
  TREATMENT_11 = 11,
  TREATMENT_12 = 12,
  TREATMENT_13 = 13,
  TREATMENT_14 = 14,
  TREATMENT_15 = 15,
  TREATMENT_16 = 16,
  TREATMENT_17 = 17,
  TREATMENT_18 = 18,
  TREATMENT_19 = 19,
  TREATMENT_20 = 20,
  TREATMENT_21 = 21,
  TREATMENT_22 = 22,
  TREATMENT_23 = 23,
  TREATMENT_24 = 24,
  TREATMENT_25 = 25,
}

export const API_DOCS_PATH_PREFIX = '/docs/';
const API_DOCS_ROUTE_PREFIX = 'DOCS_';

export type APIDocsEndpoint = (typeof APIDocsEndpoints)[number];
type TAPIDocsRoutes = {
  [Key in APIDocsEndpoint['key'] as `${typeof API_DOCS_ROUTE_PREFIX}${Key}`]: `${typeof API_DOCS_PATH_PREFIX}${string}`;
} & {
  DOCS_TOPICS_OAUTH2_BOTS: string;
};

export const APIDocsRoutes = APIDocsEndpoints.reduce((acc, {key, subPath}) => {
  acc[`${API_DOCS_ROUTE_PREFIX}${key}`] = `${API_DOCS_PATH_PREFIX}${subPath}`;
  // Append bots fragment URL.
  if (key === 'TOPICS_OAUTH2') {
    acc[`${API_DOCS_ROUTE_PREFIX}${key}_BOTS`] = `${API_DOCS_PATH_PREFIX}${subPath}#bots`;
  }
  return acc;
  /* eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter */
}, {} as TAPIDocsRoutes);

export const ROUTER_BASENAME = 'developers';

const UnsafeRoutes = Object.freeze({
  INDEX: '/',
  DEVELOPERS_DOCS: '/docs',
  // APPS
  APPLICATIONS: '/applications',
  ACTIVE_DEVELOPER_PROGRAM: '/active-developer',
  EMBED_DEBUGGER: '/embeds',
  EDIT_APPLICATION: <ID extends string>(id: ID) => `/applications/${id}` as const,
  APPLICATION_EMOJIS: <ID extends string>(id: ID) => `/applications/${id}/emojis` as const,
  APPLICATION_INFORMATION: <ID extends string>(id: ID) => `/applications/${id}/information` as const,
  APPLICATION_REQUEST_INTENTS: <ID extends string>(id: ID) => `/applications/${id}/request-additional-intents` as const,
  /** Deprecated - reroutes to /verification-onboarding */
  APPLICATION_VERIFICATION: <ID extends string>(id: ID) => `/applications/${id}/verification` as const,
  BOT: <ID extends string>(id: ID) => `/applications/${id}/bot` as const,
  EMBEDDED_APPLICATION: <ID extends string>(id: ID) => `/applications/${id}/embedded` as const,
  EMBEDDED_APPLICATION_GETTING_STARTED: <ID extends string>(id: ID) =>
    `/applications/${id}/embedded/getting-started` as const,
  EMBEDDED_APPLICATION_SETTINGS: <ID extends string>(id: ID) => `/applications/${id}/embedded/settings` as const,
  EMBEDDED_APPLICATION_URL_MAPPINGS: <ID extends string>(id: ID) =>
    `/applications/${id}/embedded/url-mappings` as const,
  EMBEDDED_APPLICATION_ASSETS: <ID extends string>(id: ID) => `/applications/${id}/embedded/assets` as const,
  EMBEDDED_APPLICATION_PUBLISH: <ID extends string>(id: ID) => `/applications/${id}/embedded/publish` as const,
  INSTALLATION: <ID extends string>(id: ID) => `/applications/${id}/installation` as const,
  OAUTH2: <ID extends string>(id: ID) => `/applications/${id}/oauth2` as const,
  /** Route never used, instead we forward to discovery/settings */
  DISCOVERY_ROOT: <ID extends string>(id: ID) => `/applications/${id}/discovery` as const,
  DISCOVERY_SETTINGS: <ID extends string>(id: ID) => `/applications/${id}/discovery/settings` as const,
  DISCOVERY_STATUS: <ID extends string>(id: ID) => `/applications/${id}/discovery/status` as const,
  APP_DISCOVERY: <ID extends string>(id: ID) => `/applications/${id}/app-directory/discovery` as const,
  RICH_PRESENCE: <ID extends string>(id: ID) => `/applications/${id}/rich-presence` as const,
  RICH_PRESENCE_ASSETS: <ID extends string>(id: ID) => `/applications/${id}/rich-presence/assets` as const,
  RICH_PRESENCE_VISUALIZER: <ID extends string>(id: ID) => `/applications/${id}/rich-presence/visualizer` as const,
  WEBHOOKS: <ID extends string>(id: ID) => `/applications/${id}/webhooks` as const,
  APP_WHITELIST: <ID extends string>(id: ID) => `/applications/${id}/testers` as const,
  VERIFICATION_ONBOARDING: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/verification-onboarding` as const,
  GIFT_CODES: <ID extends string>(id: ID) => `/applications/${id}/gift-codes` as const,
  CHECKLIST: <ID extends string>(id: ID) => `/applications/${id}/checklist` as const,
  ASSET_LIBRARY: <ID extends string>(id: ID) => `/applications/${id}/asset-library` as const,
  METADATA: <ID extends string>(id: ID) => `/applications/${id}/metadata` as const,
  SKUS_INDEX: <ID extends string>(id: ID) => `/applications/${id}/skus` as const,
  STORE_INDEX: <ID extends string>(id: ID) => `/applications/${id}/storefront/` as const,
  SKU_FEATURES: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/features` as const,
  SKU_INDEX: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}` as const,
  SKU_INFORMATION: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/information` as const,
  SKU_PRICE: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/price` as const,
  SKU_RATINGS: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/ratings` as const,
  SKU_SYSTEM_REQUIREMENTS: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/system-requirements` as const,
  SKU_BUNDLED_SKUS: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/bundled-skus` as const,
  SKU_GENRES: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/genres` as const,
  SKU_MANIFEST_LABELS: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/manifest-labels` as const,
  SKU_LOCALES: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/locales` as const,
  STORE_LISTING_CAROUSEL: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/carousel` as const,
  STORE_LISTING_DESCRIPTION: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/description` as const,
  STORE_LISTING_CHILD_SKUS: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/child-skus` as const,
  MONETIZATION_ANALYTICS: <ID extends string>(id: ID) => `/applications/${id}/monetization/analytics` as const,
  ACHIEVEMENTS: <ID extends string>(id: ID) => `/applications/${id}/achievements` as const,
  PROMOTIONAL_ASSETS: <ID extends string, SkuID extends string>(id: ID, skuId: SkuID) =>
    `/applications/${id}/skus/${skuId}/assets` as const,
  ANALYTICS: <ID extends string>(id: ID) => `/applications/${id}/analytics` as const,
  ANALYTICS_ACTIVATION: <ID extends string>(id: ID) => `/applications/${id}/analytics/activation` as const,
  ANALYTICS_ACQUISITION: <ID extends string>(id: ID) => `/applications/${id}/analytics/acquisition` as const,
  ANALYTICS_ENGAGEMENT: <ID extends string>(id: ID) => `/applications/${id}/analytics/engagement` as const,
  ANALYTICS_REVENUE: <ID extends string>(id: ID) => `/applications/${id}/analytics/revenue` as const,
  DEVELOPER_LICENSE: <ID extends string>(id: ID) => `/applications/${id}/developer-license` as const,
  GUILDS: `/servers`,
  GUILD: <ID extends string>(id: ID) => `/servers/${id}` as const,
  GUILD_ANALYTICS: <ID extends string>(id: ID) => `/servers/${id}/analytics` as const,
  HANDOFF_EXCHANGE: '/handoff',
  GUILD_ANALYTICS_ENGAGEMENT: <ID extends string>(id: ID) => `/servers/${id}/analytics/engagement` as const,
  GUILD_ANALYTICS_CHANNEL_FOLLOWING: <ID extends string>(id: ID) =>
    `/servers/${id}/analytics/channel-following` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION: <ID extends string>(id: ID) =>
    `/servers/${id}/analytics/growth-activation` as const,
  GUILD_ANALYTICS_WELCOME_SCREEN: <ID extends string>(id: ID) => `/servers/${id}/analytics/welcome-screen` as const,
  GUILD_ANALYTICS_AUDIENCE: <ID extends string>(id: ID) => `/servers/${id}/analytics/audience` as const,
  GUILD_ANALYTICS_PARTNER: <ID extends string>(id: ID) => `/servers/${id}/analytics/partner` as const,
  GUILD_ANALYTICS_ROLE_SUBSCRIPTIONS: <ID extends string>(id: ID) =>
    `/servers/${id}/analytics/premium-membership` as const,
  TETRIS_AUDIENCE_INSIGHTS: <ID extends string>(id: ID) =>
    `/servers/${id}/analytics/advanced-audience-insights` as const,
  TETRIS_MESSAGE_REACH: <ID extends string>(id: ID) => `/servers/${id}/analytics/message-reach` as const,
  // TEAMS
  TEAMS: '/teams',
  TEAM_INVITE_ACCEPT: '/teams/accept',
  EDIT_TEAM: <ID extends string>(id: ID) => `/teams/${id}` as const,
  TEAM_INFORMATION: <ID extends string>(id: ID) => `/teams/${id}/information` as const,
  TEAM_PAYOUT_SETTINGS: <ID extends string>(id: ID) => `/teams/${id}/payout-settings` as const,
  TEAM_PAYOUT_SETTINGS_TIPALTI_EDIT: <ID extends string>(id: ID) =>
    `/teams/${id}/payout-settings/tipalti/edit` as const,
  TEAM_PAYOUT_HISTORY: <ID extends string>(id: ID) => `/teams/${id}/payout-history` as const,
  // OLD/REDIRECTS
  OAUTH2_GENERAL: <ID extends string>(id: ID) => `/applications/${id}/oauth2/general` as const,
  OAUTH2_URL_GENERATOR: <ID extends string>(id: ID) => `/applications/${id}/oauth2/url-generator` as const,
  // DOCS
  ...APIDocsRoutes,
  // OTHER
  POPUP_WINDOW_TRANSITION_LOADING: '/popup/loading',
  POPUP_WINDOW_TRANSITION_CALLBACK: '/popup/callback',
  ERROR_404: '/404',
  LOCAL_DEVELOPMENT_AUTH: '/internal/auth',
} as const);

const UnsafeEndpoints = Object.freeze({
  DEV_PORTAL_CSAT_SURVEY_RESPONSE: '/dev-portal-csat-survey-response',
  EXPERIMENTS: '/experiments',
  TRACK: '/science',
  REGISTER: '/auth/register',
  UNFURL: '/unfurler/unfurl',
  USER_GUILDS: '/users/@me/guilds',
  USER: '/users/@me',
  USER_WITH_ANALYTICS_TOKEN: '/users/@me?with_analytics_token=true',
  USER_ENTITLEMENTS: <ID extends string>(applicationId: ID) =>
    `/users/@me/applications/${applicationId}/entitlements` as const,
  USER_IDENTITY_VERIFICATION: '/users/@me/identity/verification',
  APPLICATIONS: '/applications',
  APPLICATION: <ID extends string>(id: ID) => `/applications/${id}` as const,
  APPLICATION_DELETE: <ID extends string>(id: ID) => `/applications/${id}/delete` as const,
  APPLICATION_RESET: <ID extends string>(id: ID) => `/applications/${id}/reset` as const,
  APPLICATION_OWNER_TRANSFER: <ID extends string>(id: ID) => `/applications/${id}/transfer` as const,
  APPLICATION_APPROVALS: <ID extends string>(id: ID) => `/applications/${id}/approvals` as const,
  APPLICATION_BOT: <ID extends string>(id: ID) => `/applications/${id}/bot` as const,
  APPLICATION_BOT_RESET: <ID extends string>(id: ID) => `/applications/${id}/bot/reset` as const,
  APPLICATION_EMOJIS: <ID extends string>(applicationId: ID) => `/applications/${applicationId}/emojis` as const,
  APPLICATION_EMOJI: <AppID extends string, EmojiID extends string>(applicationId: AppID, emojiId: EmojiID) =>
    `/applications/${applicationId}/emojis/${emojiId}` as const,
  APPLICATION_DIRECTORY_ENTRY: <ID extends string>(applicationId: ID) =>
    `/application-directory/applications/${applicationId}` as const,
  APPLICATION_DISCOVERABILITY_STATE: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/discoverability-state` as const,
  APPLICATION_SKUS: <ID extends string>(applicationId: ID) => `/applications/${applicationId}/skus` as const,
  APPLICATION_SUBSCRIPTION_GROUP_LISTINGS: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/subscription-group-listings` as const,
  APPLICATION_SUBSCRIPTION_GROUP_LISTING: <AppID extends string, GroupListingID extends string>(
    applicationId: AppID,
    subscriptionGroupListingId: GroupListingID,
  ) => `/applications/${applicationId}/subscription-group-listings/${subscriptionGroupListingId}` as const,
  APPLICATION_SUBSCRIPTION_LISTINGS: <AppID extends string, GroupListingID extends string>(
    applicationId: AppID,
    subscriptionGroupListingId: GroupListingID,
  ) =>
    `/applications/${applicationId}/subscription-group-listings/${subscriptionGroupListingId}/subscription-listings` as const,
  APPLICATION_SUBSCRIPTION_LISTING: <AppID extends string, GroupListingID extends string, ListingID extends string>(
    applicationId: AppID,
    subscriptionGroupListingId: GroupListingID,
    subscriptionListingId: ListingID,
  ) =>
    `/applications/${applicationId}/subscription-group-listings/${subscriptionGroupListingId}/subscription-listings/${subscriptionListingId}` as const,
  APPLICATION_ACHIEVEMENTS: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/achievements` as const,
  ACHIEVEMENT: <AppID extends string, AchievementID extends string>(
    applicationId: AppID,
    achievementId: AchievementID,
  ) => `/applications/${applicationId}/achievements/${achievementId}` as const,
  ACHIEVEMENT_CREATE: <ID extends string>(applicationId: ID) => `/applications/${applicationId}/achievements` as const,
  APPLICATION_REQUEST_ADDITIONAL_INTENTS: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/request-additional-intents` as const,
  APPLICATION_LICENSE_ACTIVATE: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/activate-license` as const,
  APPLICATION_PROXY_CONFIG: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/proxy-config` as const,
  APPLICATION_EMBEDDED_ACTIVITY_CONFIG: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/embedded-activity-config` as const,
  APPLICATION_EMBEDDED_TOGGLE: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/set-embedded` as const,
  APPLICATION_ASSETS: <ID extends string>(id: ID) => `/oauth2/applications/${id}/assets` as const,
  APPLICATION_ASSET: (applicationId: string, assetId: string) =>
    `/oauth2/applications/${applicationId}/assets/${assetId}` as const,
  APPLICATION_RICH_PRESENCE_APPROVAL_FORM: <ID extends string>(applicationId: ID) =>
    `/oauth2/applications/${applicationId}/rich-presence/approval-form` as const,
  APPLICATION_RICH_PRESENCE_APPROVAL_FORM_SCREENSHOTS: <ID extends string>(applicationId: ID) =>
    `/oauth2/applications/${applicationId}/rich-presence/approval-form/screenshots` as const,
  APPLICATION_RICH_PRESENCE_APPROVAL_FORM_SCREENSHOT: (applicationId: string, screenshotId: string) =>
    `/oauth2/applications/${applicationId}/rich-presence/approval-form/screenshots/${screenshotId}` as const,
  APPLICATION_RICH_PRESENCE_APPROVAL_FORM_SCREENSHOT_IMAGE: (applicationId: string, screenshotId: string) =>
    `/oauth2/applications/${applicationId}/rich-presence/approval-form/screenshots/${screenshotId}.jpg` as const,
  APPLICATION_WHITELIST: <ID extends string>(id: ID) => `/oauth2/applications/${id}/allowlist` as const,
  APPLICATION_WHITELIST_USER: <AppID extends string, UserID extends string>(appId: AppID, userId: UserID) =>
    `/oauth2/applications/${appId}/allowlist/${userId}` as const,
  LOGOUT: '/auth/logout',
  CREATE_SKU: '/store/skus',
  SKU: <ID extends string>(skuId: ID) => `/store/skus/${skuId}` as const,
  SKU_STORE_LISTINGS: <ID extends string>(skuId: ID) => `/store/skus/${skuId}/listings` as const,
  APPLICATION_STORE_LISTINGS: <ID extends string>(applicationId: ID) =>
    `/store/applications/${applicationId}/listings` as const,
  PURCHASE_SKU: <ID extends string>(skuId: ID) => `/store/skus/${skuId}/purchase` as const,
  BRANCHES: <ID extends string>(applicationId: ID) => `/applications/${applicationId}/branches` as const,
  BRANCH_BUILDS: <AppID extends string, BranchID extends string>(applicationId: AppID, branchId: BranchID) =>
    `/applications/${applicationId}/branches/${branchId}/builds` as const,
  PUBLISH_BUILD: <AppID extends string, BranchID extends string, BuildID extends string>(
    applicationId: AppID,
    branchId: BranchID,
    buildId: BuildID,
  ) => `/applications/${applicationId}/branches/${branchId}/builds/${buildId}/publish` as const,
  MANIFEST_LABELS: <ID extends string>(applicationId: ID) => `/applications/${applicationId}/manifest-labels` as const,
  STORE_ASSETS: <ID extends string>(applicationId: ID) => `/store/applications/${applicationId}/assets` as const,
  STORE_LAYOUT: <ID extends string>(applicationId: ID) => `/applications/${applicationId}/store-layout` as const,
  FETCH_STORE_ASSET: <AppID extends string, AssetID extends string, Format extends string>(
    applicationId: AppID,
    assetId: AssetID,
    format: Format,
  ) => `/store/applications/${applicationId}/assets/${assetId}.${format}` as const,
  DELETE_STORE_ASSET: <AppID extends string, AssetID extends string>(applicationId: AppID, assetId: AssetID) =>
    `/store/applications/${applicationId}/assets/${assetId}` as const,
  STORE_LISTINGS: `/store/listings` as const,
  STORE_LISTING: <ID extends string>(storeListingId: ID) => `/store/listings/${storeListingId}` as const,
  PRICE_TIERS: `/store/price-tiers` as const,
  PRICE_TIER_VALUES: <Tier extends PriceTier>(priceTier: Tier) => `/store/price-tiers/${priceTier}` as const,
  STORE_ASSET_IMAGE: <AppID extends string, AssetID extends string, Format extends string>(
    applicationId: AppID,
    assetId: AssetID,
    format: Format,
  ) => `/store/applications/${applicationId}/assets/${assetId}.${format}` as const,
  STORE_SUBSCRIPTION_PLANS: (skuId: SkuId) => `/store/skus/${skuId}/plans`,
  TEAMS: '/teams',
  TEAM: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}` as const,
  TEAM_MEMBERS: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}/members` as const,
  TEAM_MEMBER: (teamId: TeamId, userId: string) => `/teams/${teamId}/members/${userId}` as const,
  TEAM_APPLICATIONS: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}/applications` as const,
  TEAM_DELETE: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}/delete` as const,
  TEAM_COMPANIES: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}/companies` as const,
  TEAM_PAYOUT_IFRAME_URL: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}/payouts/onboarding` as const,
  TEAM_PAYOUT_HISTORY: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}/payouts` as const,
  TEAM_IDENTITY_VERIFICATION: <ID extends TeamId>(teamId: ID) => `/teams/${teamId}/identity/verification` as const,
  TEAM_STRIPE_CONNECT_REDIRECT: <ID extends TeamId>(teamId: ID) =>
    `/teams/${teamId}/stripe/connect/redirect-url` as const,
  BILLING_STRIPE_PAYMENT_INTENTS: <ID extends string>(paymentId: ID) =>
    `/users/@me/billing/stripe/payment-intents/payments/${paymentId}` as const,
  PAYMENT_SOURCES: '/users/@me/billing/payment-sources',
  ACCEPT_TEAM_INVITE: '/teams/invite/accept',
  COMPANIES: `/companies` as const,
  COMPANY: <ID extends CompanyId>(companyId: ID) => `/companies/${companyId}` as const,
  ANALYTICS_REVENUE: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/revenue` as const,
  ANALYTICS_UNITS: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/units` as const,
  ANALYTICS_ACQUISITION_UTM: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/acquisitions/utm` as const,
  ANALYTICS_ACQUISITION_EXTERNAL: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/acquisitions/external` as const,
  ANALYTICS_ACQUISITION_FUNNEL: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/acquisitions/funnel` as const,
  ANALYTICS_ACQUISITION_IMPRESSIONS_BY_TYPE: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/acquisitions/impressions` as const,
  ANALYTICS_ACQUISITION_ACQUIRERS: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/acquisitions/acquirers` as const,
  ANALYTICS_ACQUISITION_STORE_LISTING_VIEWS: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/acquisitions/store-listing-views` as const,
  ANALYTICS_ENGAGEMENT_ACTIVE_PLAYERS_BY_COUNTRY: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/engagement/active-players` as const,
  ANALYTICS_ENGAGEMENT_PLAYER_RETENTION_BY_DATE: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/engagement/player-retention` as const,
  ANALYTICS_ACTIVATION_INSTALLATIONS: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/activations/installations` as const,
  ANALYTICS_ACTIVATION_FUNNEL: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/activations/funnel` as const,
  ANALYTICS_ACTIVATION_NEW_PLAYERS: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/activations/new-players` as const,
  ANALYTICS_ACTIVATION_REACTIVATED_PLAYERS: <ID extends ApplicationId>(applicationId: ID) =>
    `/application/${applicationId}/analytics/activations/reactivated-players` as const,
  GIFT_CODE_BATCHES: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/gift-code-batches` as const,
  GIFT_CODE_BATCHES_CSV_DOWNLOAD: <BatchID extends string, AppID extends string>(
    batchId: BatchID,
    applicationId: AppID,
  ) => `/applications/${applicationId}/gift-code-batches/${batchId}` as const,
  HANDOFF_EXCHANGE: '/auth/handoff/exchange',
  GUILD_ICON: <ID extends string, Hash extends string, Format extends 'jpg' | 'png' | 'gif' = 'jpg'>(
    guildId: ID,
    hash: Hash,
    format?: Format,
  ) => `/guilds/${guildId}/icons/${hash}.${format}` as const,
  // Server Insights - Growth and Activation
  GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/joins` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_OVERVIEW: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/overview` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS_BY_INVITE_LINK: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/joins-by-invite-link` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS_BY_REFERRER: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/joins-by-referrer` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS_BY_SOURCE: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/joins-by-source` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_LEAVERS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/leavers` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_ACTIVATION: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/activation` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_RETENTION: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/retention` as const,
  GUILD_ANALYTICS_GROWTH_ACTIVATION_MEMBERSHIP: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/growth-activation/membership` as const,
  // Server Insights - Engagement
  GUILD_ANALYTICS_ENGAGEMENT_BASE: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/engagement/base` as const,
  GUILD_ANALYTICS_ENGAGEMENT_OVERVIEW: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/engagement/overview` as const,
  GUILD_ANALYTICS_ENGAGEMENT_MUTERS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/engagement/muters` as const,
  GUILD_ANALYTICS_ENGAGEMENT_PRUNEABLE_MEMBERS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/engagement/pruneable-members` as const,
  GUILD_ANALYTICS_ENGAGEMENT_TEXT_CHANNELS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/engagement/text-channels` as const,
  GUILD_ANALYTICS_ENGAGEMENT_VOICE_CHANNELS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/engagement/voice-channels` as const,
  // Server Insights - Audience
  GUILD_ANALYTICS_AUDIENCE_NEW_MEMBERS_BY_DISCORD_TENURE: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/new-members-by-discord-tenure` as const,
  GUILD_ANALYTICS_AUDIENCE_PARTICIPATORS_BY_GUILD_TENURE: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/participators-by-guild-tenure` as const,
  GUILD_ANALYTICS_AUDIENCE_PARTICIPATORS_BY_PLATFORM: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/participators-by-platform` as const,
  GUILD_ANALYTICS_AUDIENCE_PARTICIPATORS_BY_REG_COUNTRY: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/participators-by-reg-country` as const,
  // Server Insights - Announcement Channels
  GUILD_ANALYTICS_CHANNEL_FOLLOWING_OVERVIEW: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/channel-following/overview` as const,
  GUILD_ANALYTICS_CHANNEL_FOLLOWING_BY_CHANNEL_OVERVIEW: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/channel-following/announcements-by-channel` as const,
  GUILD_ANALYTICS_CHANNEL_FOLLOWING_REACH: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/channel-following/reach` as const,
  GUILD_ANALYTICS_CHANNEL_FOLLOWING_FOLLOWING_GUILD_SIZE: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/channel-following/following-guild-size` as const,
  GUILD_ANALYTICS_CHANNEL_FOLLOWING_GUILD_SIZE_BY_CHANNEL: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/channel-following/following-guild-size-by-channel` as const,
  // Server Insights - Welcome Screen
  GUILD_ANALYTICS_WELCOME_SCREEN_FUNNEL: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/welcome-screen/funnel` as const,
  GUILD_ANALYTICS_WELCOME_SCREEN_USERS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/welcome-screen/users` as const,
  // Server Insights - Partner Program
  GUILD_ANALYTICS_PARTNER_PROGRAM_HEALTH: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/partner-program/health` as const,
  // Server Insights - Role Subscriptions
  GUILD_ANALYTICS_ROLE_SUBSCRIPTION_EVENTS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/role-subscriptions/events` as const,
  GUILD_ANALYTICS_ROLE_SUBSCRIPTION_CANCELLATIONS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/role-subscriptions/cancellations` as const,
  GUILD_ANALYTICS_ROLE_SUBSCRIPTION_PAYOUTS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/role-subscriptions/payouts` as const,
  GUILD_ANALYTICS_ROLE_SUBSCRIPTION_PAYOUTS_BY_PLATFORM: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/role-subscriptions/payouts-by-platform` as const,
  GUILD_ANALYTICS_ROLE_SUBSCRIPTION_MEMBERS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/role-subscriptions/members` as const,
  GUILD_ANALYTICS_ROLE_SUBSCRIPTION_ESTIMATED_EARNINGS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/role-subscriptions/estimated-earnings` as const,
  GUILD_CHANNELS: <ID extends string>(guildId: ID) => `/guilds/${guildId}/channels` as const,
  MESSAGES: <ID extends string>(channelId: ID) => `/channels/${channelId}/messages` as const,
  GUILD_ANALYTICS_ACTIVITY_BY_DAY_OF_WEEK: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/understanding/day-of-week` as const,
  GUILD_ANALYTICS_ACTIVITY_BY_TIME_OF_DAY: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/understanding/time-of-day` as const,
  GUILD_ANALYTICS_AGE_DISTRIBUTION: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/understanding/age` as const,
  GUILD_ANALYTICS_GENDER_DISTRIBUTION: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/understanding/gender` as const,
  GUILD_ANALYTICS_LANGUAGE_DISTRIBUTION: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/understanding/language` as const,
  GUILD_ANALYTICS_GUILD_LEAVE_REASON: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/understanding/leave-reason` as const,
  GUILD_ANALYTICS_OTHER_GUILD_MEMBERSHIP: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/interests/guild-membership` as const,
  GUILD_ANALYTICS_OTHER_GUILD_CATEGORIES: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/audience/interests/guild-categories` as const,
  GUILD_ANALYTICS_ANNOUNCEMENT_MESSAGE_IMPRESSIONS: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/message-reach/announcement-message-impressions` as const,
  GUILD_ANALYTICS_GUILDS_FOLLOWING: <ID extends string>(guildId: ID) =>
    `/guilds/${guildId}/analytics/message-reach/guilds-following` as const,
  ACTIVE_DEVELOPER_PROGRAM: '/developers/active-program',
  MONETIZATION_SKU_LIFETIME_ANALYTICS: (applicationId: string, skuId: string) =>
    `/applications/${applicationId}/analytics/skus/${skuId}` as const,
  MONETIZATION_SKU_SUBSCRIPTION_ANALYTICS: (applicationId: string, skuId: string) =>
    `/applications/${applicationId}/analytics/skus/${skuId}/subscriptions` as const,
  MONETIZATION_SKU_CANCELLATION_ANALYTICS: (applicationId: string, skuId: string) =>
    `/applications/${applicationId}/analytics/skus/${skuId}/subscriptions/cancellations` as const,
  MONETIZATION_CANCELLATION_ANALYTICS: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/analytics/subscriptions/cancellations` as const,
  MONETIZATION_TRANSACTION_ANALYTICS: <ID extends string>(applicationId: ID) =>
    `/applications/${applicationId}/analytics/transactions` as const,
  EMOJI: <ID extends string, Extension extends string>(emojiId: ID, extension: Extension) =>
    `/emojis/${emojiId}.${extension}` as const,
  LOGIN_SMS_SEND: '/auth/mfa/sms/send',
  AUTO_VERIFICATION: <ID extends string>(applicationId: ID) => `/applications/${applicationId}/auto-verification`,
} as const);

// Endpoints and Routes which take arguments are automatically urlencoded for safety.
export const Routes = wrapPaths(UnsafeRoutes, [':', '?']);
export const Endpoints = wrapPaths(UnsafeEndpoints);

const _Images = {
  BOT_ROBOILLO: require('./images/bot-roboillo.svg'),

  ASSET_DEFAULT_AVATAR_0: require('./images/asset_default_avatar_0.png'),
  ASSET_DEFAULT_AVATAR_1: require('./images/asset_default_avatar_1.png'),
  ASSET_DEFAULT_AVATAR_2: require('./images/asset_default_avatar_2.png'),
  ASSET_DEFAULT_AVATAR_3: require('./images/asset_default_avatar_3.png'),

  EMPTY_GENERIC: require('./images/empty_generic.svg'),
  EMPTY_GUILD_INSIGHTS_GUILD_LIST: require('./images/empty_guild_insights_guild_list.svg'),

  PIZZA_TRACKER_RECTANGLE_PURPLE: require('./images/pizza_tracker_rectangle_purple.svg'),
  PIZZA_TRACKER_RECTANGLE_GRAY: require('./images/pizza_tracker_rectangle_gray.svg'),
  PIZZA_TRACKER_RECTANGLE_RED: require('./images/pizza_tracker_rectangle_red.svg'),
  PIZZA_TRACKER_RECTANGLE_GREEN: require('./images/pizza_tracker_rectangle_green.svg'),
};
export const Links = {
  STATUS: SharedLinks.STATUS,
  HELP_AND_SUPPORT: `//${SUPPORT_DOMAIN}/hc/en-us`,
  FEEDBACK: `//${FEEDBACK_DOMAIN}`,
  EMAIL_SUPPORT: `mailto:support@${EMAIL_DOMAIN}`,
  EMAIL_LAW_ENFORCEMENT: `mailto:lawenforcement@${EMAIL_DOMAIN}`,
  DEV_PERKS_FORM: 'https://goo.gl/forms/oZfKBStV3sR8GHdU2',
  VERIFICATION_REQUIREMENTS: 'https://dis.gd/vsreqs',
  BASE_URL: `https://${PRIMARY_DOMAIN}`,
  API_DOCS_REPO: 'https://github.com/discord/discord-api-docs',
  API_DOCS: `https://${PRIMARY_DOMAIN}/developers/docs/intro`,
  DEVELOPER_POLICY: `https://${SUPPORT_DEV_DOMAIN}/hc/articles/8563934450327-Discord-Developer-Policy`,
  TERMS_OF_SERVICE: `https://${SUPPORT_DEV_DOMAIN}/hc/articles/8562894815383-Discord-Developer-Terms-of-Service`,
  GOOGLE_AUTH: 'https://support.google.com/accounts/answer/1066447?hl=en',
  AUTHY: 'https://www.authy.com/',
  APPLICATION_ANALYTICS_SUPPORT: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/360024852152`,
  UTM_CONVERSIONS_SUPPORT: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/360025153051`,
  STORE_ASSETS_SUPPORT: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/360020346072`,
  DEVELOPER_SUPPORT: 'https://dis.gd/devsupport',
  GAME_SUBMISSION_PAUSED_HELP_CENTER: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/360041437171`,
  APPLICATION_VERIFICATION_SUPPORT: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/23926564536471`,
  PRIVILEGED_INTENTS_SUPPORT: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/6205754771351`,
  TRUST_AND_SAFETY_TICKET: `https://${SUPPORT_DOMAIN}/hc/en-us/requests/new?ticket_form_id=360000029731`,
  PARTNERSHIP_REQUIREMENTS_SUPPORT: `https://${SUPPORT_DOMAIN}/hc/en-us/articles/115001494012`,
  MESSAGE_CONTENT_FAQ: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/4404772028055`,
  SELF_SERVE_PUBLISHING_DEPRECATION_HELP_CENTER: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/4414590563479`,
  DEVELOPER_SURVEY: 'https://discord.com/invite/discord-townhall',
  COMMUNITY_ENABLE_SUPPORT: 'https://support.discord.com/hc/en-us/articles/360047132851-Enabling-Your-Community-Server',
  ACTIVE_DEVELOPER_PROGRAM: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/10113997751447`,
  APPLICATION_DIRECTORY_PROFILE: <ID extends string>(applicationId: ID) =>
    `https://discord.com/application-directory/${applicationId}` as const,
  DISABLE_SERVER_SUBSCRIPTIONS_SUPPORT:
    'https://creator-support.discord.com/hc/en-us/articles/10423011974551-Server-Subscriptions-For-Creators-Server-Owners-Admins#h_01GKPEHXYMY354VVQF1520K0SD',
  SERVER_SHOP_PAYMENT_EXPLANATION:
    'https://creator-support.discord.com/hc/en-us/articles/10423011974551#h_01H96DAAQW0EG57QSCGMW3CZ6J',
  QUARANTINE_APPEAL_FORM: `https://dis.gd/app-quarantine`,
  SKU_LEARN_MORE: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/17298449675927`,
  OEMBED_EMBED: 'https://oembed.com',
  OPENGRAPH_EMBED: 'https://ogp.me',
  TWITTER_CARD_EMBED: 'https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup',
  APPLICATION_EVENT_WEBHOOKS: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles`,
  MARKETING_PAGE_PREMIUM_APPS: `https://${PRIMARY_DOMAIN}/build/premium-apps`,
  DEVELOPER_HELP_CENTER: `https://${SUPPORT_DEV_DOMAIN}/hc`,
  HELP_ARTICLE_SET_UP_2FA: `https://${SUPPORT_DOMAIN}/hc/en-us/articles/219576828-Setting-up-Two-Factor-Authentication`,
  HELP_ARTICLE_APP_PROFILE_PAGES: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/6378525413143`,
  HELP_ARTICLE_APP_MONETIZATION: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/17297949965079`,
  HELP_ARTICLE_MONETIZATION_TERMS: `https://${SUPPORT_DOMAIN}/hc/en-us/articles/5330075836311-Monetization-Terms`,
  PREMIUM_APPS_POLICY: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/17442400631959`,
  HELP_ARTICLE_PAYOUTS_PROCESS: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/17299902720919`,
  OAUTH2_AUTHORIZE: (queryString: string) => `https://${PRIMARY_DOMAIN}/oauth2/authorize?${queryString}`,
  DEFAULT_OAUTH2_LINK: (applicationId: string) =>
    `https://${PRIMARY_DOMAIN}/oauth2/authorize?client_id=${applicationId}`,
  EMBEDDED_APP_OVERVIEW: `https://${PRIMARY_DOMAIN}/developers/docs/activities/overview`,
  EMBEDDED_APP_GETTING_STARTED: `https://${PRIMARY_DOMAIN}/developers/docs/activities/building-an-activity`,
  EMBEDDED_APP_LEARN_MORE: `https://${PRIMARY_DOMAIN}/developers/docs/activities/overview#public-developer-preview`,
  EMBEDDED_APP_PRIMARY_ENTRY_POINT: `https://${PRIMARY_DOMAIN}/developers/docs/interactions/application-commands#entry-point-commands`,
  ACCOUNT_STANDING: `https://${SUPPORT_DOMAIN}/hc/en-us/articles/18210965981847-Discord-Warning-System#h_01HD4SVGAF2KT4AG7NH8BJBFKV`,
  EMBEDDED_APP_PUBLISH_FORM: 'https://share.hsforms.com/1lugXI_J3QEyls9nqlzE8vA2bach',
  APP_DIRECTORY_INCLUSION_GUIDELINES: `https://${SUPPORT_DEV_DOMAIN}/hc/en-us/articles/8852009977879-App-Directory-Inclusion-Guidelines`,
} as const;
export const Images = _Images as typeof _Images & Record<string, string>;

export const ImageMetaData = {
  DEVELOPERS: require('./images/og/og_developers.png'),
};

export const ApplicationFlags = {
  EMBEDDED_RELEASED: 1 << 1,
  EMBEDDED_IAP: 1 << 3,
  GATEWAY_PRESENCE: 1 << 12,
  GATEWAY_PRESENCE_LIMITED: 1 << 13,
  GATEWAY_GUILD_MEMBERS: 1 << 14,
  GATEWAY_GUILD_MEMBERS_LIMITED: 1 << 15,
  EMBEDDED: 1 << 17,
  GATEWAY_MESSAGE_CONTENT: 1 << 18,
  GATEWAY_MESSAGE_CONTENT_LIMITED: 1 << 19,
  EMBEDDED_FIRST_PARTY: 1 << 20,
  ACTIVE: 1 << 24,
  IFRAME_MODAL: 1 << 26,
};

export const ApplicationTypes = {
  GUILD_ROLE_SUBSCRIPTIONS: 4,
};

export const RPCApplicationApprovalStates = {
  DISABLED: 0,
  UNSUBMITTED: 1,
  SUBMITTED: 2,
  APPROVED: 3,
  REJECTED: 4,
};

export const StoreApplicationApprovalStates = {
  NONE: 1,
  PAID: 2,
  SUBMITTED: 3,
  APPROVED: 4,
  REJECTED: 5,
};

export const PayoutAccountStatuses = {
  UNSUBMITTED: 1,
  PENDING: 2,
  ACTION_REQUIRED: 3,
  ACTIVE: 4,
  BLOCKED: 5,
  SUSPENDED: 6,
};

export const APIErrorCodes = {
  UNKNOWN_ERROR: -1,
  UNKNOWN_USER: 10013,
  UNKNOWN_APPROVAL_FORM: 10023,
  UNKNOWN_TEAM: 10030,
  UNKNOWN_TEAM_MEMBER: 10040,
  TEAM_OWNERSHIP_REQUIRED: 20018,
  TOO_MANY_APPLICATION_GAME_SKUS: 30021,
  TOO_MANY_TEAMS: 30023,
  TOO_MANY_COMPANIES: 30025,
  NOT_ENOUGH_GUILD_MEMBERS: 30029,
  UNVERIFIED_EMAIL: 40002,
  TEAM_MEMBER_ALREADY_EXISTS: 40024,
  TEAM_MEMBERS_MUST_BE_VERIFIED: 40026,
  TEAM_INVITATION_ALREADY_ACCEPTED: 40027,
  USER_IDENTITY_VERIFICATION_PROCESSING: 40035,
  USER_IDENTITY_VERIFICATION_ALREADY_SUCCEEDED: 40036,
  INVALID_ACCOUNT_TYPE: 50002,
  INVALID_TOKEN: 50014,
  INVALID_FORM_BODY: 50035,
  INVALID_APPLICATION_NAME: 50050,
  MFA_REQUIRED: 60003,
  INVALID_MFA_CODE: 60008,
  AUTHENTICATION_REQUIRED: 100029,
  PRIVACY_POLICY_REQUIRED: 190001,
  TERMS_OF_SERVICE_REQUIRED: 190002,
};

export const APIFormErrorCodes = {
  APPLICATION_VERIFICATION_DISABLED: 'APPLICATION_VERIFICATION_DISABLED',
};

export const HTTPStatusCodes = {
  TOO_MANY_REQUESTS: 429,
};

export enum ComponentActions {
  EMPHASIZE_NOTICE = 'EMPHASIZE_NOTICE',
}

export const KeyboardKeys = Object.freeze({
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  COMMA: 188,
  DIGIT_0: 48,
  DIGIT_1: 49,
  DIGIT_2: 50,
  DIGIT_3: 51,
  DIGIT_4: 52,
  DIGIT_5: 53,
  DIGIT_6: 54,
  DIGIT_7: 55,
  DIGIT_8: 56,
  DIGIT_9: 57,
  A: 65,
  B: 66,
  D: 68,
  E: 69,
  H: 72,
  I: 73,
  K: 75,
  N: 78,
  P: 80,
  S: 83,
  U: 85,
} as const);

export enum DocumentationCategories {
  UNCATEGORIZED = '',
  QUICK_START = 'quick_start',
  RESOURCES = 'resources',
  TOPICS = 'topics',
  RICH_PRESENCE = 'rich_presence',
  INTERACTIONS = 'interactions',
  TUTORIALS = 'tutorials',
  BEST_PRACTICES = 'best_practices',
  POLICIES_AND_AGREEMENTS = 'policies_and_agreements',
  MONETIZATION = 'monetization',
  ACTIVITIES = 'activities',
  DEVELOPER_TOOLS = 'developer_tools',
  CHANGE_LOG = 'change_log',
  DISTRIBUTION = 'distribution',
}

export const ExcludedDocumentationCategories = Object.freeze(['fr', 'tr', 'de', 'da', 'pt-br', 'nl', 'ja', 'pl']);

export const DocumentationCategoryOrder = Object.freeze([
  DocumentationCategories.UNCATEGORIZED,
  DocumentationCategories.QUICK_START,
  DocumentationCategories.INTERACTIONS,
  DocumentationCategories.ACTIVITIES,
  DocumentationCategories.RICH_PRESENCE,
  DocumentationCategories.MONETIZATION,
  DocumentationCategories.DISTRIBUTION,
  DocumentationCategories.RESOURCES,
  DocumentationCategories.TOPICS,
  DocumentationCategories.DEVELOPER_TOOLS,
  DocumentationCategories.TUTORIALS,
  DocumentationCategories.BEST_PRACTICES,
  DocumentationCategories.POLICIES_AND_AGREEMENTS,
  DocumentationCategories.CHANGE_LOG,
] as const);

// TODO @Mason: generate weights via the markdown parser

// -1 value hides the section in navigation
export const DocumentationCategoryPageWeights: Readonly<Record<string, Record<string, number>>> = Object.freeze({
  [DocumentationCategories.UNCATEGORIZED]: {
    intro: 3,
    'change-log': 2,
    reference: 1,
  },
  [DocumentationCategories.QUICK_START]: {
    'overview-of-apps': 2,
    'getting-started': 1,
  },
  [DocumentationCategories.INTERACTIONS]: {
    overview: 4,
    'application-commands': 2,
    'message-components': 1,
    'receiving-and-responding': 3,
  },
  [DocumentationCategories.RICH_PRESENCE]: {
    overview: 4,
    'using-with-the-embedded-app-sdk': 3,
    'using-with-the-game-sdk': 2,
    'best-practices': 1,
  },
  [DocumentationCategories.POLICIES_AND_AGREEMENTS]: {
    'developer-policy': 2,
    'developer-terms-of-service': 1,
    'store-distribution-agreement': -1,
  },
  [DocumentationCategories.MONETIZATION]: {
    overview: 7,
    'enabling-monetization': 6,
    'managing-skus': 5,
    'implementing-app-subscriptions': 4,
    'implementing-one-time-purchases': 3,
  },
  [DocumentationCategories.ACTIVITIES]: {
    overview: 7,
    'how-activities-work': 6,
    'building-an-activity': 5,
    'development-guides': 4,
    'design-patterns': 3,
  },
  [DocumentationCategories.DEVELOPER_TOOLS]: {
    'embedded-app-sdk': 3,
    'community-resources': 2,
    'game-sdk': 1,
  },
  [DocumentationCategories.DISTRIBUTION]: {
    'overview' : 4,
    'verifying-your-app': 3,
    'enabling-discovery': 2,
    'discovery-best-practices': 1,
  }
} as const);

export const DocumentationRouteLabels = Object.freeze({
  '/docs/developer-tools/game-sdk': {label: 'legacy', labelType: 'legacy-label'},
});

// Eventually these should be pulled directly from frontmatter in the markdown
export const DocumentationExternalLinks = Object.freeze({
  '/docs/policies-and-agreements/developer-policy': Links.DEVELOPER_POLICY,
  '/docs/policies-and-agreements/developer-terms-of-service': Links.TERMS_OF_SERVICE,
});

export const DocumentationPages = APIDocsEndpoints.map((topic) => {
  const {name, key, category, sidebarLabel} = topic;
  const route = Routes[`${API_DOCS_ROUTE_PREFIX}${key}`];
  const categoryWeights = DocumentationCategoryPageWeights[category ?? ''];
  const weightIndex = route.split('/').pop();
  const weight = categoryWeights != null && weightIndex != null ? (categoryWeights[weightIndex] ?? 0) : 0;
  return {
    name,
    category,
    route,
    weight,
    sidebarLabel,
  };
});

export enum OperatingSystems {
  WINDOWS = '1',
  MACOS = '2',
  LINUX = '3',
}

export enum ContentRatingAgencies {
  ESRB = '1',
  PEGI = '2',
}

export enum Distributors {
  DISCORD = 'discord',
  STEAM = 'steam',
  TWITCH = 'twitch',
  UPLAY = 'uplay',
  BATTLENET = 'battlenet',
  ORIGIN = 'origin',
  GOG = 'gog',
  EPIC = 'epic',
}

export enum StoreApplicationStates {
  NONE = 1,
  PAID = 2,
  SUBMITTED = 3,
  APPROVED = 4,
  REJECTED = 5,
  BLOCKED = 6,
}

export const SkuAccessTypes = Object.freeze({
  FULL: 1,
  EARLY_ACCESS: 2,
} as const);

export const SkuFlags = {
  PREMIUM_PURCHASE: 1 << 0,
  HAS_FREE_PREMIUM_CONTENT: 1 << 1,
  AVAILABLE: 1 << 2,
  PREMIUM_AND_DISTRIBUTION: 1 << 3,
};

export enum HistoryActions {
  REPLACE = 'REPLACE',
  PUSH = 'PUSH',
  POP = 'POP',
}

export const getSkuTypeLabels = (): Record<SKUTypes, string> => {
  return {
    [SKUTypes.DURABLE_PRIMARY]: Messages.EmbeddedSkuTypes.DURABLE_PRIMARY,
    [SKUTypes.DURABLE]: Messages.EmbeddedSkuTypes.DURABLE,
    [SKUTypes.CONSUMABLE]: Messages.EmbeddedSkuTypes.CONSUMABLE,
    [SKUTypes.BUNDLE]: Messages.SkuTypes.BUNDLE,
    [SKUTypes.SUBSCRIPTION]: Messages.SkuTypes.SUBSCRIPTION,
    [SKUTypes.SUBSCRIPTION_GROUP]: Messages.SkuTypes.SUBSCRIPTION_GROUP,
  };
};

export const getSkuAccessTypeLabels = (): Record<Values<typeof SkuAccessTypes>, string> => {
  return {
    [SkuAccessTypes.FULL]: Messages.SkuAccessTypes.FULL,
    [SkuAccessTypes.EARLY_ACCESS]: Messages.SkuAccessTypes.EARLY_ACCESS,
  };
};

export const Ratings = {
  [ContentRatingAgencies.ESRB]: {
    EVERYONE: 1,
    EVERYONE_TEN_PLUS: 2,
    TEEN: 3,
    MATURE: 4,
    ADULTS_ONLY: 5,
    RATING_PENDING: 6,
  },
  [ContentRatingAgencies.PEGI]: {
    THREE: 1,
    SEVEN: 2,
    TWELVE: 3,
    SIXTEEN: 4,
    EIGHTEEN: 5,
  },
};

export const ESRBContentDescriptors = {
  ALCOHOL_REFERENCE: 1,
  ANIMATED_BLOOD: 2,
  BLOOD: 3,
  BLOOD_AND_GORE: 4,
  CARTOON_VIOLENCE: 5,
  COMIC_MISCHIEF: 6,
  CRUDE_HUMOR: 7,
  DRUG_REFERENCE: 8,
  FANTASY_VIOLENCE: 9,
  INTENSE_VIOLENCE: 10,
  LANGUAGE: 11,
  LYRICS: 12,
  MATURE_HUMOR: 13,
  NUDITY: 14,
  PARTIAL_NUDITY: 15,
  REAL_GAMBLING: 16,
  SEXUAL_CONTENT: 17,
  SEXUAL_THEMES: 18,
  SEXUAL_VIOLENCE: 19,
  SIMULATED_GAMBLING: 20,
  STRONG_LANGUAGE: 21,
  STRONG_LYRICS: 22,
  STRONG_SEXUAL_CONTENT: 23,
  SUGGESTIVE_THEMES: 24,
  TOBACCO_REFERENCE: 25,
  USE_OF_ALCOHOL: 26,
  USE_OF_DRUGS: 27,
  USE_OF_TOBACCO: 28,
  VIOLENCE: 29,
  VIOLENT_REFERENCES: 30,
  IN_GAME_PURCHASES: 31,
  USERS_INTERACT: 32,
  SHARES_LOCATION: 33,
  UNRESTRICTED_INTERNET: 34,
  MILD_BLOOD: 35,
  MILD_CARTOON_VIOLENCE: 36,
  MILD_FANTASY_VIOLENCE: 37,
  MILD_LANGUAGE: 38,
  MILD_LYRICS: 39,
  MILD_SEXUAL_THEMES: 40,
  MILD_SUGGESTIVE_THEMES: 41,
  MILD_VIOLENCE: 42,
  ANIMATED_VIOLENCE: 43,
};

export const PEGIContentDescriptors = {
  VIOLENCE: 1,
  BAD_LANGUAGE: 2,
  FEAR: 3,
  GAMBLING: 4,
  SEX: 5,
  DRUGS: 6,
  DISCRIMINATION: 7,
};

export enum SkuRequirementsFields {
  OS = 'operating_system_version',
  CPU = 'cpu',
  GPU = 'gpu',
  RAM = 'ram',
  DISK_SPACE = 'disk',
  SOUND_CARD = 'sound_card',
  DIRECT_X = 'directx',
  NETWORK = 'network',
  NOTES = 'notes',
}

export const getSystemRequirementsLabels = () => {
  return {
    [SkuRequirementsFields.OS]: Messages.SkuRequirements.OS_VERSION,
    [SkuRequirementsFields.CPU]: Messages.SkuRequirements.PROCESSOR,
    [SkuRequirementsFields.GPU]: Messages.SkuRequirements.GRAPHICS_CARD,
    [SkuRequirementsFields.RAM]: Messages.SkuRequirements.MEMORY,
    [SkuRequirementsFields.DISK_SPACE]: Messages.SkuRequirements.DISK_SPACE,
    [SkuRequirementsFields.SOUND_CARD]: Messages.SkuRequirements.SOUND_CARD,
    [SkuRequirementsFields.DIRECT_X]: Messages.SkuRequirements.DIRECTX_VERSION,
    [SkuRequirementsFields.NETWORK]: Messages.SkuRequirements.NETWORK,
    [SkuRequirementsFields.NOTES]: Messages.SkuRequirements.NOTES,
  };
};

export const RequiredSystemFields = [
  SkuRequirementsFields.OS,
  SkuRequirementsFields.CPU,
  SkuRequirementsFields.GPU,
  SkuRequirementsFields.RAM,
  SkuRequirementsFields.DISK_SPACE,
];

export const UserFlags = Object.freeze({
  STAFF: 1 << 0,
  TEAM_PSEUDO_USER: 1 << 10,
  ACTIVE_DEVELOPER: 1 << 22,
} as const);

export const DATE_FORMAT = 'yyyy-MM-dd';

export const TeamMembershipStates = Object.freeze({
  INVITED: 1,
  ACCEPTED: 2,
} as const);

export const AggregationIntervals = Object.freeze({
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
} as const);

export const GuildAnalyticsAggregationIntervals = Object.freeze({
  HOURLY: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
} as const);

export const getGuildAnalyticsAggregationIntervalOptions = () => [
  // TODO: uncomment hourly to re-enable
  // {label: Messages.Analytics.HOURLY, value: GuildAnalyticsAggregationIntervals.HOURLY},
  {label: Messages.Analytics.DAILY, value: GuildAnalyticsAggregationIntervals.DAILY},
  {label: Messages.Analytics.WEEKLY, value: GuildAnalyticsAggregationIntervals.WEEKLY},
  {label: Messages.Analytics.MONTHLY, value: GuildAnalyticsAggregationIntervals.MONTHLY},
];

export const DAILY_DATA_NOTICE_MEMBER_THRESHOLD = 20;
export const GUILD_ANALYTICS_NO_CHANNEL_SELECTED = '0';

export const AnalyticsQueryTypes = Object.freeze({
  BY_INTERVAL: 0,
  BY_COUNTRY: 1,
  BY_SKU: 2,
} as const);

export const PayoutStatusTypes = Object.freeze({
  OPEN: 1,
  PAID: 2,
  PENDING: 3,
  MANUAL: 4,
  CANCELED: 5,
} as const);

export const getPayoutStatusLabels = () => {
  return {
    [PayoutStatusTypes.OPEN]: Messages.PayoutHistory.OPEN,
    [PayoutStatusTypes.PAID]: Messages.PayoutHistory.PAID,
    [PayoutStatusTypes.PENDING]: Messages.PayoutHistory.PENDING,
    [PayoutStatusTypes.MANUAL]: Messages.PayoutHistory.MANUAL,
    [PayoutStatusTypes.CANCELED]: Messages.PayoutHistory.CANCELED,
  };
};

export const DISCORD_APPLICATION_ID = '545364944258990091';
export const APPLICATION_LICENSE_SKU_ID = '545380566489497610';

export const HAS_VIEWED_CHECKLIST_KEY = 'developersHasViewedChecklist';

export const ALERT_MOUNT_POINT_ID = 'alert-mount-point-id';

export enum GuildFeatures {
  INVITE_SPLASH = 'INVITE_SPLASH',
  VIP_REGIONS = 'VIP_REGIONS',
  VANITY_URL = 'VANITY_URL',
  MORE_EMOJI = 'MORE_EMOJI',
  MORE_STICKERS = 'MORE_STICKERS',
  MORE_SOUNDBOARD = 'MORE_SOUNDBOARD',
  VERIFIED = 'VERIFIED',
  PARTNERED = 'PARTNERED',
  PUBLIC = 'PUBLIC',
  COMMERCE = 'COMMERCE',
  NEWS = 'NEWS',
  DISCOVERABLE = 'DISCOVERABLE',
  FEATURABLE = 'FEATURABLE',
  ANIMATED_ICON = 'ANIMATED_ICON',
  BANNER = 'BANNER',
  COMMUNITY = 'COMMUNITY',
  ENABLED_DISCOVERABLE_BEFORE = 'ENABLED_DISCOVERABLE_BEFORE',
  WELCOME_SCREEN_ENABLED = 'WELCOME_SCREEN_ENABLED',
  MEMBER_VERIFICATION_GATE_ENABLED = 'MEMBER_VERIFICATION_GATE_ENABLED',
  PREVIEW_ENABLED = 'PREVIEW_ENABLED',
  ROLE_SUBSCRIPTIONS_ENABLED = 'ROLE_SUBSCRIPTIONS_ENABLED',
  GUILD_ONBOARDING = 'GUILD_ONBOARDING',
  GUILD_ONBOARDING_EVER_ENABLED = 'GUILD_ONBOARDING_EVER_ENABLED',
}

export enum CardGridTypes {
  SMALL_CARD = 1,
  LARGE_CARD = 2,
}

export enum CardGridSortOrder {
  ALPHABETICAL = 0,
  DATE_CREATED = 1,
}

export const GuildAnalyticsPages = {
  GROWTH_ACTIVATION: 'growthActivation',
  ENGAGEMENT: 'engagement',
  AUDIENCE: 'audience',
  CHANNEL_FOLLOWING: 'channelFollowing',
  WELCOME_SCREEN: 'welcomeScreen',
  PARTNER: 'partner',
  ROLE_SUBSCRIPTIONS: 'roleSubscriptions',
  PROJECT_TETRIS: 'projectTetris',
};

export const ApplicationVerificationStates = {
  INELIGIBLE: 1,
  UNSUBMITTED: 2,
  SUBMITTED: 3,
  APPROVED_MANUALLY: 4,
  BLOCKED: 5,
  APPROVED_AUTOMATICALLY: 6,
};

export const ApplicationCommandTypes = {
  CHAT: 1,
  USER: 2,
  MESSAGE: 3,
};

export const HiddenOAuth2Scopes = new Set([OAuth2Scopes.APPLICATIONS_COMMANDS_UPDATE]);

export {
  Colors,
  CurrencyCodes,
  LocalizedLinks,
  NOOP,
  Permissions,
  SKUFeatureTypes,
  StatusTypes,
} from '@discordapp/common/Constants';

export enum NoticeTypes {
  SURVEY_NAG = 'SURVEY_NAG',
}

export const DAY_OF_WEEK_ORDER = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;
export type DayOfWeek = (typeof DAY_OF_WEEK_ORDER)[number];

export const urlBasename = '/developers';

// Allow applying for intent approval here
export const MIN_BOT_GUILDS_APPLICATION_INTENT_VERIFICATION = 75;
// App guild install will fail above this number
export const MAX_BOT_GUILDS_APPLICATION_INTENT_VERIFICATION = 100;

export const CHECKERS_IN_THE_PARK_APP_ID = '832013003968348200';
export const PUTT_PARTY_APPLICATION_ID = '945737671223947305';
export const CHEF_SHOWDOWN_APPLICATION_ID = '1037680572660727838';
export const CHEF_SHOWDOWN_STG_APPLICATION_ID = '1068582065370574878';
export const CHEF_SHOWDOWN_DEV_APPLICATION_ID = '1068580404434567278';
export const RYTHM_APPLICATION_ID = '235088799074484224';
export const GARTIC_PHONE_APPLICATION_ID = '1007373802981822582';
export const GARTIC_PHONE_DEV_APPLICATION_ID = '1067803140071620628';
export const DEATH_BY_AI_APPLICATION_ID = '1194351737264406548';
export const TINY_ROYALE_APP_1 = '1245041113882955918';
export const TINY_ROYALE_APP_2 = '1245040907732910201';
export const TINY_ROYALE_APP_3 = '1245040436427489471';
export const TINY_ROYALE_APP_4 = '1208090049871351890';
export const PAPERY_MATCH_APPLICATION_ID = '1235205910863806484';
export const EXORACER_APPLICATION_ID = '1220052815590723654';
export const CLASH_GEM_CARD_PARTY_APPLICATION_ID = '1222080278672179220';
export const MAGIC_CIRCLE_APPLICATION_ID = '1227719606223765687';

export const EXCLUDE_FULL_SCREEN_APPS = [
  CHEF_SHOWDOWN_APPLICATION_ID,
  CHEF_SHOWDOWN_DEV_APPLICATION_ID,
  CHEF_SHOWDOWN_STG_APPLICATION_ID,
  RYTHM_APPLICATION_ID,
  GARTIC_PHONE_APPLICATION_ID,
  GARTIC_PHONE_DEV_APPLICATION_ID,
  DEATH_BY_AI_APPLICATION_ID,
  TINY_ROYALE_APP_1,
  TINY_ROYALE_APP_2,
  TINY_ROYALE_APP_3,
  TINY_ROYALE_APP_4,
  // app pitches
  PAPERY_MATCH_APPLICATION_ID,
  EXORACER_APPLICATION_ID,
  CLASH_GEM_CARD_PARTY_APPLICATION_ID,
  MAGIC_CIRCLE_APPLICATION_ID,
];
