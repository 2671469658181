/*
  THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
  If you need to add or edit existing variables, check out
  discord_common/js/packages/tokens/definitions, then run
  `clyde mana tokens generate`
*/

export enum Color {
  APPLICATION_SUBSCRIPTION_END = 'application-subscription-end',
  APPLICATION_SUBSCRIPTION_START = 'application-subscription-start',
  BATTLENET = 'battlenet',
  BG_GRADIENT_AURORA_1 = 'bg-gradient-aurora-1',
  BG_GRADIENT_AURORA_2 = 'bg-gradient-aurora-2',
  BG_GRADIENT_AURORA_3 = 'bg-gradient-aurora-3',
  BG_GRADIENT_AURORA_4 = 'bg-gradient-aurora-4',
  BG_GRADIENT_AURORA_5 = 'bg-gradient-aurora-5',
  BG_GRADIENT_BLURPLE_TWILIGHT_1 = 'bg-gradient-blurple-twilight-1',
  BG_GRADIENT_BLURPLE_TWILIGHT_2 = 'bg-gradient-blurple-twilight-2',
  BG_GRADIENT_CHROMA_GLOW_1 = 'bg-gradient-chroma-glow-1',
  BG_GRADIENT_CHROMA_GLOW_2 = 'bg-gradient-chroma-glow-2',
  BG_GRADIENT_CHROMA_GLOW_3 = 'bg-gradient-chroma-glow-3',
  BG_GRADIENT_CHROMA_GLOW_4 = 'bg-gradient-chroma-glow-4',
  BG_GRADIENT_CHROMA_GLOW_5 = 'bg-gradient-chroma-glow-5',
  BG_GRADIENT_CITRUS_SHERBERT_1 = 'bg-gradient-citrus-sherbert-1',
  BG_GRADIENT_CITRUS_SHERBERT_2 = 'bg-gradient-citrus-sherbert-2',
  BG_GRADIENT_COTTON_CANDY_1 = 'bg-gradient-cotton-candy-1',
  BG_GRADIENT_COTTON_CANDY_2 = 'bg-gradient-cotton-candy-2',
  BG_GRADIENT_CRIMSON_MOON_1 = 'bg-gradient-crimson-moon-1',
  BG_GRADIENT_CRIMSON_MOON_2 = 'bg-gradient-crimson-moon-2',
  BG_GRADIENT_DESERT_KHAKI_1 = 'bg-gradient-desert-khaki-1',
  BG_GRADIENT_DESERT_KHAKI_2 = 'bg-gradient-desert-khaki-2',
  BG_GRADIENT_DESERT_KHAKI_3 = 'bg-gradient-desert-khaki-3',
  BG_GRADIENT_DUSK_1 = 'bg-gradient-dusk-1',
  BG_GRADIENT_DUSK_2 = 'bg-gradient-dusk-2',
  BG_GRADIENT_EASTER_EGG_1 = 'bg-gradient-easter-egg-1',
  BG_GRADIENT_EASTER_EGG_2 = 'bg-gradient-easter-egg-2',
  BG_GRADIENT_FOREST_1 = 'bg-gradient-forest-1',
  BG_GRADIENT_FOREST_2 = 'bg-gradient-forest-2',
  BG_GRADIENT_FOREST_3 = 'bg-gradient-forest-3',
  BG_GRADIENT_FOREST_4 = 'bg-gradient-forest-4',
  BG_GRADIENT_FOREST_5 = 'bg-gradient-forest-5',
  BG_GRADIENT_HANAMI_1 = 'bg-gradient-hanami-1',
  BG_GRADIENT_HANAMI_2 = 'bg-gradient-hanami-2',
  BG_GRADIENT_HANAMI_3 = 'bg-gradient-hanami-3',
  BG_GRADIENT_LOFI_VIBES_1 = 'bg-gradient-lofi-vibes-1',
  BG_GRADIENT_LOFI_VIBES_2 = 'bg-gradient-lofi-vibes-2',
  BG_GRADIENT_LOFI_VIBES_3 = 'bg-gradient-lofi-vibes-3',
  BG_GRADIENT_LOFI_VIBES_4 = 'bg-gradient-lofi-vibes-4',
  BG_GRADIENT_MARS_1 = 'bg-gradient-mars-1',
  BG_GRADIENT_MARS_2 = 'bg-gradient-mars-2',
  BG_GRADIENT_MIDNIGHT_BLURPLE_1 = 'bg-gradient-midnight-blurple-1',
  BG_GRADIENT_MIDNIGHT_BLURPLE_2 = 'bg-gradient-midnight-blurple-2',
  BG_GRADIENT_MINT_APPLE_1 = 'bg-gradient-mint-apple-1',
  BG_GRADIENT_MINT_APPLE_2 = 'bg-gradient-mint-apple-2',
  BG_GRADIENT_MINT_APPLE_3 = 'bg-gradient-mint-apple-3',
  BG_GRADIENT_NEON_NIGHTS_1 = 'bg-gradient-neon-nights-1',
  BG_GRADIENT_NEON_NIGHTS_2 = 'bg-gradient-neon-nights-2',
  BG_GRADIENT_NEON_NIGHTS_3 = 'bg-gradient-neon-nights-3',
  BG_GRADIENT_RETRO_RAINCLOUD_1 = 'bg-gradient-retro-raincloud-1',
  BG_GRADIENT_RETRO_RAINCLOUD_2 = 'bg-gradient-retro-raincloud-2',
  BG_GRADIENT_RETRO_STORM_1 = 'bg-gradient-retro-storm-1',
  BG_GRADIENT_RETRO_STORM_2 = 'bg-gradient-retro-storm-2',
  BG_GRADIENT_SEPIA_1 = 'bg-gradient-sepia-1',
  BG_GRADIENT_SEPIA_2 = 'bg-gradient-sepia-2',
  BG_GRADIENT_STRAWBERRY_LEMONADE_1 = 'bg-gradient-strawberry-lemonade-1',
  BG_GRADIENT_STRAWBERRY_LEMONADE_2 = 'bg-gradient-strawberry-lemonade-2',
  BG_GRADIENT_STRAWBERRY_LEMONADE_3 = 'bg-gradient-strawberry-lemonade-3',
  BG_GRADIENT_SUNRISE_1 = 'bg-gradient-sunrise-1',
  BG_GRADIENT_SUNRISE_2 = 'bg-gradient-sunrise-2',
  BG_GRADIENT_SUNRISE_3 = 'bg-gradient-sunrise-3',
  BG_GRADIENT_SUNSET_1 = 'bg-gradient-sunset-1',
  BG_GRADIENT_SUNSET_2 = 'bg-gradient-sunset-2',
  BG_GRADIENT_UNDER_THE_SEA_1 = 'bg-gradient-under-the-sea-1',
  BG_GRADIENT_UNDER_THE_SEA_2 = 'bg-gradient-under-the-sea-2',
  BG_GRADIENT_UNDER_THE_SEA_3 = 'bg-gradient-under-the-sea-3',
  BLACK = 'black',
  BLACK_100 = 'black-100',
  BLACK_130 = 'black-130',
  BLACK_160 = 'black-160',
  BLACK_200 = 'black-200',
  BLACK_230 = 'black-230',
  BLACK_260 = 'black-260',
  BLACK_300 = 'black-300',
  BLACK_330 = 'black-330',
  BLACK_345 = 'black-345',
  BLACK_360 = 'black-360',
  BLACK_400 = 'black-400',
  BLACK_430 = 'black-430',
  BLACK_460 = 'black-460',
  BLACK_500 = 'black-500',
  BLACK_530 = 'black-530',
  BLACK_560 = 'black-560',
  BLACK_600 = 'black-600',
  BLACK_630 = 'black-630',
  BLACK_660 = 'black-660',
  BLACK_700 = 'black-700',
  BLACK_730 = 'black-730',
  BLACK_760 = 'black-760',
  BLACK_800 = 'black-800',
  BLACK_830 = 'black-830',
  BLACK_860 = 'black-860',
  BLACK_900 = 'black-900',
  BLUE_100 = 'blue-100',
  BLUE_130 = 'blue-130',
  BLUE_160 = 'blue-160',
  BLUE_200 = 'blue-200',
  BLUE_230 = 'blue-230',
  BLUE_260 = 'blue-260',
  BLUE_300 = 'blue-300',
  BLUE_330 = 'blue-330',
  BLUE_345 = 'blue-345',
  BLUE_360 = 'blue-360',
  BLUE_400 = 'blue-400',
  BLUE_430 = 'blue-430',
  BLUE_460 = 'blue-460',
  BLUE_500 = 'blue-500',
  BLUE_530 = 'blue-530',
  BLUE_560 = 'blue-560',
  BLUE_600 = 'blue-600',
  BLUE_630 = 'blue-630',
  BLUE_660 = 'blue-660',
  BLUE_700 = 'blue-700',
  BLUE_730 = 'blue-730',
  BLUE_760 = 'blue-760',
  BLUE_800 = 'blue-800',
  BLUE_830 = 'blue-830',
  BLUE_860 = 'blue-860',
  BLUE_900 = 'blue-900',
  BRAND_100 = 'brand-100',
  BRAND_130 = 'brand-130',
  BRAND_160 = 'brand-160',
  BRAND_200 = 'brand-200',
  BRAND_230 = 'brand-230',
  BRAND_260 = 'brand-260',
  BRAND_300 = 'brand-300',
  BRAND_330 = 'brand-330',
  BRAND_345 = 'brand-345',
  BRAND_360 = 'brand-360',
  BRAND_400 = 'brand-400',
  BRAND_430 = 'brand-430',
  BRAND_460 = 'brand-460',
  BRAND_500 = 'brand-500',
  BRAND_530 = 'brand-530',
  BRAND_560 = 'brand-560',
  BRAND_600 = 'brand-600',
  BRAND_630 = 'brand-630',
  BRAND_660 = 'brand-660',
  BRAND_700 = 'brand-700',
  BRAND_730 = 'brand-730',
  BRAND_760 = 'brand-760',
  BRAND_800 = 'brand-800',
  BRAND_830 = 'brand-830',
  BRAND_860 = 'brand-860',
  BRAND_900 = 'brand-900',
  BUNGIE = 'bungie',
  CRUNCHYROLL = 'crunchyroll',
  EBAY = 'ebay',
  EPIC_GAMES = 'epic-games',
  FACEBOOK = 'facebook',
  GITHUB = 'github',
  GOLD = 'gold',
  GREEN_100 = 'green-100',
  GREEN_130 = 'green-130',
  GREEN_160 = 'green-160',
  GREEN_200 = 'green-200',
  GREEN_230 = 'green-230',
  GREEN_260 = 'green-260',
  GREEN_300 = 'green-300',
  GREEN_330 = 'green-330',
  GREEN_345 = 'green-345',
  GREEN_360 = 'green-360',
  GREEN_400 = 'green-400',
  GREEN_430 = 'green-430',
  GREEN_460 = 'green-460',
  GREEN_500 = 'green-500',
  GREEN_530 = 'green-530',
  GREEN_560 = 'green-560',
  GREEN_600 = 'green-600',
  GREEN_630 = 'green-630',
  GREEN_660 = 'green-660',
  GREEN_700 = 'green-700',
  GREEN_730 = 'green-730',
  GREEN_760 = 'green-760',
  GREEN_800 = 'green-800',
  GREEN_830 = 'green-830',
  GREEN_860 = 'green-860',
  GREEN_900 = 'green-900',
  GUILD_BOOSTING_BLUE = 'guild-boosting-blue',
  GUILD_BOOSTING_BLUE_FOR_GRADIENTS = 'guild-boosting-blue-for-gradients',
  GUILD_BOOSTING_PINK = 'guild-boosting-pink',
  GUILD_BOOSTING_PURPLE = 'guild-boosting-purple',
  GUILD_BOOSTING_PURPLE_FOR_GRADIENTS = 'guild-boosting-purple-for-gradients',
  HYPESQUAD_HOUSE_1 = 'hypesquad-house-1',
  HYPESQUAD_HOUSE_2 = 'hypesquad-house-2',
  HYPESQUAD_HOUSE_3 = 'hypesquad-house-3',
  INPUT_PLACEHOLDER_TEXT_DARK = 'input-placeholder-text-dark',
  LOL = 'lol',
  ORANGE_100 = 'orange-100',
  ORANGE_130 = 'orange-130',
  ORANGE_160 = 'orange-160',
  ORANGE_200 = 'orange-200',
  ORANGE_230 = 'orange-230',
  ORANGE_260 = 'orange-260',
  ORANGE_300 = 'orange-300',
  ORANGE_330 = 'orange-330',
  ORANGE_345 = 'orange-345',
  ORANGE_360 = 'orange-360',
  ORANGE_400 = 'orange-400',
  ORANGE_430 = 'orange-430',
  ORANGE_460 = 'orange-460',
  ORANGE_500 = 'orange-500',
  ORANGE_530 = 'orange-530',
  ORANGE_560 = 'orange-560',
  ORANGE_600 = 'orange-600',
  ORANGE_630 = 'orange-630',
  ORANGE_660 = 'orange-660',
  ORANGE_700 = 'orange-700',
  ORANGE_730 = 'orange-730',
  ORANGE_760 = 'orange-760',
  ORANGE_800 = 'orange-800',
  ORANGE_830 = 'orange-830',
  ORANGE_860 = 'orange-860',
  ORANGE_900 = 'orange-900',
  PARTNER = 'partner',
  PAYPAL = 'paypal',
  PLAYSTATION = 'playstation',
  PLUM_23_ALPHA = 'plum-23-alpha',
  PLUM_0 = 'plum-0',
  PLUM_1 = 'plum-1',
  PLUM_10 = 'plum-10',
  PLUM_11 = 'plum-11',
  PLUM_12 = 'plum-12',
  PLUM_13 = 'plum-13',
  PLUM_14 = 'plum-14',
  PLUM_15 = 'plum-15',
  PLUM_16 = 'plum-16',
  PLUM_17 = 'plum-17',
  PLUM_18 = 'plum-18',
  PLUM_19 = 'plum-19',
  PLUM_2 = 'plum-2',
  PLUM_20 = 'plum-20',
  PLUM_21 = 'plum-21',
  PLUM_22 = 'plum-22',
  PLUM_23 = 'plum-23',
  PLUM_24 = 'plum-24',
  PLUM_25 = 'plum-25',
  PLUM_26 = 'plum-26',
  PLUM_3 = 'plum-3',
  PLUM_4 = 'plum-4',
  PLUM_5 = 'plum-5',
  PLUM_6 = 'plum-6',
  PLUM_7 = 'plum-7',
  PLUM_8 = 'plum-8',
  PLUM_9 = 'plum-9',
  PREMIUM_NITRO_PINK_DARK = 'premium-nitro-pink-dark',
  PREMIUM_NITRO_PINK_LIGHT = 'premium-nitro-pink-light',
  PREMIUM_PERK_BLUE = 'premium-perk-blue',
  PREMIUM_PERK_BLUE_ALT = 'premium-perk-blue-alt',
  PREMIUM_PERK_DARK_BLUE = 'premium-perk-dark-blue',
  PREMIUM_PERK_GOLD = 'premium-perk-gold',
  PREMIUM_PERK_GREEN = 'premium-perk-green',
  PREMIUM_PERK_LIGHT_BLUE = 'premium-perk-light-blue',
  PREMIUM_PERK_ORANGE = 'premium-perk-orange',
  PREMIUM_PERK_PINK = 'premium-perk-pink',
  PREMIUM_PERK_PURPLE = 'premium-perk-purple',
  PREMIUM_PERK_YELLOW = 'premium-perk-yellow',
  PREMIUM_TIER_0_BLUE = 'premium-tier-0-blue',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS = 'premium-tier-0-blue-for-gradients',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS_2 = 'premium-tier-0-blue-for-gradients-2',
  PREMIUM_TIER_0_HEADER_GRADIENT_1 = 'premium-tier-0-header-gradient-1',
  PREMIUM_TIER_0_HEADER_GRADIENT_2 = 'premium-tier-0-header-gradient-2',
  PREMIUM_TIER_0_HEADER_GRADIENT_3 = 'premium-tier-0-header-gradient-3',
  PREMIUM_TIER_0_HEADER_GRADIENT_4 = 'premium-tier-0-header-gradient-4',
  PREMIUM_TIER_0_HEADER_GRADIENT_5 = 'premium-tier-0-header-gradient-5',
  PREMIUM_TIER_0_PURPLE = 'premium-tier-0-purple',
  PREMIUM_TIER_0_PURPLE_FOR_GRADIENTS = 'premium-tier-0-purple-for-gradients',
  PREMIUM_TIER_1_BLUE = 'premium-tier-1-blue',
  PREMIUM_TIER_1_BLUE_FOR_GRADIENTS = 'premium-tier-1-blue-for-gradients',
  PREMIUM_TIER_1_DARK_BLUE_FOR_GRADIENTS = 'premium-tier-1-dark-blue-for-gradients',
  PREMIUM_TIER_1_PURPLE = 'premium-tier-1-purple',
  PREMIUM_TIER_2_PINK = 'premium-tier-2-pink',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS = 'premium-tier-2-pink-for-gradients',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS_2 = 'premium-tier-2-pink-for-gradients-2',
  PREMIUM_TIER_2_PURPLE = 'premium-tier-2-purple',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS = 'premium-tier-2-purple-for-gradients',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS_2 = 'premium-tier-2-purple-for-gradients-2',
  PRIMARY_100 = 'primary-100',
  PRIMARY_130 = 'primary-130',
  PRIMARY_160 = 'primary-160',
  PRIMARY_200 = 'primary-200',
  PRIMARY_230 = 'primary-230',
  PRIMARY_260 = 'primary-260',
  PRIMARY_300 = 'primary-300',
  PRIMARY_330 = 'primary-330',
  PRIMARY_345 = 'primary-345',
  PRIMARY_360 = 'primary-360',
  PRIMARY_400 = 'primary-400',
  PRIMARY_430 = 'primary-430',
  PRIMARY_460 = 'primary-460',
  PRIMARY_500 = 'primary-500',
  PRIMARY_530 = 'primary-530',
  PRIMARY_560 = 'primary-560',
  PRIMARY_600 = 'primary-600',
  PRIMARY_630 = 'primary-630',
  PRIMARY_645 = 'primary-645',
  PRIMARY_660 = 'primary-660',
  PRIMARY_700 = 'primary-700',
  PRIMARY_730 = 'primary-730',
  PRIMARY_760 = 'primary-760',
  PRIMARY_800 = 'primary-800',
  PRIMARY_830 = 'primary-830',
  PRIMARY_860 = 'primary-860',
  PRIMARY_900 = 'primary-900',
  RED_100 = 'red-100',
  RED_130 = 'red-130',
  RED_160 = 'red-160',
  RED_200 = 'red-200',
  RED_230 = 'red-230',
  RED_260 = 'red-260',
  RED_300 = 'red-300',
  RED_330 = 'red-330',
  RED_345 = 'red-345',
  RED_360 = 'red-360',
  RED_400 = 'red-400',
  RED_430 = 'red-430',
  RED_460 = 'red-460',
  RED_500 = 'red-500',
  RED_530 = 'red-530',
  RED_560 = 'red-560',
  RED_600 = 'red-600',
  RED_630 = 'red-630',
  RED_660 = 'red-660',
  RED_700 = 'red-700',
  RED_730 = 'red-730',
  RED_760 = 'red-760',
  RED_800 = 'red-800',
  RED_830 = 'red-830',
  RED_860 = 'red-860',
  RED_900 = 'red-900',
  REDDIT = 'reddit',
  RIOT_GAMES = 'riot-games',
  ROLE_BLUE = 'role-blue',
  ROLE_BROWN = 'role-brown',
  ROLE_BURGUNDY = 'role-burgundy',
  ROLE_DARK_BLUE = 'role-dark-blue',
  ROLE_DARK_GREY = 'role-dark-grey',
  ROLE_DARK_PURPLE = 'role-dark-purple',
  ROLE_DARK_TEAL = 'role-dark-teal',
  ROLE_DEFAULT = 'role-default',
  ROLE_GREEN = 'role-green',
  ROLE_GREY = 'role-grey',
  ROLE_LIGHT_BLUE = 'role-light-blue',
  ROLE_LIGHT_GREEN = 'role-light-green',
  ROLE_LIGHT_GREY = 'role-light-grey',
  ROLE_MAGENTA = 'role-magenta',
  ROLE_ORANGE = 'role-orange',
  ROLE_PURPLE = 'role-purple',
  ROLE_SALMON = 'role-salmon',
  ROLE_SKY_BLUE = 'role-sky-blue',
  ROLE_TAN = 'role-tan',
  ROLE_TEAL = 'role-teal',
  ROLE_TERRACOTTA = 'role-terracotta',
  ROLE_YELLOW = 'role-yellow',
  SAMSUNG = 'samsung',
  SKYPE = 'skype',
  SPOTIFY = 'spotify',
  STEAM = 'steam',
  TEAL_100 = 'teal-100',
  TEAL_130 = 'teal-130',
  TEAL_160 = 'teal-160',
  TEAL_200 = 'teal-200',
  TEAL_230 = 'teal-230',
  TEAL_260 = 'teal-260',
  TEAL_300 = 'teal-300',
  TEAL_330 = 'teal-330',
  TEAL_345 = 'teal-345',
  TEAL_360 = 'teal-360',
  TEAL_400 = 'teal-400',
  TEAL_430 = 'teal-430',
  TEAL_460 = 'teal-460',
  TEAL_500 = 'teal-500',
  TEAL_530 = 'teal-530',
  TEAL_560 = 'teal-560',
  TEAL_600 = 'teal-600',
  TEAL_630 = 'teal-630',
  TEAL_660 = 'teal-660',
  TEAL_700 = 'teal-700',
  TEAL_730 = 'teal-730',
  TEAL_760 = 'teal-760',
  TEAL_800 = 'teal-800',
  TEAL_830 = 'teal-830',
  TEAL_860 = 'teal-860',
  TEAL_900 = 'teal-900',
  TRANSPARENT = 'transparent',
  TWITCH = 'twitch',
  TWITTER = 'twitter',
  WHITE = 'white',
  WHITE_100 = 'white-100',
  WHITE_130 = 'white-130',
  WHITE_160 = 'white-160',
  WHITE_200 = 'white-200',
  WHITE_230 = 'white-230',
  WHITE_260 = 'white-260',
  WHITE_300 = 'white-300',
  WHITE_330 = 'white-330',
  WHITE_345 = 'white-345',
  WHITE_360 = 'white-360',
  WHITE_400 = 'white-400',
  WHITE_430 = 'white-430',
  WHITE_460 = 'white-460',
  WHITE_500 = 'white-500',
  WHITE_530 = 'white-530',
  WHITE_560 = 'white-560',
  WHITE_600 = 'white-600',
  WHITE_630 = 'white-630',
  WHITE_660 = 'white-660',
  WHITE_700 = 'white-700',
  WHITE_730 = 'white-730',
  WHITE_760 = 'white-760',
  WHITE_800 = 'white-800',
  WHITE_830 = 'white-830',
  WHITE_860 = 'white-860',
  WHITE_900 = 'white-900',
  XBOX = 'xbox',
  YELLOW_100 = 'yellow-100',
  YELLOW_130 = 'yellow-130',
  YELLOW_160 = 'yellow-160',
  YELLOW_200 = 'yellow-200',
  YELLOW_230 = 'yellow-230',
  YELLOW_260 = 'yellow-260',
  YELLOW_300 = 'yellow-300',
  YELLOW_330 = 'yellow-330',
  YELLOW_345 = 'yellow-345',
  YELLOW_360 = 'yellow-360',
  YELLOW_400 = 'yellow-400',
  YELLOW_430 = 'yellow-430',
  YELLOW_460 = 'yellow-460',
  YELLOW_500 = 'yellow-500',
  YELLOW_530 = 'yellow-530',
  YELLOW_560 = 'yellow-560',
  YELLOW_600 = 'yellow-600',
  YELLOW_630 = 'yellow-630',
  YELLOW_660 = 'yellow-660',
  YELLOW_700 = 'yellow-700',
  YELLOW_730 = 'yellow-730',
  YELLOW_760 = 'yellow-760',
  YELLOW_800 = 'yellow-800',
  YELLOW_830 = 'yellow-830',
  YELLOW_860 = 'yellow-860',
  YELLOW_900 = 'yellow-900',
  YOUTUBE = 'youtube',
}

export const ColorDetails = {
  [Color.APPLICATION_SUBSCRIPTION_END]: {hex: '#0177a4'},
  [Color.APPLICATION_SUBSCRIPTION_START]: {hex: '#3442d9'},
  [Color.BATTLENET]: {hex: '#009ae5'},
  [Color.BG_GRADIENT_AURORA_1]: {hex: '#062053'},
  [Color.BG_GRADIENT_AURORA_2]: {hex: '#191fbb'},
  [Color.BG_GRADIENT_AURORA_3]: {hex: '#13929a'},
  [Color.BG_GRADIENT_AURORA_4]: {hex: '#218573'},
  [Color.BG_GRADIENT_AURORA_5]: {hex: '#051a81'},
  [Color.BG_GRADIENT_BLURPLE_TWILIGHT_1]: {hex: '#2C3FE7'},
  [Color.BG_GRADIENT_BLURPLE_TWILIGHT_2]: {hex: '#261D83'},
  [Color.BG_GRADIENT_CHROMA_GLOW_1]: {hex: '#0eb5bf'},
  [Color.BG_GRADIENT_CHROMA_GLOW_2]: {hex: '#4c0ce0'},
  [Color.BG_GRADIENT_CHROMA_GLOW_3]: {hex: '#a308a7'},
  [Color.BG_GRADIENT_CHROMA_GLOW_4]: {hex: '#9a53ff'},
  [Color.BG_GRADIENT_CHROMA_GLOW_5]: {hex: '#218be0'},
  [Color.BG_GRADIENT_CITRUS_SHERBERT_1]: {hex: '#f3b336'},
  [Color.BG_GRADIENT_CITRUS_SHERBERT_2]: {hex: '#ee8558'},
  [Color.BG_GRADIENT_COTTON_CANDY_1]: {hex: '#f4abb8'},
  [Color.BG_GRADIENT_COTTON_CANDY_2]: {hex: '#b1c2fc'},
  [Color.BG_GRADIENT_CRIMSON_MOON_1]: {hex: '#950909'},
  [Color.BG_GRADIENT_CRIMSON_MOON_2]: {hex: '#000000'},
  [Color.BG_GRADIENT_DESERT_KHAKI_1]: {hex: '#e7dbd0'},
  [Color.BG_GRADIENT_DESERT_KHAKI_2]: {hex: '#dfd0b2'},
  [Color.BG_GRADIENT_DESERT_KHAKI_3]: {hex: '#e0d6a3'},
  [Color.BG_GRADIENT_DUSK_1]: {hex: '#665069'},
  [Color.BG_GRADIENT_DUSK_2]: {hex: '#91a3d1'},
  [Color.BG_GRADIENT_EASTER_EGG_1]: {hex: '#7289da'},
  [Color.BG_GRADIENT_EASTER_EGG_2]: {hex: '#4e5d94'},
  [Color.BG_GRADIENT_FOREST_1]: {hex: '#142215'},
  [Color.BG_GRADIENT_FOREST_2]: {hex: '#2d4d39'},
  [Color.BG_GRADIENT_FOREST_3]: {hex: '#454c32'},
  [Color.BG_GRADIENT_FOREST_4]: {hex: '#5a7c58'},
  [Color.BG_GRADIENT_FOREST_5]: {hex: '#a98e4b'},
  [Color.BG_GRADIENT_HANAMI_1]: {hex: '#efaab3'},
  [Color.BG_GRADIENT_HANAMI_2]: {hex: '#efd696'},
  [Color.BG_GRADIENT_HANAMI_3]: {hex: '#a6daa2'},
  [Color.BG_GRADIENT_LOFI_VIBES_1]: {hex: '#a4c0f7'},
  [Color.BG_GRADIENT_LOFI_VIBES_2]: {hex: '#a9e4e8'},
  [Color.BG_GRADIENT_LOFI_VIBES_3]: {hex: '#b0e2b8'},
  [Color.BG_GRADIENT_LOFI_VIBES_4]: {hex: '#cfdfa2'},
  [Color.BG_GRADIENT_MARS_1]: {hex: '#895240'},
  [Color.BG_GRADIENT_MARS_2]: {hex: '#8f4343'},
  [Color.BG_GRADIENT_MIDNIGHT_BLURPLE_1]: {hex: '#5348ca'},
  [Color.BG_GRADIENT_MIDNIGHT_BLURPLE_2]: {hex: '#140730'},
  [Color.BG_GRADIENT_MINT_APPLE_1]: {hex: '#56b69f'},
  [Color.BG_GRADIENT_MINT_APPLE_2]: {hex: '#63bc61'},
  [Color.BG_GRADIENT_MINT_APPLE_3]: {hex: '#9eca67'},
  [Color.BG_GRADIENT_NEON_NIGHTS_1]: {hex: '#01a89e'},
  [Color.BG_GRADIENT_NEON_NIGHTS_2]: {hex: '#7d60ba'},
  [Color.BG_GRADIENT_NEON_NIGHTS_3]: {hex: '#b43898'},
  [Color.BG_GRADIENT_RETRO_RAINCLOUD_1]: {hex: '#3a7ca1'},
  [Color.BG_GRADIENT_RETRO_RAINCLOUD_2]: {hex: '#7f7eb9'},
  [Color.BG_GRADIENT_RETRO_STORM_1]: {hex: '#3a7ca1'},
  [Color.BG_GRADIENT_RETRO_STORM_2]: {hex: '#58579a'},
  [Color.BG_GRADIENT_SEPIA_1]: {hex: '#857664'},
  [Color.BG_GRADIENT_SEPIA_2]: {hex: '#5b4421'},
  [Color.BG_GRADIENT_STRAWBERRY_LEMONADE_1]: {hex: '#af1a6c'},
  [Color.BG_GRADIENT_STRAWBERRY_LEMONADE_2]: {hex: '#c26b20'},
  [Color.BG_GRADIENT_STRAWBERRY_LEMONADE_3]: {hex: '#e7a525'},
  [Color.BG_GRADIENT_SUNRISE_1]: {hex: '#9f4175'},
  [Color.BG_GRADIENT_SUNRISE_2]: {hex: '#c49064'},
  [Color.BG_GRADIENT_SUNRISE_3]: {hex: '#a6953d'},
  [Color.BG_GRADIENT_SUNSET_1]: {hex: '#48288c'},
  [Color.BG_GRADIENT_SUNSET_2]: {hex: '#db7f4b'},
  [Color.BG_GRADIENT_UNDER_THE_SEA_1]: {hex: '#647962'},
  [Color.BG_GRADIENT_UNDER_THE_SEA_2]: {hex: '#588575'},
  [Color.BG_GRADIENT_UNDER_THE_SEA_3]: {hex: '#6a8482'},
  [Color.BLACK]: {hex: '#000000'},
  [Color.BLACK_100]: {hex: '#f2f2f2'},
  [Color.BLACK_130]: {hex: '#e8e8e8'},
  [Color.BLACK_160]: {hex: '#dadada'},
  [Color.BLACK_200]: {hex: '#cccccc'},
  [Color.BLACK_230]: {hex: '#bdbdbd'},
  [Color.BLACK_260]: {hex: '#acacac'},
  [Color.BLACK_300]: {hex: '#999999'},
  [Color.BLACK_330]: {hex: '#7a7a7a'},
  [Color.BLACK_345]: {hex: '#666666'},
  [Color.BLACK_360]: {hex: '#5c5c5c'},
  [Color.BLACK_400]: {hex: '#333333'},
  [Color.BLACK_430]: {hex: '#252525'},
  [Color.BLACK_460]: {hex: '#141414'},
  [Color.BLACK_500]: {hex: '#000000'},
  [Color.BLACK_530]: {hex: '#000000'},
  [Color.BLACK_560]: {hex: '#000000'},
  [Color.BLACK_600]: {hex: '#000000'},
  [Color.BLACK_630]: {hex: '#000000'},
  [Color.BLACK_660]: {hex: '#000000'},
  [Color.BLACK_700]: {hex: '#000000'},
  [Color.BLACK_730]: {hex: '#000000'},
  [Color.BLACK_760]: {hex: '#000000'},
  [Color.BLACK_800]: {hex: '#000000'},
  [Color.BLACK_830]: {hex: '#000000'},
  [Color.BLACK_860]: {hex: '#000000'},
  [Color.BLACK_900]: {hex: '#000000'},
  [Color.BLUE_100]: {hex: '#f6fafe'},
  [Color.BLUE_130]: {hex: '#f0f7fe'},
  [Color.BLUE_160]: {hex: '#e2f0fd'},
  [Color.BLUE_200]: {hex: '#cde8fd'},
  [Color.BLUE_230]: {hex: '#b2ddfc'},
  [Color.BLUE_260]: {hex: '#94d2fc'},
  [Color.BLUE_300]: {hex: '#66c4fd'},
  [Color.BLUE_330]: {hex: '#2eb6ff'},
  [Color.BLUE_345]: {hex: '#00aafc'},
  [Color.BLUE_360]: {hex: '#0097f2'},
  [Color.BLUE_400]: {hex: '#0082eb'},
  [Color.BLUE_430]: {hex: '#006be7'},
  [Color.BLUE_460]: {hex: '#005cd1'},
  [Color.BLUE_500]: {hex: '#0051b6'},
  [Color.BLUE_530]: {hex: '#00489b'},
  [Color.BLUE_560]: {hex: '#004088'},
  [Color.BLUE_600]: {hex: '#003976'},
  [Color.BLUE_630]: {hex: '#00336a'},
  [Color.BLUE_660]: {hex: '#002d5f'},
  [Color.BLUE_700]: {hex: '#002855'},
  [Color.BLUE_730]: {hex: '#002348'},
  [Color.BLUE_760]: {hex: '#001e3f'},
  [Color.BLUE_800]: {hex: '#001a36'},
  [Color.BLUE_830]: {hex: '#001630'},
  [Color.BLUE_860]: {hex: '#00132b'},
  [Color.BLUE_900]: {hex: '#001024'},
  [Color.BRAND_100]: {hex: '#f7f7fe'},
  [Color.BRAND_130]: {hex: '#f0f1fe'},
  [Color.BRAND_160]: {hex: '#e7e9fd'},
  [Color.BRAND_200]: {hex: '#dee0fc'},
  [Color.BRAND_230]: {hex: '#d4d7fc'},
  [Color.BRAND_260]: {hex: '#c9cdfb'},
  [Color.BRAND_300]: {hex: '#bcc1fa'},
  [Color.BRAND_330]: {hex: '#a8aff8'},
  [Color.BRAND_345]: {hex: '#9ba3f7'},
  [Color.BRAND_360]: {hex: '#949cf7'},
  [Color.BRAND_400]: {hex: '#7984f5'},
  [Color.BRAND_430]: {hex: '#707bf4'},
  [Color.BRAND_460]: {hex: '#6571f3'},
  [Color.BRAND_500]: {hex: '#5865f2'},
  [Color.BRAND_530]: {hex: '#505cdc'},
  [Color.BRAND_560]: {hex: '#4752c4'},
  [Color.BRAND_600]: {hex: '#3c45a5'},
  [Color.BRAND_630]: {hex: '#343b8f'},
  [Color.BRAND_660]: {hex: '#2d347d'},
  [Color.BRAND_700]: {hex: '#232861'},
  [Color.BRAND_730]: {hex: '#21265b'},
  [Color.BRAND_760]: {hex: '#1e2353'},
  [Color.BRAND_800]: {hex: '#1a1e49'},
  [Color.BRAND_830]: {hex: '#141738'},
  [Color.BRAND_860]: {hex: '#0d0f24'},
  [Color.BRAND_900]: {hex: '#04050c'},
  [Color.BUNGIE]: {hex: '#00a3e3'},
  [Color.CRUNCHYROLL]: {hex: '#f78b24'},
  [Color.EBAY]: {hex: '#0064d2'},
  [Color.EPIC_GAMES]: {hex: '#3c3935'},
  [Color.FACEBOOK]: {hex: '#355089'},
  [Color.GITHUB]: {hex: '#191717'},
  [Color.GOLD]: {hex: '#ffc819'},
  [Color.GREEN_100]: {hex: '#ecfef1'},
  [Color.GREEN_130]: {hex: '#defee7'},
  [Color.GREEN_160]: {hex: '#bdfcd3'},
  [Color.GREEN_200]: {hex: '#88fbb5'},
  [Color.GREEN_230]: {hex: '#58f39c'},
  [Color.GREEN_260]: {hex: '#48e58b'},
  [Color.GREEN_300]: {hex: '#3bd67f'},
  [Color.GREEN_330]: {hex: '#2dc771'},
  [Color.GREEN_345]: {hex: '#26b968'},
  [Color.GREEN_360]: {hex: '#23a55a'},
  [Color.GREEN_400]: {hex: '#24934e'},
  [Color.GREEN_430]: {hex: '#248045'},
  [Color.GREEN_460]: {hex: '#1f703c'},
  [Color.GREEN_500]: {hex: '#1a6334'},
  [Color.GREEN_530]: {hex: '#15562b'},
  [Color.GREEN_560]: {hex: '#124c24'},
  [Color.GREEN_600]: {hex: '#0e431f'},
  [Color.GREEN_630]: {hex: '#0c3c1c'},
  [Color.GREEN_660]: {hex: '#0a3618'},
  [Color.GREEN_700]: {hex: '#072f15'},
  [Color.GREEN_730]: {hex: '#052910'},
  [Color.GREEN_760]: {hex: '#03240e'},
  [Color.GREEN_800]: {hex: '#031f0c'},
  [Color.GREEN_830]: {hex: '#031b0a'},
  [Color.GREEN_860]: {hex: '#041708'},
  [Color.GREEN_900]: {hex: '#051307'},
  [Color.GUILD_BOOSTING_BLUE]: {hex: '#3e70dd'},
  [Color.GUILD_BOOSTING_BLUE_FOR_GRADIENTS]: {hex: '#3442d9'},
  [Color.GUILD_BOOSTING_PINK]: {hex: '#ff73fa'},
  [Color.GUILD_BOOSTING_PURPLE]: {hex: '#b377f3'},
  [Color.GUILD_BOOSTING_PURPLE_FOR_GRADIENTS]: {hex: '#9b44f7'},
  [Color.HYPESQUAD_HOUSE_1]: {hex: '#9c84ef'},
  [Color.HYPESQUAD_HOUSE_2]: {hex: '#f47b67'},
  [Color.HYPESQUAD_HOUSE_3]: {hex: '#45ddc0'},
  [Color.INPUT_PLACEHOLDER_TEXT_DARK]: {hex: '#87898c'},
  [Color.LOL]: {hex: '#021f25'},
  [Color.ORANGE_100]: {hex: '#fff8f5'},
  [Color.ORANGE_130]: {hex: '#fff4ed'},
  [Color.ORANGE_160]: {hex: '#feeadf'},
  [Color.ORANGE_200]: {hex: '#fddecd'},
  [Color.ORANGE_230]: {hex: '#fccfb2'},
  [Color.ORANGE_260]: {hex: '#fcbd95'},
  [Color.ORANGE_300]: {hex: '#fbab70'},
  [Color.ORANGE_330]: {hex: '#fa9746'},
  [Color.ORANGE_345]: {hex: '#f1882a'},
  [Color.ORANGE_360]: {hex: '#db7628'},
  [Color.ORANGE_400]: {hex: '#c46926'},
  [Color.ORANGE_430]: {hex: '#ac591f'},
  [Color.ORANGE_460]: {hex: '#9b4c19'},
  [Color.ORANGE_500]: {hex: '#8c4013'},
  [Color.ORANGE_530]: {hex: '#7b3710'},
  [Color.ORANGE_560]: {hex: '#6d300e'},
  [Color.ORANGE_600]: {hex: '#5f2b0b'},
  [Color.ORANGE_630]: {hex: '#56260a'},
  [Color.ORANGE_660]: {hex: '#4c2209'},
  [Color.ORANGE_700]: {hex: '#431e09'},
  [Color.ORANGE_730]: {hex: '#3b1a07'},
  [Color.ORANGE_760]: {hex: '#331606'},
  [Color.ORANGE_800]: {hex: '#2d1305'},
  [Color.ORANGE_830]: {hex: '#261005'},
  [Color.ORANGE_860]: {hex: '#200e05'},
  [Color.ORANGE_900]: {hex: '#190d04'},
  [Color.PARTNER]: {hex: '#4087ed'},
  [Color.PAYPAL]: {hex: '#13216b'},
  [Color.PLAYSTATION]: {hex: '#032f87'},
  [Color.PLUM_23_ALPHA]: {hex: '#BCBCF0'},
  [Color.PLUM_0]: {hex: '#f9f9fa'},
  [Color.PLUM_1]: {hex: '#f3f3f4'},
  [Color.PLUM_10]: {hex: '#818491'},
  [Color.PLUM_11]: {hex: '#6c6f7c'},
  [Color.PLUM_12]: {hex: '#5b5e6a'},
  [Color.PLUM_13]: {hex: '#4d505b'},
  [Color.PLUM_14]: {hex: '#41434d'},
  [Color.PLUM_15]: {hex: '#383a43'},
  [Color.PLUM_16]: {hex: '#31323b'},
  [Color.PLUM_17]: {hex: '#2c2d35'},
  [Color.PLUM_18]: {hex: '#26272f'},
  [Color.PLUM_19]: {hex: '#212229'},
  [Color.PLUM_2]: {hex: '#ecedef'},
  [Color.PLUM_20]: {hex: '#1c1d23'},
  [Color.PLUM_21]: {hex: '#18191f'},
  [Color.PLUM_22]: {hex: '#16161c'},
  [Color.PLUM_23]: {hex: '#131318'},
  [Color.PLUM_24]: {hex: '#0f1014'},
  [Color.PLUM_25]: {hex: '#0c0c10'},
  [Color.PLUM_26]: {hex: '#08080b'},
  [Color.PLUM_3]: {hex: '#e4e5e8'},
  [Color.PLUM_4]: {hex: '#dddee1'},
  [Color.PLUM_5]: {hex: '#d3d5d9'},
  [Color.PLUM_6]: {hex: '#c7c8ce'},
  [Color.PLUM_7]: {hex: '#b8bac1'},
  [Color.PLUM_8]: {hex: '#a8aab4'},
  [Color.PLUM_9]: {hex: '#9597a3'},
  [Color.PREMIUM_NITRO_PINK_DARK]: {hex: '#b64ab7'},
  [Color.PREMIUM_NITRO_PINK_LIGHT]: {hex: '#c269c3'},
  [Color.PREMIUM_PERK_BLUE]: {hex: '#80a6ff'},
  [Color.PREMIUM_PERK_BLUE_ALT]: {hex: '#9cb8ff'},
  [Color.PREMIUM_PERK_DARK_BLUE]: {hex: '#4173da'},
  [Color.PREMIUM_PERK_GOLD]: {hex: '#faa61a'},
  [Color.PREMIUM_PERK_GREEN]: {hex: '#86dcc5'},
  [Color.PREMIUM_PERK_LIGHT_BLUE]: {hex: '#aec7ff'},
  [Color.PREMIUM_PERK_ORANGE]: {hex: '#fc964b'},
  [Color.PREMIUM_PERK_PINK]: {hex: '#ff80f4'},
  [Color.PREMIUM_PERK_PURPLE]: {hex: '#d09aff'},
  [Color.PREMIUM_PERK_YELLOW]: {hex: '#fed648'},
  [Color.PREMIUM_TIER_0_BLUE]: {hex: '#007cc2'},
  [Color.PREMIUM_TIER_0_BLUE_FOR_GRADIENTS]: {hex: '#007cc2'},
  [Color.PREMIUM_TIER_0_BLUE_FOR_GRADIENTS_2]: {hex: '#1776cf'},
  [Color.PREMIUM_TIER_0_HEADER_GRADIENT_1]: {hex: '#3736bb'},
  [Color.PREMIUM_TIER_0_HEADER_GRADIENT_2]: {hex: '#4670e8'},
  [Color.PREMIUM_TIER_0_HEADER_GRADIENT_3]: {hex: '#8377eb'},
  [Color.PREMIUM_TIER_0_HEADER_GRADIENT_4]: {hex: '#e782f1'},
  [Color.PREMIUM_TIER_0_HEADER_GRADIENT_5]: {hex: '#df90af'},
  [Color.PREMIUM_TIER_0_PURPLE]: {hex: '#5865f2'},
  [Color.PREMIUM_TIER_0_PURPLE_FOR_GRADIENTS]: {hex: '#5865f2'},
  [Color.PREMIUM_TIER_1_BLUE]: {hex: '#738ef5'},
  [Color.PREMIUM_TIER_1_BLUE_FOR_GRADIENTS]: {hex: '#5865f2'},
  [Color.PREMIUM_TIER_1_DARK_BLUE_FOR_GRADIENTS]: {hex: '#3442d9'},
  [Color.PREMIUM_TIER_1_PURPLE]: {hex: '#b3aeff'},
  [Color.PREMIUM_TIER_2_PINK]: {hex: '#e292aa'},
  [Color.PREMIUM_TIER_2_PINK_FOR_GRADIENTS]: {hex: '#ab5d8a'},
  [Color.PREMIUM_TIER_2_PINK_FOR_GRADIENTS_2]: {hex: '#b73ec1'},
  [Color.PREMIUM_TIER_2_PURPLE]: {hex: '#b473f5'},
  [Color.PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS]: {hex: '#8547c6'},
  [Color.PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS_2]: {hex: '#b845c1'},
  [Color.PRIMARY_100]: {hex: '#f9f9f9'},
  [Color.PRIMARY_130]: {hex: '#f2f3f5'},
  [Color.PRIMARY_160]: {hex: '#ebedef'},
  [Color.PRIMARY_200]: {hex: '#e3e5e8'},
  [Color.PRIMARY_230]: {hex: '#dbdee1'},
  [Color.PRIMARY_260]: {hex: '#d2d5d9'},
  [Color.PRIMARY_300]: {hex: '#c4c9ce'},
  [Color.PRIMARY_330]: {hex: '#b5bac1'},
  [Color.PRIMARY_345]: {hex: '#a5abb3'},
  [Color.PRIMARY_360]: {hex: '#949ba4'},
  [Color.PRIMARY_400]: {hex: '#80848e'},
  [Color.PRIMARY_430]: {hex: '#6d6f78'},
  [Color.PRIMARY_460]: {hex: '#5c5e66'},
  [Color.PRIMARY_500]: {hex: '#4e5058'},
  [Color.PRIMARY_530]: {hex: '#41434a'},
  [Color.PRIMARY_560]: {hex: '#383a40'},
  [Color.PRIMARY_600]: {hex: '#313338'},
  [Color.PRIMARY_630]: {hex: '#2b2d31'},
  [Color.PRIMARY_645]: {hex: '#282a2e'},
  [Color.PRIMARY_660]: {hex: '#232428'},
  [Color.PRIMARY_700]: {hex: '#1e1f22'},
  [Color.PRIMARY_730]: {hex: '#1a1b1e'},
  [Color.PRIMARY_760]: {hex: '#161719'},
  [Color.PRIMARY_800]: {hex: '#111214'},
  [Color.PRIMARY_830]: {hex: '#0c0c0d'},
  [Color.PRIMARY_860]: {hex: '#060607'},
  [Color.PRIMARY_900]: {hex: '#020202'},
  [Color.RED_100]: {hex: '#fef8f9'},
  [Color.RED_130]: {hex: '#fef4f4'},
  [Color.RED_160]: {hex: '#feeaea'},
  [Color.RED_200]: {hex: '#fdddde'},
  [Color.RED_230]: {hex: '#fbcdce'},
  [Color.RED_260]: {hex: '#fbbabb'},
  [Color.RED_300]: {hex: '#fba6a8'},
  [Color.RED_330]: {hex: '#fa9193'},
  [Color.RED_345]: {hex: '#fa777b'},
  [Color.RED_360]: {hex: '#f85b5f'},
  [Color.RED_400]: {hex: '#f23f43'},
  [Color.RED_430]: {hex: '#da373c'},
  [Color.RED_460]: {hex: '#bb3033'},
  [Color.RED_500]: {hex: '#a12829'},
  [Color.RED_530]: {hex: '#8f2022'},
  [Color.RED_560]: {hex: '#7f1c1e'},
  [Color.RED_600]: {hex: '#70181a'},
  [Color.RED_630]: {hex: '#651517'},
  [Color.RED_660]: {hex: '#5a1314'},
  [Color.RED_700]: {hex: '#501012'},
  [Color.RED_730]: {hex: '#460e0f'},
  [Color.RED_760]: {hex: '#3f0b0c'},
  [Color.RED_800]: {hex: '#360a0b'},
  [Color.RED_830]: {hex: '#2e090a'},
  [Color.RED_860]: {hex: '#280808'},
  [Color.RED_900]: {hex: '#220606'},
  [Color.REDDIT]: {hex: '#ff4500'},
  [Color.RIOT_GAMES]: {hex: '#ea0029'},
  [Color.ROLE_BLUE]: {hex: '#206694'},
  [Color.ROLE_BROWN]: {hex: '#a84300'},
  [Color.ROLE_BURGUNDY]: {hex: '#ad1457'},
  [Color.ROLE_DARK_BLUE]: {hex: '#546e7a'},
  [Color.ROLE_DARK_GREY]: {hex: '#607d8b'},
  [Color.ROLE_DARK_PURPLE]: {hex: '#71368a'},
  [Color.ROLE_DARK_TEAL]: {hex: '#11806a'},
  [Color.ROLE_DEFAULT]: {hex: '#99aab5'},
  [Color.ROLE_GREEN]: {hex: '#1f8b4c'},
  [Color.ROLE_GREY]: {hex: '#979c9f'},
  [Color.ROLE_LIGHT_BLUE]: {hex: '#99aab5'},
  [Color.ROLE_LIGHT_GREEN]: {hex: '#2ecc71'},
  [Color.ROLE_LIGHT_GREY]: {hex: '#95a5a6'},
  [Color.ROLE_MAGENTA]: {hex: '#e91e63'},
  [Color.ROLE_ORANGE]: {hex: '#e67e22'},
  [Color.ROLE_PURPLE]: {hex: '#9b59b6'},
  [Color.ROLE_SALMON]: {hex: '#e74c3c'},
  [Color.ROLE_SKY_BLUE]: {hex: '#3498db'},
  [Color.ROLE_TAN]: {hex: '#c27c0e'},
  [Color.ROLE_TEAL]: {hex: '#1abc9c'},
  [Color.ROLE_TERRACOTTA]: {hex: '#992d22'},
  [Color.ROLE_YELLOW]: {hex: '#f1c40f'},
  [Color.SAMSUNG]: {hex: '#1429a0'},
  [Color.SKYPE]: {hex: '#009dd7'},
  [Color.SPOTIFY]: {hex: '#1db954'},
  [Color.STEAM]: {hex: '#182332'},
  [Color.TEAL_100]: {hex: '#f4fbfd'},
  [Color.TEAL_130]: {hex: '#e9f9fd'},
  [Color.TEAL_160]: {hex: '#d3f4fb'},
  [Color.TEAL_200]: {hex: '#b1eff9'},
  [Color.TEAL_230]: {hex: '#7ee7f7'},
  [Color.TEAL_260]: {hex: '#5edbef'},
  [Color.TEAL_300]: {hex: '#47cbe2'},
  [Color.TEAL_330]: {hex: '#35bcd5'},
  [Color.TEAL_345]: {hex: '#2eb0c9'},
  [Color.TEAL_360]: {hex: '#289fb6'},
  [Color.TEAL_400]: {hex: '#248da1'},
  [Color.TEAL_430]: {hex: '#207a8d'},
  [Color.TEAL_460]: {hex: '#1b6b7c'},
  [Color.TEAL_500]: {hex: '#175e6d'},
  [Color.TEAL_530]: {hex: '#13525f'},
  [Color.TEAL_560]: {hex: '#0f4954'},
  [Color.TEAL_600]: {hex: '#0c4049'},
  [Color.TEAL_630]: {hex: '#0a3942'},
  [Color.TEAL_660]: {hex: '#08333a'},
  [Color.TEAL_700]: {hex: '#062d34'},
  [Color.TEAL_730]: {hex: '#05272d'},
  [Color.TEAL_760]: {hex: '#042227'},
  [Color.TEAL_800]: {hex: '#031d21'},
  [Color.TEAL_830]: {hex: '#02191d'},
  [Color.TEAL_860]: {hex: '#021619'},
  [Color.TEAL_900]: {hex: '#011215'},
  [Color.TRANSPARENT]: {hex: '#00000000'},
  [Color.TWITCH]: {hex: '#593695'},
  [Color.TWITTER]: {hex: '#1da1f2'},
  [Color.WHITE]: {hex: '#ffffff'},
  [Color.WHITE_100]: {hex: '#ffffff'},
  [Color.WHITE_130]: {hex: '#ffffff'},
  [Color.WHITE_160]: {hex: '#ffffff'},
  [Color.WHITE_200]: {hex: '#ffffff'},
  [Color.WHITE_230]: {hex: '#ffffff'},
  [Color.WHITE_260]: {hex: '#ffffff'},
  [Color.WHITE_300]: {hex: '#ffffff'},
  [Color.WHITE_330]: {hex: '#ffffff'},
  [Color.WHITE_345]: {hex: '#ffffff'},
  [Color.WHITE_360]: {hex: '#ffffff'},
  [Color.WHITE_400]: {hex: '#ffffff'},
  [Color.WHITE_430]: {hex: '#ffffff'},
  [Color.WHITE_460]: {hex: '#ffffff'},
  [Color.WHITE_500]: {hex: '#ffffff'},
  [Color.WHITE_530]: {hex: '#e8e8e8'},
  [Color.WHITE_560]: {hex: '#cfcfcf'},
  [Color.WHITE_600]: {hex: '#adadad'},
  [Color.WHITE_630]: {hex: '#969696'},
  [Color.WHITE_660]: {hex: '#838383'},
  [Color.WHITE_700]: {hex: '#666666'},
  [Color.WHITE_730]: {hex: '#5f5f5f'},
  [Color.WHITE_760]: {hex: '#585858'},
  [Color.WHITE_800]: {hex: '#4d4d4d'},
  [Color.WHITE_830]: {hex: '#3b3b3b'},
  [Color.WHITE_860]: {hex: '#262626'},
  [Color.WHITE_900]: {hex: '#0d0d0d'},
  [Color.XBOX]: {hex: '#107c10'},
  [Color.YELLOW_100]: {hex: '#fff8ef'},
  [Color.YELLOW_130]: {hex: '#fff4e8'},
  [Color.YELLOW_160]: {hex: '#ffebd3'},
  [Color.YELLOW_200]: {hex: '#fee0b6'},
  [Color.YELLOW_230]: {hex: '#fdd18c'},
  [Color.YELLOW_260]: {hex: '#fcc145'},
  [Color.YELLOW_300]: {hex: '#f0b232'},
  [Color.YELLOW_330]: {hex: '#e1a42a'},
  [Color.YELLOW_345]: {hex: '#d49824'},
  [Color.YELLOW_360]: {hex: '#bf861c'},
  [Color.YELLOW_400]: {hex: '#af7615'},
  [Color.YELLOW_430]: {hex: '#9a650d'},
  [Color.YELLOW_460]: {hex: '#8a5709'},
  [Color.YELLOW_500]: {hex: '#7c4b04'},
  [Color.YELLOW_530]: {hex: '#6d4104'},
  [Color.YELLOW_560]: {hex: '#613803'},
  [Color.YELLOW_600]: {hex: '#543203'},
  [Color.YELLOW_630]: {hex: '#4c2d03'},
  [Color.YELLOW_660]: {hex: '#432803'},
  [Color.YELLOW_700]: {hex: '#3b2303'},
  [Color.YELLOW_730]: {hex: '#351e02'},
  [Color.YELLOW_760]: {hex: '#2e1a02'},
  [Color.YELLOW_800]: {hex: '#271602'},
  [Color.YELLOW_830]: {hex: '#221302'},
  [Color.YELLOW_860]: {hex: '#1c1002'},
  [Color.YELLOW_900]: {hex: '#160e02'},
  [Color.YOUTUBE]: {hex: '#cb2120'},
} as const;
